import { ElMessage } from 'element-plus'
import type { FormItemConfig } from '@/components/common/form-renderer/form-components'
import { ComponentType, FileType } from '@/components/common/form-renderer/form-components/type'
import { iesAccountSearch } from '@/api/ocean-engine'

export const configs: FormItemConfig[] = [
  {
    name: 'name',
    label: '广告名称',
    config: {
      type: ComponentType.COMMON_INPUT_TITLE,
      default: '广告名称_{{日期}}_{{时间}}_{{标识}}',
      wildcards: ['日期', '时间', '标识']
    },
    rules: [{ required: true, message: '请输入广告名称', trigger: 'blur' }]
  },
  {
    name: 'budget_mode',
    label: '预算模式',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        { label: '日预算', value: 'BUDGET_MODE_DAY' },
        {
          label: '总预算',
          value: 'BUDGET_MODE_TOTAL'
        }
      ],
      default: 'BUDGET_MODE_DAY'
    },
    condition: {
      'adgroup.delivery_setting.schedule_type': 'SCHEDULE_START_END',
      'campaign.delivery_mode': {
        $ne: 'PROCEDURAL'
      }
    }
  },
  {
    name: 'budget',
    label: '广告预算',
    config: {
      type: ComponentType.OCEAN_BUDGET
    }
  },
  {
    name: 'cpa_bid',
    label: '广告出价',
    tip: '广告出价范围0.01-10000',
    config: {
      type: ComponentType.COMMON_INPUT_NUMBER,
      unit: 1,
      precision: 2,
      min: 0.01,
      max: 10000,
      suffix: '元'
    },
    condition: {
      'adgroup.delivery_setting.bid_type': {
        $ne: 'NO_BID'
      },
      'campaign.delivery_mode': {
        $ne: 'PROCEDURAL'
      },
      'adgroup.delivery_setting.pricing': {
        $in: ['PRICING_OCPC', 'PRICING_OCPM']
      }
    },
    rules: [{ required: true, message: '请输入广告出价', trigger: ['blur', 'change'] }]
  },

  {
    name: 'bid',
    label: '广告出价',
    tip: '广告出价范围4-100',
    config: {
      type: ComponentType.COMMON_INPUT_NUMBER,
      unit: 1,
      precision: 2,
      min: 4,
      max: 100,
      suffix: '元'
    },
    condition: {
      'adgroup.delivery_setting.bid_type': {
        $ne: 'NO_BID'
      },
      'campaign.delivery_mode': {
        $ne: 'PROCEDURAL'
      },
      'adgroup.delivery_setting.pricing': {
        $in: ['PRICING_CPC', 'PRICING_CPM']
      }
    },
    rules: [
      { required: true, message: '请输入广告出价', trigger: ['blur', 'change'] },
      {
        min: 4,
        max: 100,
        type: 'number',
        message: '广告出价超出范围4-100',
        trigger: ['blur', 'change']
      }
    ]
  },
  {
    name: 'source',
    label: '广告来源',
    tip: '即您的产品/公司名称，表达您的推广身份',
    config: {
      type: ComponentType.COMMON_INPUT,
      attr: {
        maxlength: 10
      }
    },
    rules: [{ required: true, message: '请输入广告来源', trigger: 'blur' }]
  },
  {
    name: 'is_comment_disable',
    label: '广告评论',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        { label: '不开启', value: 'OFF' },
        { label: '开启', value: 'ON' }
      ],
      default: 'ON'
    }
  },
  {
    name: 'promotion_materials',
    label: '',
    config: {
      type: ComponentType.COMMON_STRUCT,
      elements: [
        {
          name: 'product_info',
          label: '',
          config: {
            type: ComponentType.COMMON_STRUCT,
            elements: [
              {
                name: 'titles',
                label: '产品名称',
                config: {
                  type: ComponentType.COMMON_INPUT_TAG_LIST,
                  limit: 1,
                  attr: {
                    style: {
                      width: '450px'
                    },
                    clearable: true,
                    minlength: 1,
                    maxlength: 20
                  }
                },
                rules: [{ required: true, message: '请输入产品名称', trigger: 'blur' }]
              },
              {
                name: 'image_ids',
                label: '产品主图',
                config: {
                  type: ComponentType.OCEAN_UPLOAD_FILE,
                  uploadType: FileType.IMAGE,
                  dimension: [108, 108],
                  size: 1024 * 1024 * 50,
                  limit: 10,
                  multiple: true,
                  accept: 'image/*'
                },
                rules: [{ required: true, message: '请上传产品主图', trigger: 'change' }]
              },
              {
                name: 'selling_points',
                tip: '直观在投放端展示的产品优势信息，辅助提升转化效果',
                label: '产品卖点',
                config: {
                  type: ComponentType.COMMON_INPUT_TAG_LIST,
                  limit: 10,
                  attr: {
                    style: {
                      width: '450px'
                    },
                    minlength: 6,
                    maxlength: 9,
                    clearable: true
                  }
                },
                rules: [{ required: true, message: '请输入并确定产品卖点', trigger: 'change' }]
              }
            ]
          }
        },
        {
          name: 'call_to_action_buttons',
          label: '行动号召',
          config: {
            type: ComponentType.COMMON_INPUT_TAG_LIST,
            limit: 10,
            attr: {
              style: {
                width: '450px'
              },
              clearable: true,
              maxlength: 6,
              minlength: 2
            }
          },
          rules: [{ required: true, message: '请输入并确定行动号召', trigger: 'change' }]
        },
        {
          name: 'video_material_list',
          label: '视频',
          tip: '至少上传一个横版视频和一个竖版视频',
          config: {
            type: ComponentType.OCEAN_VIDEO_MATERIAL,
            uploadConfig: {
              uploadType: 'video',
              dimensions: [
                { min: [1280, 720], max: [2560, 1440], ratio: '16:9', duration: [4, 300] },
                { min: [720, 1280], max: [1440, 2560], ratio: '9:16', duration: [4, 300] }
              ],
              size: 1024 * 1024 * 150,
              limit: 10,
              multiple: true,
              accept: 'video/*'
            }
          }
        },
        {
          name: 'image_material_list',
          label: '图片',
          tip: '至少上传一个横版图片',
          config: {
            type: ComponentType.OCEAN_IMAGE_MATERIAL,
            uploadConfig: {
              uploadType: 'image',
              dimensions: [
                { min: [1280, 720], max: [2560, 1440], ratio: '16:9' },
                { min: [720, 1280], max: [1440, 2560], ratio: '9:16' },
                { min: [456, 300], max: [1368, 900], ratio: '38:25' }
              ],
              size: 1024 * 1024 * 50,
              limit: 10,
              multiple: true,
              accept: 'image/*'
            }
          }
        }
      ]
    }
  },
  {
    name: 'promotion_materials',
    label: '',
    config: {
      type: ComponentType.OCEAN_LANDING
    }
  },
  {
    name: 'native_setting_switch',
    label: '推广身份',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        { label: '抖音号', value: true },
        { label: '账户信息', value: false }
      ],
      default: true
    }
  },
  {
    name: 'native_setting',
    label: '',
    condition: { 'adcreative.native_setting_switch': true },
    config: {
      type: ComponentType.COMMON_STRUCT,
      elements: [
        {
          name: 'aweme_id',
          label: '授权抖音号',
          config: {
            type: ComponentType.COMMON_SELECT,
            options: async (keyword, params) => {
              try {
                const [hasError, data] = await iesAccountSearch({ account_id: String(params.accountId.value) })
                if (hasError) {
                  data?.message && ElMessage.error(data.message)
                  return []
                }
                const list = data.data.list || []
                return list.map((el) => {
                  return {
                    label: el.aweme_name,
                    value: el.aweme_id
                  }
                })
              } catch (error) {
                return []
              }
            }
          },
          rules: [{ required: true, message: '请选择授权抖音号', trigger: 'change' }]
        },
        {
          name: 'is_feed_and_fav_see',
          label: '隐藏广告视频',
          tip: '主页作品列表是否隐藏广告内容',
          config: {
            type: ComponentType.COMMON_RADIO_GROUP,
            options: [
              { label: '隐藏', value: 'ON' },
              { label: '不隐藏', value: 'OFF' }
            ],
            default: 'ON'
          }
        },
        {
          name: 'anchor_related_type',
          label: '原生锚点',
          config: {
            type: ComponentType.COMMON_RADIO_GROUP,
            options: [
              { label: '不启用', value: 'OFF' },
              { label: '自动生成', value: 'AUTO' },
              { label: '手动生成', value: 'SELECT' }
            ],
            default: 'OFF'
          }
        }
      ]
    }
  }
]
