import { selectOptions } from '@/api/page-api/index'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { SelectOption } from '@/api/page-api/type'
import _ from 'lodash'
import { setUserConfig } from '.'

export interface CurrencyType extends SelectOption {
  unit: string
  symbol: string
}
// 默认值
export const currencyDefaultValue = 'CNY'
const currencyDefaultObj = {
  label: '人民币',
  value: 'CNY',
  unit: '元',
  symbol: '¥'
}
export const useCurrencyStore = defineStore('currencyStore', () => {
  const currencyList = ref<CurrencyType[]>([])
  const currency = ref<string>(currencyDefaultValue)
  const currencyObj = ref<CurrencyType>(currencyDefaultObj)
  const setCurrencyList = (list: CurrencyType[]) => {
    currencyList.value = list
  }

  const getCurrencyList = async () => {
    try {
      const [hasError, data] = await selectOptions({}, 'currency')
      if (hasError) {
        setCurrencyList([])
        return
      }
      // 处理数据
      setCurrencyList(data.data.options)
    } catch (error) {
      console.trace(error)
      setCurrencyList([])
    }
  }

  const setCurrency = async (value: string) => {
    currency.value = value
    await setUserConfig({ currency: value })
    currencyObj.value = _.find(currencyList.value, (item) => item.value === value) || currencyDefaultObj
  }

  const setCurrencyValue = (value: string) => {
    currency.value = value
    currencyObj.value = _.find(currencyList.value, (item) => item.value === value) || currencyDefaultObj
  }

  return { currency, currencyList, currencyObj, getCurrencyList, setCurrency, setCurrencyValue }
})
