import type { ObjectDirective } from 'vue'
import { usePermissionStore } from '@/stores/permission'

export const permission: ObjectDirective = {
  mounted(el: HTMLButtonElement, binding) {
    if (binding.value == void 0) return
    // 权限唯一标识
    const permissionStr: string = binding.value
    // 权限作用
    const effect = binding.arg
    const result = hasPermission(permissionStr)
    if (!result) {
      if (effect == 'disabled') {
        el.disabled = true
        el.style['disabled'] = 'disabled'
        el.style['pointer-events'] = 'none'
        el.classList.add('is-disabled')
      } else {
        el.remove()
      }
    }
  }
}

export function hasPermission(value?: string) {
  if (!value) return false
  const permissionStore = usePermissionStore()
  const permissionList = permissionStore.getPermissionList()
  return permissionList.includes(value)
}
