import type { RouteRecordRaw } from 'vue-router'

const orderManage: RouteRecordRaw[] = [
  {
    path: '/order-manage',
    name: 'order-manage',
    component: () => import('@/views/order/index.vue'),
    meta: {
      permission: 'order:list',
      title: '订单管理'
    }
  }
]
export default orderManage
