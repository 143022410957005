import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useReportExportStore = defineStore('reportExportStore', () => {
  /** 是否有新导出任务（体现：导出中心小红点） */
  const hasNewExportTask = ref(false)
  return {
    hasNewExportTask
  }
})
