import type { RouteRecordRaw } from 'vue-router'

const system: RouteRecordRaw[] = [
  {
    path: '/role-manage',
    name: 'role-manage',
    component: () => import('@/views/system-manage/role-manage/index.vue'),
    meta: {
      permission: 'role:list',
      title: '角色管理'
    }
  },
  {
    path: '/user-manage',
    name: 'user-manage',
    component: () => import('@/views/system-manage/user-manage/index.vue'),
    meta: {
      permission: 'user:list',
      title: '人员管理'
    }
  }
]
export default system
