export enum ComponentType {
  COMMON_UNKNOWN = 'CommonUnknown',
  COMMON_INPUT = 'CommonInput',
  COMMON_HIDDEN = 'CommonHidden',
  COMMON_RADIO_GROUP = 'CommonRadioGroup',
  COMMON_SELECT = 'CommonSelect',
  COMMON_CHECKBOX = 'CommonCheckbox',
  COMMON_SWITCH = 'CommonSwitch',
  COMMON_SLIDER = 'CommonSlider',
  COMMON_PROMOTE_DATE = 'CommonPromoteDate',
  COMMON_PROMOTE_TIME = 'CommonPromoteTime',
  COMMON_INPUT_NUMBER = 'CommonInputNumber',
  COMMON_GROUP = 'CommonGroup',
  COMMON_CONTENT_LIST = 'CommonContentList',
  COMMON_STRUCT = 'CommonStruct',
  COMMON_INPUT_TAG_LIST = 'CommonInputTagList',
  COMMON_INPUT_TITLE = 'CommonInputTitle',
  COMMON_CONVERSION_SETTING = 'CommonConversionSetting',
  COMMON_MULTIPLE_INPUT_TITLE = 'CommonMultipleInputTitle',

  TADS_V3_AUTO_ACQUISITION = 'TadsV3AutoAcquisition',
  TADS_V3_BID_AMOUNT = 'TadsV3BidAmount',
  TADS_V3_CONVERSION_BID = 'TadsV3ConversionBid',
  TADS_V3_CONVERSION_TYPE = 'TadsV3ConversionType',
  TADS_V3_CONVERSION = 'TadsV3Conversion',
  TADS_V3_CREATIVE_ACTION_BUTTON = 'TadsV3CreativeActionButton',
  TADS_V3_CREATIVE_BRAND = 'TadsV3CreativeBrand',
  TADS_V3_CREATIVE_BRAND_CREATE = 'TadsV3CreativeBrandCreate',
  TADS_V3_CREATIVE_FLOATING_ZONE = 'TadsV3CreativeFloatingZone',
  TADS_V3_CREATIVE_FLOATING_ZONE_IMAGE = 'TadsV3CreativeFloatingZoneImage',
  TADS_V3_CREATIVE_LANDING = 'TadsV3CreativeLanding',
  TADS_V3_SELECT_BUTTON = 'TadsV3SelectButton',
  TADS_V3_CREATIVE_LANDING_TEXT_LINK = 'TadsV3CreativeLandingTextLink',
  TADS_V3_CREATIVE_MODE = 'TadsV3CreativeMode',
  TADS_V3_CREATIVE_TEMPLATE = 'TadsV3CreativeTemplate',
  TADS_V3_CREATIVE_COMPONENTS = 'TadsV3CreativeComponents',
  TADS_V3_CREATIVE_MATERIAL = 'TadsV3CreativeMaterial',
  TADS_V3_CREATIVE_WORDING = 'TadsV3CreativeWording',
  TADS_V3_CREATIVE_TEXT_LINK = 'TadsV3CreativeTextLink',
  TADS_V3_CREATIVE_TEXT_LINK_BASE = 'TadsV3CreativeTextLinkBase',
  TADS_V3_DAILY_BUDGET = 'TadsV3DailyBudget',
  TADS_V3_OPTIMIZATION_GOAL = 'TadsV3OptimizationGoal',
  TADS_V3_SELL_STRATEGY_WITH_CREATE_BUTTON = 'TadsV3SellStrategyWithCreateButton',
  TADS_V3_PROMOTE_TIME = 'TadsV3PromoteTime',
  TADS_V3_SINGLE_IMAGE = 'TadsV3SingleImage',
  TADS_V3_SITE_SET = 'TadsV3SiteSet',
  TADS_V3_TARGETING_AGE = 'TadsV3TargetingAge',
  TADS_V3_TARGETING_CUSTOM_AUDIENCE = 'TadsV3TargetingCustomAudience',
  TADS_V3_TARGETING_EXCLUDED_CONVERTED_AUDIENCE = 'TadsV3TargetingExcludedConvertedAudience',
  TADS_V3_TARGETING_GEO_LOCATION = 'TadsV3TargetingGeoLocation',
  TADS_V3_TARGETING_TEMPLATE_AGE = 'TadsV3TargetingTemplateAge',
  TADS_V3_TARGETING_TEMPLATE_EXCLUDED_CONVERTED_AUDIENCE = 'TadsV3TargetingTemplateExcludedConvertedAudience',
  TADS_V3_TARGETING_TEMPLATE_GENDER = 'TadsV3TargetingTemplateGender',
  TADS_V3_TARGETING_TEMPLATE_GEO_LOCATION = 'TadsV3TargetingTemplateGeoLocation',
  TADS_V3_TARGETING_USER_OS = 'TadsV3TargetingUserOs',
  TADS_V3_TARGETING = 'TadsV3Targeting',
  TADS_V3_UPLOAD_FILE = 'TadsV3UploadFile',
  TADS_V3_USER_ACTION_SETS = 'TadsV3UserActionSets',

  OCEAN_MARKETING_INFO = 'OceanMarketingInfo',
  OCEAN_OPTIMIZE_GOAL = 'OceanOptimizeGoal',
  OCEAN_INVENTORY_TYPE = 'OceanInventoryType',
  OCEAN_BUDGET_SCHEDULE = 'OceanBudgetSchedule',
  OCEAN_BUDGET_INPUT = 'OceanBudgetInput',
  OCEAN_PROMOTE_DATE = 'OceanPromoteDate',
  OCEAN_AUDIENCE_TARGET = 'OceanAudienceTarget',
  OCEAN_RELATED_PRODUCT = 'OceanRelatedProduct',
  OCEAN_LANDING = 'OceanLanding',
  OCEAN_UPLOAD_FILE = 'OceanUploadFile',
  OCEAN_NATIVE_SETTING = 'OceanNativeSetting',
  OCEAN_IMAGE_MATERIAL = 'OceanImageMaterial',
  OCEAN_VIDEO_MATERIAL = 'OceanVideoMaterial',
  OCEAN_TITLE_MATERIAL = 'OceanTitleMaterial',
  OCEAN_MICRO_GAME_ASSET_TYPE = 'OceanMicroGameAssetType',
  OCEAN_ANCHOR_MATERIAL_LIST = 'OceanAnchorMaterialList',
  OCEAN_BUDGET = 'OceanBudget',

  FACEBOOK_ATTRIBUTION_SPEC = 'FacebookAttributionSpec',
  FACEBOOK_PROMOTE_DATE = 'FacebookPromoteDate',
  FACEBOOK_PROMOTE_TIME = 'FacebookPromoteTime',
  FACEBOOK_TARGETING = 'FacebookTargeting',
  FACEBOOK_AGE = 'FacebookAge',
  FACEBOOK_AGE_RANGE = 'FacebookAgeRange',
  FACEBOOK_OBJECT_STORY = 'FacebookObjectStory',
  FACEBOOk_POSITION = 'FacebookPosition',
  FACEBOOK_PBIA_SELECT = 'FacebookPBIASelect',
  FACEBOOK_INPUT_TAG_LIST = 'FacebookInputTagList',
  FACEBOOK_MATERIAL_ELEMENTS = 'FacebookMaterialElements',
  FACEBOOK_UPLOAD_FILE = 'FacebookUploadFile',
  FACEBOOK_LANDING = 'FacebookLanding',
  FACEBOOK_STORY_ASSET_SPEC = 'FacebookStoryAssetSpec',
  FACEBOOK_CAROUSEL = 'FacebookCarousel',
  FACEBOOK_MATERIAL_ELEMENTS_TYPE = 'FacebookMaterialElementsType',

  TIKTOK_ADS_SET = 'TikTokAdsSet',
  TIKTOK_BID_PRICE = 'TikTokBidPrice',
  TIKTOK_BID_TYPE = 'TikTokBidType',
  TIKTOK_BUDGET = 'TikTokBudget',
  TIKTOK_CALL_TO_ACTION_ID = 'TikTokCallToActionId',
  TIKTOK_IDENTITY_ID = 'TikTokIdentityId',
  TIKTOK_LANDING = 'TikTokLanding',
  TIKTOK_OPTIMIZATION_GOAL = 'TikTokOptimizationGoal',
  TIKTOK_PROMOTE_DATE = 'TikTokPromoteDate'
}

export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video'
}

enum WeekEnum {
  Monday = '\u661F\u671F\u4E00',
  Tuesday = '\u661F\u671F\u4E8C',
  Wednesday = '\u661F\u671F\u4E09',
  Thursday = '\u661F\u671F\u56DB',
  Friday = '\u661F\u671F\u4E94',
  Saturday = '\u661F\u671F\u516D',
  Sunday = '\u661F\u671F\u65E5'
}

export enum AdLandingTypeV2Enum {
  APP = 'APP',
  LINK = 'LINK',
  MICRO_GAME = 'MICRO_GAME',
  SHOP = 'SHOP',
  QUICK_APP = 'QUICK_APP',
  DPA = 'DPA'
}

type IRowScheduleTimeItem = {
  week: WeekEnum
  value: string[]
}

export type IPropsValue = {
  schedule_time: string
  invoke_source?: string
  row_schedule_time?: IRowScheduleTimeItem[]
}

export type IExtensions = {
  display_landing_types?: AdLandingTypeV2Enum[]
  isDisabled?: boolean
}

export type SiteSetType =
  | 'SITE_SET_MOBILE_UNION'
  | 'SITE_SET_WECHAT'
  | 'SITE_SET_TENCENT_NEWS'
  | 'SITE_SET_TENCENT_VIDEO'
  | 'SITE_SET_KANDIAN'
  | 'SITE_SET_QQ_MUSIC_GAME'
  | 'SITE_SET_MOMENTS'
  | 'SITE_SET_CHANNELS'
  | 'SITE_SET_WECHAT_PLUGIN'
