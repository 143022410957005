<template>
  <in-dialog
    class="opt"
    append-to-body
    v-model="dialogStatusControlStore.operationDialog"
    :title="''"
    destroy-on-close
    @open="getTask"
    @cancel="closeDialog"
  >
    <template #title>
      <div class="title-container">
        <div v-if="isTaskCenter">操作中心</div>
        <el-breadcrumb v-else :separator-icon="ArrowRight" class="breadcrumb-box">
          <el-breadcrumb-item @click="backTaskCenter">操作中心</el-breadcrumb-item>
          <el-breadcrumb-item class="detail">详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </template>

    <!-- 内容 -->
    <div v-if="isTaskCenter">
      <in-search-card
        v-model="searchVal"
        :timezone-offset="timezoneOffset"
        :configColumns="searchColumns"
        @search="handleSearch"
        @reset="handleReset"
      />
      <el-card class="margin-top-xs" :body-style="{ padding: '10px' }">
        <div class="refresh-box">
          <in-button type="primary" plain @click="refreshTable">
            <el-icon style="margin-right: 2px">
              <Refresh />
            </el-icon>
            刷新数据
          </in-button>
        </div>
        <div class="task-container">
          <el-table
            :data="taskList"
            border
            :height="448"
            size="default"
            style="width: 100%"
            v-loading="loading"
            :show-overflow-tooltip="true"
          >
            <el-table-column prop="desc" label="任务ID" width="100">
              <template #default="{ row }">
                <ColumnCopy :copy-text="row.id">
                  {{ row.id }}
                </ColumnCopy>
              </template>
            </el-table-column>
            <el-table-column prop="desc" label="任务名称">
              <template #default="{ row }">
                <ColumnCopy :copy-text="`${row.desc}-${taskStatus(row.status)}`">
                  {{ row.desc }}-{{ taskStatus(row.status) }}
                </ColumnCopy>
              </template>
            </el-table-column>
            <el-table-column label="平台账户ID" width="150" :show-overflow-tooltip="false">
              <template #default="{ row }">
                <el-popover placement="top" effect="dark" :popper-style="accountPopoverStyle">
                  <template #reference>
                    <div class="account-id-content">
                      <span
                        class="account-id-item"
                        @click="toLink(item.link)"
                        v-for="(item, index) of row.accounts"
                        :key="item.id"
                      >
                        {{ item.platform_account_id + (index !== row.accounts.length - 1 ? ',' : '') }}
                      </span>
                    </div>
                  </template>
                  <div class="account-tooltip-content">
                    <div v-for="item of row.accounts" :key="item.id">
                      {{ item.platform_account_id }}
                    </div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="账户名称" width="190" :show-overflow-tooltip="false">
              <template #default="{ row }">
                <el-popover placement="top" effect="dark" :popper-style="accountPopoverStyle">
                  <template #reference>
                    <div class="account-id-content">
                      <span v-for="(item, index) of row.accounts" :key="item.id">{{
                        item.name + (index !== row.accounts.length - 1 ? ',' : '')
                      }}</span>
                    </div>
                  </template>
                  <div class="account-tooltip-content">
                    <div v-for="item of row.accounts" :key="item.id">
                      {{ item.name }}
                    </div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="user_name" label="创建者" width="90" />
            <el-table-column prop="created_at" label="操作时间" width="180">
              <template #default="{ row }">
                {{ new Date(row.created_at * 1000).toLocaleString() }}
              </template>
            </el-table-column>
            <el-table-column prop="task_progress" label="任务进度" width="200" :show-overflow-tooltip="false">
              <template #default="{ row }">
                <in-progress
                  :stroke-width="10"
                  :total="row.task_total"
                  :percentage="Math.floor((row.task_success / row.task_total) * 100) || 0"
                  :status-text="taskStatus(row.status)"
                  :color="colorList[row.status]"
                  :status="taskProgress(row.status, row.task_total, row.task_success) === 100 ? 'success' : ''"
                />
              </template>
            </el-table-column>
            <el-table-column prop="operation" width="145" label="操作" :show-overflow-tooltip="false" fixed="right">
              <template #default="{ row }">
                <in-button type="primary" link class="watch-btn el-button-xs" size="small" @click="toTaskList(row.id)">
                  查看
                </in-button>
                <in-button
                  v-if="[2, 3].includes(row.status) && [1].includes(row.type)"
                  type="primary"
                  link
                  class="watch-btn el-button-xs"
                  size="small"
                  @click="refreshTask(row)"
                >
                  重试
                </in-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <in-pagination
          size="default"
          class="margin-top-xs"
          :current-page="pageConfig.page"
          :total="pageConfig.total"
          alignment="right"
          @current-change="(e) => changePage(e)"
          @size-change="handleSizeChange"
        />
      </el-card>
    </div>
    <!-- 操作中心-详情 -->
    <div v-else>
      <el-card :body-style="{ padding: '10px' }">
        <div class="refresh-box">
          <in-button type="primary" plain @click="getOperationRecordList">
            <el-icon style="margin-right: 2px">
              <Refresh />
            </el-icon>
            刷新数据
          </in-button>
        </div>

        <div class="status-center">
          <div class="status-list" v-loading="loading">
            <el-table :data="oneTask" :height="620" style="width: 100%">
              <el-table-column label="ID" width="180">
                <template #default="{ row }">
                  <ColumnCopy :copy-text="row.id">
                    {{ row.id }}
                  </ColumnCopy>
                </template>
              </el-table-column>
              <el-table-column prop="ad_name" label="名称" width="400">
                <template #default="{ row }">
                  {{ row.task_result?.dimension_name ? row.task_result?.dimension_name : '未知' }}
                </template>
              </el-table-column>
              <el-table-column prop="desc" label="任务类型" />
              <el-table-column label="任务结果">
                <template #default="scope">
                  <p v-if="scope.row.task_result.dimension_id.length">
                    <span>{{ scope.row.task_result.dimension_desc }}: </span>
                    <el-link type="primary" style="margin-left: 5px">{{ scope.row.task_result.dimension_id }}</el-link>
                  </p>
                  <p v-else>无</p>
                </template>
              </el-table-column>
              <el-table-column prop="ad_status" label="执行状态">
                <template #default="{ row }">
                  <el-tag class="ml-2" type="success" v-if="row.status === Status.SUCCESS" size="small">
                    {{ taskStatus(row.status) }}
                  </el-tag>
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    v-else-if="row.status === Status.FAIL"
                    :content="row.err_msg"
                  >
                    <el-tag class="ml-2" type="danger" size="small">
                      {{ taskStatus(row.status) }}
                    </el-tag>
                  </el-tooltip>
                  <el-tag class="ml-2" type="info" v-else-if="row.status === Status.WAIT" size="small">
                    {{ taskStatus(row.status) }}
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-card>
    </div>
  </in-dialog>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue'
import { useDialogStatusControlStore } from '@/stores/dialog-status-control/index'
import { ArrowRight } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { operationRecordGroupList, operationRecordList } from '@/api/operation-record'
import { batchRetry } from '@/api/batch'
import type { OperationRecordGroup } from '@/api/operation-record/type'
import _ from 'lodash'
import ColumnCopy from '@/components/column-copy/index.vue'
import { selectOptions } from '@/api/page-api'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useTimezoneStore } from '@/stores/user-config/timezone'
import type { SearchColumnsType } from '@adsmocha/influx-ui'

const timezoneStore = useTimezoneStore()
const timezoneOffset = timezoneStore.getUtcOffset()

type GetTaskListReq = {
  task_batch_types: number[]
  account_ids: number[]
  platform_account_ids?: string[]
  user_ids?: number[]
  types?: number[]
  status?: number[]
  start_time?: number
  end_time?: number
  page: number
  size: number
}

const accountPopoverStyle = {
  maxWidth: '800px',
  maxHeight: '200px',
  width: 'auto'
}
const loading = ref<boolean>(false)
const dialogStatusControlStore = useDialogStatusControlStore()
const isTaskCenter = ref<boolean>(true)
const getTaskListReq = ref<GetTaskListReq>({
  account_ids: [],
  task_batch_types: [1, 2, 3, 4],
  page: 1,
  size: 10
})
const getOneTaskReq = ref<{ batch_id: number }>({
  batch_id: 0
})
enum Status {
  WAIT = 0,
  SUCCESS = 1,
  FAIL = 2,
  PARTIAL_FAIL = 3
}

type SearchVal = {
  types: number[] | undefined
  accounts: string
  user_ids: number[] | undefined
  create_at: [Dayjs, Dayjs] | undefined
  status: number[] | undefined
}
const searchVal = ref<SearchVal>({
  types: void 0,
  accounts: '',
  user_ids: void 0,
  create_at: void 0,
  status: void 0
})

const taskList = ref<OperationRecordGroup[]>([])
const oneTask = ref()
const pageConfig = reactive<{
  page: number
  pageSize: number
  page_total: number
  total: number
}>({
  page: 1,
  pageSize: 10,
  page_total: 10,
  total: 100
})
// 0-待执行/1-执行成功/2-执行失败/3-部分执行失败4重试中
const taskProgress = (status: number, total: number, successCount: number): number => {
  if (successCount === 0) return 0
  return Math.floor((successCount / total) * 100)
}

const taskProgressList = [
  {
    label: '待执行',
    value: 0
  },
  {
    label: '执行成功',
    value: 1
  },
  {
    label: '执行失败',
    value: 2
  },
  {
    label: '部分执行失败',
    value: 3
  },
  {
    label: '重试中',
    value: 4
  }
]
const taskTypeList = [
  {
    label: '批量创建',
    value: 1
  },
  {
    label: '批量更新',
    value: 2
  },
  {
    label: '批量删除',
    value: 3
  },
  {
    label: '批量复制',
    value: 4
  },
  {
    label: '跨账户迁移',
    value: 5
  }
]

const searchColumns = reactive<SearchColumnsType[]>([
  {
    label: '任务类型',
    type: 'select',
    entity: 'types',
    valueName: 'types',
    options: taskTypeList,
    multiple: true
  },
  {
    label: '平台账户ID',
    type: 'input',
    keywordsName: 'params',
    valueName: 'accounts',
    placeholder: '多个ID用逗号/空格分割'
  },
  {
    label: '创建者',
    type: 'asyncSelect',
    request: selectOptions,
    entity: 'user',
    valueName: 'user_ids',
    options: [],
    multiple: true
  },
  {
    label: '创建时间',
    type: 'datetimeRange',
    valueName: 'create_at'
  },
  {
    label: '任务进度',
    type: 'select',
    entity: 'status',
    valueName: 'status',
    options: taskProgressList,
    multiple: true
  }
])
// 点击查找
const handleSearch = () => {
  pageConfig.page = 1

  getTaskListReq.value.page = 1
  getGroupList()
}
const handleReset = () => {
  searchVal.value = {
    types: void 0,
    accounts: '',
    user_ids: void 0,
    create_at: void 0,
    status: void 0
  }
  handleSearch()
}
const changePage = (e) => {
  getTaskListReq.value.page = e
  getGroupList()
}
const toLink = (link?: string) => {
  if (link) {
    window.open(link, '_blank')
  }
}
const handleSizeChange = (size: number) => {
  getTaskListReq.value.page = 1
  getTaskListReq.value.size = size
  getGroupList()
}
const taskStatus = (status: number) => {
  const res = ['待执行', '执行成功', '执行失败', '部分执行失败', '重试中']
  return res[status]
}
const colorList = ['#409EFF', '#67C23A', '#F56C6C', '#F56C6C', '#E6A23C']
const getTask = () => {
  isTaskCenter.value = true
  getGroupList()
}
const judgeDimension = (str: string) => {
  const res = {
    'Tads.UpdateAdgroup': 'adgroup',
    'Tads.UpdateAd': 'ad',
    'Tads.UpdateCampaign': 'campaign',
    'All.UpdateCampaignStatus': 'campaign',
    'All.UpdateAdgroupStatus': 'adgroup',
    'All.UpdateAdStatus': 'ad'
  }
  return res[str]
}
const toTaskList = (id) => {
  isTaskCenter.value = !isTaskCenter.value
  getOneTaskReq.value.batch_id = id
  getOperationRecordList()
}

const getOperationRecordList = async () => {
  loading.value = true
  try {
    const [hasError, data] = await operationRecordList(getOneTaskReq.value)
    if (hasError) {
      oneTask.value = []
      return
    }
    oneTask.value = (data.data.list || []).map((item) => {
      return {
        ...item,
        name: judgeDimension(item.task_type),
        param: JSON.parse(item.param)
      }
    })
  } catch (error) {
    oneTask.value = []
  } finally {
    loading.value = false
  }
}

const backTaskCenter = () => {
  isTaskCenter.value = !isTaskCenter.value
  oneTask.value = null
  getGroupList()
}
const getGroupList = async () => {
  loading.value = true
  const [hasError, data] = await operationRecordGroupList({
    ...getTaskListReq.value,
    types: searchVal.value.types,
    start_time: searchVal.value.create_at ? dayjs(searchVal.value.create_at[0]).unix() : void 0,
    end_time: searchVal.value.create_at ? dayjs(searchVal.value.create_at[1]).unix() : void 0,
    user_ids: searchVal.value.user_ids,
    status: searchVal.value.status,
    platform_account_ids: searchVal.value.accounts
      ? searchVal.value.accounts.split(/[,，、 ]/).filter((item: string) => item !== '')
      : []
  })
  loading.value = false
  if (hasError) return
  taskList.value = data.data.list
  pageConfig.total = data.data.page_info.total
  pageConfig.page_total = data.data.page_info.page_total
  pageConfig.page = data.data.page_info.page
}

// 刷新任务
const refreshTask = async (row: OperationRecordGroup) => {
  const [hasError, data] = await batchRetry({ batch_id: row.id })
  if (hasError) {
    ElMessage.warning(data.message ? `重试失败: ${data.message}` : '重试失败')
    return
  }
  toTaskList(row.id)
}

// 关闭弹框
const closeDialog = () => {
  getTaskListReq.value.page = 1
  getTaskListReq.value.size = 10
  dialogStatusControlStore.operationDialog = false
  searchVal.value = {
    types: void 0,
    accounts: '',
    user_ids: void 0,
    create_at: void 0,
    status: void 0
  }
}

// 刷新操作中心表格
const refreshTable = _.debounce(getGroupList, 500)
</script>
<style scoped>
.title-container {
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
  color: #303030;
}
.task-center {
  padding: 0;
}

.task-container {
  background-color: #f0f0f7;
}

.select-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px solid #f0f0f7;
  width: 100px;
  height: 40px;
  cursor: pointer;
  border-radius: var(--el-border-radius-base);
  margin: 5px;
}

.select-title:active {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px solid #f0f0f7;
  width: 100px;
  height: 40px;
  border-radius: var(--el-border-radius-base);
  margin: 5px;
  box-shadow: 0 0 2px #cecece;
}

.task-desc {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  padding: 0 10px;
}

.task-total {
  color: #8989a5;
}

.id-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-table--border {
  height: 550px;
}

.check-status {
  height: 630px;
}
.status-center {
  overflow: auto;
  border-radius: var(--el-border-radius-base);
  background: #fff;
}

.title-btn {
  padding: 10px;
  background: #fff;
  box-shadow: 0 2px 4px #cecece;
}

.el-button--info.is-plain {
  --el-button-bg-color: #f0f0f7;
}

.succeed {
  color: #6eb243;
}

.progress {
  color: #3b86ff;
}

:deep(.el-progress .el-progress__text) {
  min-width: 30px;
}
:deep(.el-progress .el-progress__text > span) {
  font-size: 13px;
}
.refresh-box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
:deep(.el-table--border .el-table__inner-wrapper) {
  font-size: 12px;
}
.breadcrumb-box {
  font-size: 18px;
  padding: 5px 0;
}
:deep(.el-breadcrumb__item:first-child .el-breadcrumb__inner) {
  cursor: pointer;
  color: #303030;
}
:deep(.el-breadcrumb__item:first-child .el-breadcrumb__inner:hover) {
  color: #3b86ff;
}
:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
  color: #909399;
}
.account-id-content {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 10px;
}
.account-id-item {
  color: #409eff;
  cursor: pointer;
}
.account-id-item:hover {
  text-decoration: underline;
}
.account-tooltip-content {
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
}
.account-tooltip-content::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 20px;
}
.account-tooltip-content::-webkit-scrollbar {
  width: 5px;
}
</style>
