import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "a" }, [
        _createElementVNode("path", {
          fill: "rgba(255,255,255,0.6)",
          d: "M1639.524 27.926h9.764a.7.7 0 0 1 .712.692v14.023a.7.7 0 0 1-.712.692h-18.576a.7.7 0 0 1-.5-.206.68.68 0 0 1-.212-.484V26.69a.706.706 0 0 1 .716-.69h7.14a1.24 1.24 0 0 1 1.026.627l.643 1.3Z"
        })
      ])
    ], -1),
    _createElementVNode("g", {
      "clip-path": "url(#a)",
      "data-name": "蒙版组 6",
      transform: "translate(-1630 -26)"
    }, [
      _createElementVNode("path", {
        fill: "rgba(255,255,255,0.6)",
        d: "M1648.75 43h-17.5a1.23 1.23 0 0 1-1.25-1.25V27.584a1.23 1.23 0 0 1 1.25-1.25h6.167a1.14 1.14 0 0 1 .875.375l1.333 1.333a1.2 1.2 0 0 0 .875.375h8.25a1.23 1.23 0 0 1 1.25 1.25V41.75a1.23 1.23 0 0 1-1.25 1.25m-8.208-11.416a.85.85 0 0 0-.625-.25.92.92 0 0 0-.625.25l-3.5 3.75a.814.814 0 0 0 .625 1.375h1.916v2.5a.836.836 0 0 0 .833.833h1.667a.836.836 0 0 0 .833-.833v-2.5h1.75a.837.837 0 0 0 .625-1.375z",
        "data-name": "导出 (1)"
      })
    ], -1)
  ])))
}
export default { render: render }