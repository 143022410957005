import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"#928df4\" data-name=\"组 3467\"><path d=\"M0 10c0 4.061 4.477 7.353 10 7.353S20 14.061 20 10s-4.477-7.352-10-7.352S0 5.94 0 10\" data-name=\"路径 633\" opacity=\".1\"></path><path d=\"M10 0C5.94 0 2.648 4.477 2.648 10S5.94 20 10 20s7.353-4.477 7.353-10S14.061 0 10 0\" data-name=\"路径 634\" opacity=\".1\"></path><path d=\"M17.071 2.929C14.199.057 8.706.895 4.8 4.8S.057 14.2 2.928 17.07s8.365 2.033 12.27-1.872 4.743-9.4 1.872-12.27Z\" data-name=\"路径 635\" opacity=\".2\"></path><path d=\"M17.071 17.071c2.872-2.872 2.033-8.365-1.872-12.27S5.8.057 2.929 2.929.895 11.294 4.8 15.199s9.4 4.743 12.27 1.872Z\" data-name=\"路径 636\" opacity=\".2\"></path><path d=\"M2.941 10.147a7.206 7.206 0 1 0 7.206-7.205 7.206 7.206 0 0 0-7.205 7.205Z\" data-name=\"路径 637\"></path></g>", 1)
  ])))
}
export default { render: render }