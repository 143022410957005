import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "30",
  height: "30",
  class: "icon",
  viewBox: "0 0 1024 1024"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#7BE4DD\" d=\"M68.865 329.357h449.123l-53.894-179.65H128.749zM631.766 0 446.13 41.918l221.567 389.24 131.743-143.72z\"></path><path fill=\"#3B8BFE\" d=\"M889.263 215.579 667.696 598.83l185.637 41.919 167.673-281.45z\"></path><path fill=\"#3468D5\" d=\"M506.012 688.655h449.123L895.25 874.292H571.883z\"></path><path fill=\"#335BB3\" d=\"M368.28 598.83 230.55 736.561 392.234 1024l191.626-47.906z\"></path><path fill=\"#3368DA\" d=\"M170.667 371.275 2.994 664.702l131.743 137.73 227.555-383.25z\"></path>", 5)
  ])))
}
export default { render: render }