import webapi, { type Response } from '../service'
import type * as components from './type'

/**
 * 获取操作中心列表
 * @param req
 */
export function operationRecordGroupList(
  req: components.OperationRecordGroupListRequest
): Response<components.OperationRecordGroupListResponse> {
  return webapi.post(`/operation_record/group/list`, req)
}

/**
 * 获取操作记录列表
 * @param req
 */
export function operationRecordList(
  req: components.OperationRecordListRequest
): Response<components.OperationRecordListResponse> {
  return webapi.post(`/operation_record/list`, req)
}
