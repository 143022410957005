import type { FormItemConfig } from '@/components/common/form-renderer/form-components'
import { ComponentType } from '@/components/common/form-renderer/form-components/type'

export const configs: FormItemConfig[] = [
  {
    name: ['landing_type', 'app_promotion_type', 'delivery_mode', 'marketing_goal', 'ad_type'],
    label: '',
    config: {
      type: ComponentType.OCEAN_MARKETING_INFO,
      default: {
        landing_type: 'LINK'
      }
    }
  },
  {
    name: 'name',
    label: '项目名称',
    config: {
      type: ComponentType.COMMON_INPUT_TITLE,
      attr: {
        maxlength: 50,
        showWordLimit: true,
        style: {
          width: '450px'
        },
        clearable: true
      },
      default: '项目名称_{{日期}}_{{时间}}_{{标识}}',
      wildcards: ['日期', '时间', '标识']
    },
    rules: [{ required: true, message: '请输入项目名称', trigger: 'blur' }]
  },
  {
    name: 'operation',
    label: '计划默认状态',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        {
          label: '关闭',
          value: 'DISABLE'
        },
        { label: '开启', value: 'ENABLE' }
      ],
      default: 'DISABLE'
    }
  },
  {
    name: 'track_url_setting',
    label: '',
    config: {
      type: ComponentType.COMMON_STRUCT,
      elements: [
        {
          name: 'send_type',
          label: '',
          config: {
            type: ComponentType.COMMON_HIDDEN,
            default: 'SERVER_SEND'
          }
        }
      ]
    }
  },
  {
    name: 'related_product',
    label: '关联产品',
    config: {
      type: ComponentType.OCEAN_RELATED_PRODUCT,
      attr: {
        style: {
          width: '450px'
        }
      }
    },
    rules: [{ required: true, message: '请选择关联产品', trigger: 'change' }]
  }
]
