<template>
  <div class="export-task-list">
    <in-dialog v-model="showList" title="导出中心" append-to-body @cancel="showList = false">
      <el-card :body-style="{ padding: '10px' }">
        <el-table :data="taskList" v-loading="isLoading" :height="500" border>
          <el-table-column label="报表名称" prop="reportName">
            <template #default="{ row }">报表{{ row.task_id }}</template>
          </el-table-column>
          <el-table-column label="导出时间" prop="create_time" />
          <el-table-column label="报表状态" prop="status">
            <template #default="{ row }">
              <el-tag size="small" :type="taskStatusMap[row.status].tagType">
                {{ taskStatusMap[row.status].text }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template #default="{ row }">
              <in-button
                v-permission="'export_center:download'"
                type="primary"
                link
                size="small"
                :disabled="row.status !== 'succeed'"
                @click="onDownload(row.link)"
                >下载
              </in-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </in-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import type { DisplayExportTask, ReportExportTask } from '@/views/report/type'
import { ElMessage } from 'element-plus'
import { useReportExportStore } from '@/stores/report/export'
import { reportTaskList } from '@/api/report'
import type { ReportTask } from '@/api/report/type'
const store = useReportExportStore()
const props = defineProps<{
  showReportExportList: boolean
}>()

const emit = defineEmits<{
  (e: 'update:showReportExportList', value: boolean): void
}>()

const showList = computed({
  get() {
    return props.showReportExportList
  },
  set(newValue) {
    emit('update:showReportExportList', newValue)
  }
})

const taskList = ref<ReportTask[]>([])
const taskStatusMap: Record<ReportExportTask['status'], { text: DisplayExportTask['status']; tagType: string }> = {
  proceed: { text: '正在导出', tagType: 'info' },
  succeed: { text: '导出成功', tagType: 'success' },
  failed: { text: '导出失败', tagType: 'danger' }
}
const isLoading = ref<boolean>(false)
const getTableExportTaskList = async () => {
  isLoading.value = true
  try {
    const [hasError, data] = await reportTaskList()
    if (hasError) {
      data?.message && ElMessage.error(data.message)
      return
    }
    taskList.value = data.data.task_list.map((task) => ({
      ...task,
      checked: false
    }))
  } catch (error) {
    console.error(error)
    ElMessage.error({ message: '导出记录加载失败，请重试' })
  } finally {
    store.hasNewExportTask = false
    isLoading.value = false
  }
}

const onDownload = (link) => {
  window.open(link)
}

watch(
  () => showList.value,
  () => {
    if (showList.value) {
      getTableExportTaskList()
    }
  }
)
</script>

<style scoped></style>
