import type { RouteRecordRaw } from 'vue-router'

const toolbox: RouteRecordRaw[] = [
  {
    path: '/toolbox/robot',
    name: 'robot',
    component: () => import('@/views/toolbox/robot/index.vue'),
    meta: {
      permission: 'auto_rule:list',
      title: '自动规则'
    },
    redirect: '/toolbox/robot/list',
    children: [
      {
        path: 'list',
        name: 'robot-list',
        component: () => import('@/views/toolbox/robot/list/index.vue'),
        meta: {
          permission: 'auto_rule:list',
          title: '规则管理'
        },
        children: [
          {
            path: 'create/:platformId',
            name: 'robot-create',
            component: () => import('@/views/toolbox/robot/form/index.vue'),
            meta: {
              permission: 'auto_rule:list',
              title: '新建规则'
            }
          },
          {
            path: 'edit/:id',
            name: 'robot-edit',
            component: () => import('@/views/toolbox/robot/form/index.vue'),
            meta: {
              permission: 'auto_rule:list',
              title: '编辑规则'
            }
          },
          {
            path: 'copy/:id',
            name: 'robot-copy',
            component: () => import('@/views/toolbox/robot/form/index.vue'),
            meta: {
              permission: 'auto_rule:list',
              title: '复制规则'
            }
          },
          {
            path: 'detail/:id',
            name: 'robot-detail',
            component: () => import('@/views/toolbox/robot/form/index.vue'),
            meta: {
              permission: 'auto_rule:list',
              title: '查看详情'
            }
          }
        ]
      },
      {
        path: 'record/:id?',
        name: 'robot-record',
        component: () => import('@/views/toolbox/robot/record/index.vue'),
        meta: {
          permission: 'auto_rule:list',
          title: '规则运行记录'
        }
      }
    ]
  },
  {
    path: '/toolbox/material/analyze',
    name: 'material-analyze',
    component: () => import('@/views/toolbox/analyze/index.vue'),
    meta: {
      permission: 'material_analysis:list',
      title: '素材分析'
    }
  }
]
export default toolbox
