import mitt from 'mitt'
import type { GlobalBusEventsType } from './utils-type/event-bus'

/** 全局事件总线 */
const $bus = mitt<GlobalBusEventsType>()

/**
 * 清空所有事件
 */
const clearAllBus = () => {
  $bus.all.clear()
}

export { $bus, clearAllBus }
