import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "30",
  height: "30",
  class: "icon",
  viewBox: "0 0 1823 1024"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#FFA600",
      d: "M327.655 641.88a133.66 133.66 0 0 0-187.916 23.027L28.314 808.046A133.66 133.66 0 0 0 51.4 995.658a133.66 133.66 0 0 0 187.612-23.027L350.68 829.735a133.66 133.66 0 0 0-23.026-187.855"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#8ED2FF",
      d: "M1289.348 28.314A133.66 133.66 0 0 0 1101.736 51.4L668.31 606.156a133.66 133.66 0 0 0 23.026 187.612 133.66 133.66 0 0 0 187.612-23.026l433.367-554.755a133.66 133.66 0 0 0-22.966-187.673m240.044 312.403a133.66 133.66 0 0 0-187.612 23.026l-241.38 308.94a133.66 133.66 0 0 0 23.026 187.612 133.66 133.66 0 0 0 187.612-23.026l241.38-308.697a133.66 133.66 0 0 0-23.026-187.855M1771.258 645.1a133.66 133.66 0 0 0-187.612 23.209l-108.083 138.34a133.66 133.66 0 0 0 23.026 187.611 133.66 133.66 0 0 0 187.612-23.026l108.144-138.34a133.66 133.66 0 0 0-23.087-187.794"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#296AEF",
      d: "m577.176 539.386 252.68-323.4a133.66 133.66 0 0 0-23.025-187.672A133.66 133.66 0 0 0 619.219 51.4l-252.56 323.4a133.66 133.66 0 0 0 23.027 187.612 133.66 133.66 0 0 0 187.49-23.027m470.67-201.89a133.66 133.66 0 0 0-187.672 23.088l-348.37 445.882a133.66 133.66 0 0 0 22.843 187.612 133.66 133.66 0 0 0 187.673-23.026l348.37-445.882a133.66 133.66 0 0 0-22.844-187.673M1290.99 645.83a133.66 133.66 0 0 0-187.612 23.027L995.84 806.466a133.66 133.66 0 0 0 23.026 187.612 133.66 133.66 0 0 0 187.673-23.026l107.476-137.61a133.66 133.66 0 0 0-23.026-187.613"
    }, null, -1)
  ])))
}
export default { render: render }