import type { RouteRecordRaw } from 'vue-router'

const login: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/login/facebook',
    name: 'login-facebook',
    component: () => import('@/views/login/facebook.vue')
  }
]
export default login
