import webapi, { type Response } from '../service'
import type * as components from './type'

/** 巨量引擎[接口清单](https://open.oceanengine.com/labels/7/docs/1699352157034496) */

/**
 * 透传接口 - [获取商品库信息](https://open.oceanengine.com/labels/7/docs/1696710577036288)
 * @param params
 */
export function dpaProductAvailable(
  params: components.DpaProductAvailableRequestParams
): Response<components.DpaProductAvailableResponse> {
  return webapi.get(`/oceanengine/dpa-product/available`, { params })
}

/**
 * 透传接口 - [获取商品列表](https://open.oceanengine.com/labels/7/docs/1696710578078732)
 * @param params
 */
export function dpaDetailGet(params: components.DpaDetailGetRequestParams): Response<components.DpaDetailGetResponse> {
  return webapi.post(`/oceanengine/dpa/detail/get`, params)
}

/**
 * 透传接口 - [获取绑定的抖音号](https://open.oceanengine.com/labels/7/docs/1696710680364044)
 * @param params
 */
export function iesAccountSearch(
  params: components.IesAccountSearchRequestParams
): Response<components.IesAccountSearchResponse> {
  return webapi.get(`/oceanengine/tools/ies_account_search`, { params })
}

/**
 * 透传接口 - [获取已创建资产列表](https://open.oceanengine.com/labels/7/docs/1705976384784395)
 * @param req
 */
export function eventAssetsGet(req: components.EventAssetsGetRequest): Response<components.EventAssetsGetResponse> {
  return webapi.post(`/oceanengine/tools/event/assets/get`, req)
}

/**
 * 透传接口 - [获取可用优化目标](https://open.oceanengine.com/labels/7/docs/1740944984250381)
 * @param params
 */
export function eventManagerOptimizedGoal(
  params: components.EventManagerOptimizedGoalRequestParams
): Response<components.EventManagerOptimizedGoalResponse> {
  return webapi.get(`/oceanengine/event-manager/optimazed-goal`, {
    params
  })
}

/**
 * 透传接口 - [上传视频](https://open.oceanengine.com/labels/7/docs/1696710600730639)
 * @param req
 */
export function addOceanVideos(req: components.FileVideoAddRequest): Response<components.FileVideoAddResponse> {
  return webapi.post(`/oceanengine/videos/add`, req, {
    headers: { 'content-type': 'multipart/form-data' }
  })
}

/**
 * 透传接口 - [获取视频素材](https://open.oceanengine.com/labels/7/docs/1696710601820172)
 * @param req
 */
export function getOceanVideo(req: components.FileVideoGetRequest): Response<components.FileVideoGetResponse> {
  return webapi.post(`/oceanengine/videos/get`, req)
}

/**
 * 透传接口 - [上传广告图片](https://open.oceanengine.com/labels/7/docs/1696710600176640)
 * @param req
 */
export function addOceanImages(req: components.FileImageAddRequest): Response<components.FileImageAddResponse> {
  return webapi.post(`/oceanengine/images/add`, req, {
    headers: { 'content-type': 'multipart/form-data' }
  })
}

/**
 * 透传接口 - [获取图片素材](https://open.oceanengine.com/labels/7/docs/1696710601254912)
 * @param req
 */
export function getOceanImages(req: components.FileImageGetRequest): Response<components.FileImageGetResponse> {
  return webapi.post(`/oceanengine/images/get`, req)
}

/**
 * 透传接口 - [获取视频智能封面](https://open.oceanengine.com/labels/7/docs/1696710602404864)
 * @param params
 */
export function videoCoverSuggest(
  params: components.VideoCoverSuggestRequestParams
): Response<components.VideoCoverSuggestResponse> {
  return webapi.get(`/oceanengine/video-cover/suggert`, { params })
}
/**
 * 透传接口 - [获取橙子建站站点列表](https://open.oceanengine.com/labels/7/docs/1696710620579852)
 * @param params
 */
export function siteGet(params: components.SiteGetSearchRequestParams): Response<components.SiteGetSearchResponse> {
  return webapi.get(`/oceanengine/tools/site/get`, { params })
}
/**
 * 透传接口 - [获取账户下原生锚点](https://open.oceanengine.com/labels/7/docs/1757715831323652)
 * @description
 * @param req
 */
export function nativeAnchorGet(req: components.NativeAnchorGetRequest): Response<components.NativeAnchorGetResponse> {
  return webapi.post(`/oceanengine/native_anchor/get`, req)
}

/**
 * 透传接口 - [获取推荐关键词](https://open.oceanengine.com/labels/7/docs/1755356193295372)
 * @description
 * @param params
 */
export function suggWordsGet(params: components.SuggWordsGetRequest): Response<components.SuggWordsGetResponse> {
  return webapi.get(`/oceanengine/sugg_words`, { params })
}

/**
 * 透传接口 - [创建定向包](https://open.oceanengine.com/labels/7/docs/1696710735901711)
 * @param req
 */
export function oceanAudienceAdd(
  req: components.OceanAudienceAddRequest
): Response<components.OceanAudienceAddResponse> {
  return webapi.post(`/oceanengine/audience_package/create`, req)
}

/**
 * 透传接口 - [获取定向包](https://open.oceanengine.com/labels/7/docs/1696710731761676)
 * @param req
 */
export function oceanAudienceGet(
  params: components.OceanAudienceGetRequest
): Response<components.OceanAudienceGetResponse> {
  return webapi.get(`/oceanengine/audience_package/get`, { params })
}
