import { LANGUAGE_LIST } from '@/components/common/form-renderer/form-components/constant'
import { ComponentType } from '@/components/common/form-renderer/form-components/type'
import type { FormItemConfig } from '@/components/common/form-renderer/form-components'
import type { TikTokPixel } from '@/api/tiktok/type'
import { tiktokPixelList } from '@/api/tiktok'

/** 广告版位 */
const placements: FormItemConfig[] = [
  {
    name: 'placement_type',
    label: '广告位类型',
    sectionLabel: '广告版位',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        { label: '自动广告位', value: 'PLACEMENT_TYPE_AUTOMATIC' },
        { label: '编辑广告位', value: 'PLACEMENT_TYPE_NORMAL' }
      ],
      default: 'PLACEMENT_TYPE_AUTOMATIC'
    },
    condition: {
      'campaign.is_spc': false
    }
  },
  {
    name: 'placements',
    label: '广告位',
    sectionLabel: '广告版位',
    config: {
      type: ComponentType.COMMON_SELECT,
      options: [
        { label: 'TikTok', value: 'PLACEMENT_TIKTOK' },
        { label: 'Pangle', value: 'PLACEMENT_PANGLE' },
        { label: '全球化应用组合', value: 'PLACEMENT_GLOBAL_APP_BUNDLE' }
      ],
      attr: {
        multiple: true,
        filterable: true,
        clearable: true,
        placeholder: '请选择广告位'
      }
    },
    condition: {
      'campaign.is_spc': false,
      'adgroup.placement_type': 'PLACEMENT_TYPE_NORMAL'
    },
    rules: [{ required: true, message: '请选择广告位', trigger: ['blur', 'change'] }]
  },
  {
    name: 'search_result_enabled',
    label: '包含搜索结果',
    tip: '与Tik Tok广告位相关，当用户在 TikTok 上搜索你的业务相关信息时，系统就会向他们展示你的广告',
    sectionLabel: '广告版位',
    config: {
      type: ComponentType.COMMON_SWITCH,
      default: true
    },
    condition: {
      $or: [
        { 'campaign.is_spc': true },
        { 'adgroup.placement_type': 'PLACEMENT_TYPE_AUTOMATIC' },
        { 'adgroup.placements': { $in: ['PLACEMENT_TIKTOK'] } }
      ]
    }
  },
  {
    name: 'comment_disabled',
    tip: '我们建议您保持开启用户评论，这样可以帮助您的广告获得更多展示数和转化量。您可以使用官方投放平台的评论管理工具隐藏、置顶以及回复评论',
    label: '用户评论',
    sectionLabel: '广告版位',
    config: {
      type: ComponentType.COMMON_SWITCH,
      default: true
    }
  },
  {
    name: 'video_download_disabled',
    tip: '位于日本的用户将无法下载你的视频广告。只有在广告管理平台和 TikTok 上都启用了此设置，用户才能下载 Spark Ads 帖子',
    label: '允许下载视频',
    sectionLabel: '广告版位',
    config: {
      type: ComponentType.COMMON_SWITCH,
      default: true
    }
  },
  {
    name: 'share_disabled',
    tip: '用户将能够在 TikTok 上分享你的视频，也可以通过私信、短信、电子邮件、Instagram 等方式分享你的视频',
    label: '允许共享视频',
    sectionLabel: '广告版位',
    config: {
      type: ComponentType.COMMON_SWITCH,
      default: true
    }
  }
]

/** 转化 */
const optimization: FormItemConfig[] = [
  {
    name: 'promotion_type',
    label: '推广对象类型',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: 'WEBSITE'
    }
  },
  {
    name: 'pixel_id',
    label: '数据连接',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_SELECT,
      options: async (keyword, params) => {
        const [hasError, data] = await tiktokPixelList({ account_id: params.accountId.value, page: 1, size: 20 })
        if (hasError) return []
        return data.data.list.map((item: TikTokPixel) => ({ value: item.pixel_id, label: item.pixel_name }))
      },
      attr: {
        placeholder: '请选择数据连接'
      }
    },
    rules: [{ required: true, message: '请选择数据连接', trigger: ['blur', 'change'] }]
  },
  {
    name: 'optimization_event',
    label: '优化事件',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_SELECT,
      // https://business-api.tiktok.com/portal/docs?id=1739585696931842
      options: [
        { label: '查看内容', value: 'ON_WEB_DETAIL' },
        { label: '点击按钮', value: 'BUTTON' },
        { label: '搜索', value: 'ON_WEB_SEARCH' },
        { label: '加入心愿单', value: 'ON_WEB_ADD_TO_WISHLIST' },
        { label: '加入购物车', value: 'ON_WEB_CART' },
        { label: '开始结账', value: 'INITIATE_ORDER' },
        { label: '添加支付信息', value: 'ADD_BILLING' },
        { label: '支付完成', value: 'SHOPPING' },
        { label: '下单', value: 'ON_WEB_ORDER' },
        { label: '联系', value: 'CONSULT' },
        { label: '下载', value: 'DOWNLOAD_START' },
        { label: '提交表单', value: 'FORM' },
        { label: '完成注册', value: 'ON_WEB_REGISTER' },
        { label: '订阅', value: 'ON_WEB_SUBSCRIBE' }
      ],
      attr: {
        placeholder: '请选择优化事件'
      }
    },
    rules: [{ required: true, message: '请选择优化事件', trigger: ['blur', 'change'] }]
  },
  {
    name: 'optimization_goal',
    label: '优化目标',
    sectionLabel: '转化',
    config: {
      type: ComponentType.TIKTOK_OPTIMIZATION_GOAL
    }
  },
  {
    name: ['bid_type', 'deep_bid_type'],
    label: '竞价策略',
    sectionLabel: '转化',
    config: {
      type: ComponentType.TIKTOK_BID_TYPE
    }
  },
  {
    name: 'conversion_bid_price',
    label: '出价',
    sectionLabel: '转化',
    config: {
      type: ComponentType.TIKTOK_BID_PRICE
    },
    condition: {
      'adgroup.billing_event': 'OCPM',
      'adgroup.bid_type': 'BID_TYPE_CUSTOM'
    }
  },
  {
    name: 'roas_bid',
    label: '目标 ROAS',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_INPUT_NUMBER,
      unit: 1,
      min: 0.01,
      max: 1000,
      precision: 2,
      default: 0.01
    },
    rules: [{ required: true, message: '请输入目标 ROAS', trigger: ['blur', 'change'] }],
    condition: {
      'adgroup.optimization_goal': 'VALUE',
      'adgroup.deep_bid_type': 'VO_MIN_ROAS'
    }
  },
  {
    name: 'billing_event',
    label: '计费点',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: 'OCPM'
    }
  },
  {
    name: 'pacing',
    label: '投放速度',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: 'PACING_MODE_SMOOTH'
    }
  }
]

/** 预算和排期 */
const budgetAndSchedule: FormItemConfig[] = [
  {
    name: ['budget_mode', 'budget'],
    label: '预算',
    sectionLabel: '预算和排期',
    config: {
      type: ComponentType.TIKTOK_BUDGET,
      isAdgroup: true
    },
    condition: {
      $or: [{ 'campaign.is_spc': true }, { 'campaign.budget_optimize_on': false }]
    }
  },
  {
    name: ['schedule_type', 'schedule_start_time', 'schedule_end_time'],
    label: '投放日期',
    sectionLabel: '预算和排期',
    config: {
      type: ComponentType.TIKTOK_PROMOTE_DATE,
      elements: {
        type: ComponentType.COMMON_PROMOTE_DATE,
        longTermType: 'datetime',
        specificType: 'datetimerange',
        longTermFormat: 'YYYY-MM-DD HH:mm:ss',
        specificFormat: 'YYYY-MM-DD HH:mm:ss',
        dateType: 'SPECIFIC'
      }
    },
    condition: {
      $or: [
        { 'campaign.budget_optimize_on': true, 'campaign.budget_mode': 'BUDGET_MODE_TOTAL' },
        { 'campaign.budget_optimize_on': false, 'adgroup.budget_mode': 'BUDGET_MODE_TOTAL' }
      ]
    }
  },
  {
    name: ['schedule_type', 'schedule_start_time', 'schedule_end_time'],
    label: '投放日期',
    sectionLabel: '预算和排期',
    config: {
      type: ComponentType.TIKTOK_PROMOTE_DATE
    },
    condition: {
      $or: [
        { 'campaign.is_spc': true },
        { 'campaign.budget_optimize_on': true, 'campaign.budget_mode': 'BUDGET_MODE_DYNAMIC_DAILY_BUDGET' },
        { 'campaign.budget_optimize_on': false, 'adgroup.budget_mode': 'BUDGET_MODE_DYNAMIC_DAILY_BUDGET' }
      ]
    }
  },
  {
    name: 'dayparting',
    label: '投放时间',
    sectionLabel: '预算和排期',
    config: {
      type: ComponentType.COMMON_PROMOTE_TIME
    }
  }
]

/** 定向 */
export const targetings: FormItemConfig[] = [
  {
    name: 'location_ids',
    label: '地域',
    sectionLabel: '定向',
    config: {
      type: ComponentType.COMMON_CHECKBOX,
      options: [
        { label: '美国', value: '6252001' },
        { label: '英国', value: '2635167' },
        { label: '澳大利亚', value: '2077456' },
        { label: '加拿大', value: '6251999' },
        { label: '新西兰', value: '2186224' }
      ],
      multi: true
    },
    rules: [{ required: true, message: '请选择地域', trigger: 'change' }]
  },
  {
    name: 'gender',
    label: '性别',
    sectionLabel: '定向',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        { label: '不限', value: 'GENDER_UNLIMITED' },
        { label: '男', value: 'GENDER_MALE' },
        { label: '女', value: 'GENDER_FEMALE' }
      ],
      default: 'GENDER_UNLIMITED'
    },
    condition: {
      'campaign.is_spc': false
    }
  },
  {
    name: 'age_groups',
    label: '年龄',
    sectionLabel: '定向',
    config: {
      type: ComponentType.COMMON_CHECKBOX,
      options: [
        { label: '13-17岁', value: 'AGE_13_17' },
        { label: '18-24岁', value: 'AGE_18_24' },
        { label: '25-34岁', value: 'AGE_25_34' },
        { label: '35-44岁', value: 'AGE_35_44' },
        { label: '45-54岁', value: 'AGE_45_54' },
        { label: '55+', value: 'AGE_55_100' }
      ],
      multi: true,
      showNoLimit: true
    },
    condition: {
      'campaign.is_spc': false
    }
  },
  {
    name: 'languages',
    label: '语言',
    sectionLabel: '定向',
    config: {
      type: ComponentType.COMMON_SELECT,
      options: LANGUAGE_LIST,
      attr: {
        multiple: true,
        filterable: true,
        clearable: true,
        placeholder: '不限'
      }
    }
  }
]

export const configs: FormItemConfig[] = [
  {
    name: 'adgroup_name',
    label: '广告组名称',
    config: {
      type: ComponentType.COMMON_INPUT_TITLE,
      attr: {
        maxlength: 512,
        showWordLimit: true,
        style: {
          width: '450px'
        },
        clearable: true,
        placeholder: '请输入广告组名称'
      },
      default: '广告组名称_{{日期}}_{{时间}}_{{标识}}',
      wildcards: ['日期', '时间', '标识', '素材名']
    },
    condition: {
      'campaign.is_spc': false
    },
    rules: [
      { required: true, message: '请输入广告组名称', trigger: ['blur', 'change'] },
      {
        validator: (rule, value, callback) => {
          // 不能含有 emoji
          if (value.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/)) {
            return callback(new Error('名称不能含有 Emoji'))
          }
          // 名称不能超过512字符，中文或日文每个字占用2个字符，每个英语字符占用1个字符，单独计算
          let length = 0
          for (let i = 0; i < value.length; i++) {
            if (value.charCodeAt(i) > 255) {
              length += 2
            } else {
              length++
            }
          }
          if (length > 512) {
            callback(new Error('名称不能超过512个字符，中文或日文每个字占用2个字符，每个英语字符占用1个字符'))
          } else {
            callback()
          }
        },
        trigger: ['blur', 'change']
      }
    ]
  },
  ...placements,
  ...optimization,
  ...budgetAndSchedule,
  ...targetings
]
