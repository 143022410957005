<template>
  <div class="export-center" :class="{ hasNewExportTask: exportStore.hasNewExportTask }" @click="openExportReportList">
    <Export class="export-center-icon"></Export>
    <div class="export-center-title" v-show="simplify">导出中心</div>
    <exportCenterList v-model:showReportExportList="showReportExportList"></exportCenterList>
  </div>
</template>

<script setup lang="ts">
import { Export } from '@/assets/svg'
import { ref } from 'vue'
import { useReportExportStore } from '@/stores/report/export'
import exportCenterList from './export-center-list.vue'

const exportStore = useReportExportStore()
const showReportExportList = ref(false)
const openExportReportList = () => {
  showReportExportList.value = true
}
defineProps<{ simplify?: boolean }>()
</script>

<style scoped>
/* 导出 */
.export-center {
  width: 100%;
  height: 50px;
  color: #fff;
  background-color: #2f2e50;
  border-radius: var(--el-border-radius-base);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px;
}
.export-center .export-center-icon {
  transform: scale(1.2) translateY(2px);
}
.hasNewExportTask::after {
  content: '';
  position: absolute;
  top: 3px;
  right: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #eb6868;
  transform: translate(50%, -50%);
}
.export-center-title {
  font-size: 16px;
  margin-left: 10px;
  font-weight: bold !important;
  color: #ababb9;
  white-space: nowrap;
  overflow: hidden;
}
/* 导出 */
</style>
