import webapi, { type Response } from '../service'
import type * as components from './type'

/**
 * TikTok[接口列表](https://business-api.tiktok.com/portal/docs?id=1735713875563521)
 */

/**
 * 透传接口 - [获取Pixel列表](https://business-api.tiktok.com/portal/docs?id=1740858697598978)
 * @param params
 */
export function tiktokPixelList(
  params: components.TikTokPixelListRequestParams
): Response<components.TikTokPixelListResponse> {
  return webapi.get(`/tiktok/pixel/list`, { params })
}

/**
 * 透传接口 - [基于具体设置获取可投放地域](https://business-api.tiktok.com/portal/docs?id=1737189539571713)
 * @param params
 */
export function tiktokToolRegion(
  params: components.TikTokToolRegionRequestParams
): Response<components.TikTokToolRegionResponse> {
  return webapi.get(`/tiktok/tool/region`, { params })
}

/**
 * 透传接口 - [获取受众语言列表](https://business-api.tiktok.com/portal/docs?id=1737188554152962)
 * @param params
 */
export function tiktokToolLanguage(
  params: components.TikTokToolLanguageRequestParams
): Response<components.TikTokToolLanguageResponse> {
  return webapi.get(`/tiktok/tool/language`, { params })
}

/**
 * 透传接口 - [获取认证身份列表](https://business-api.tiktok.com/portal/docs?id=1740218420781057)
 * @param params
 */
export function tiktokIdentityGet(
  params: components.TikTokIdentityGetRequestParams
): Response<components.TikTokIdentityGetResponse> {
  return webapi.get(`/tiktok/identity/get`, { params })
}

/**
 * 透传接口 - [获取认证身份下帖子](https://business-api.tiktok.com/portal/docs?id=1740218475032577)
 * @param params
 */
export function tiktokIdentityVideoGet(
  params: components.TikTokIdentityVideoGetRequestParams
): Response<components.TikTokIdentityVideoGetResponse> {
  return webapi.get(`/tiktok/identity/video/get`, { params })
}

/**
 * 透传接口 - [获取 TikTok 帖子信息](https://business-api.tiktok.com/portal/docs?id=1740218522178562)
 * @param params
 */
export function tiktokIdentityVideoInfo(
  params: components.TikTokIdentityVideoInfoRequestParams
): Response<components.TikTokIdentityVideoInfoResponse> {
  return webapi.get(`/tiktok/identity/video/info`, { params })
}

/**
 * 透传接口 - [智能推荐CTA](https://business-api.tiktok.com/portal/docs?id=1739362202742785)
 * @param params
 */
export function tiktokCTARecommend(
  params: components.TikTokCTARecommendRequestParams
): Response<components.TikTokCTARecommendResponse> {
  return webapi.get(`/tiktok/creative/cta/recommend`, { params })
}

/**
 * 透传接口 - [根据 ID 获取素材包](https://business-api.tiktok.com/portal/docs?id=1739092113671170)
 * @param params
 */
export function tiktokPortfolioGet(
  params: components.TikTokPortfolioGetRequestParams
): Response<components.TikTokPortfolioGetResponse> {
  return webapi.get(`/tiktok/creative/portfolio/get`, { params })
}

/**
 * 透传接口 - [创建素材包](https://business-api.tiktok.com/portal/docs?id=1739091950439426)
 * @param req
 */
export function tiktokPortfolioCreate(
  req: components.TikTokPortfolioCreateRequest
): Response<components.TikTokPortfolioCreateResponse> {
  return webapi.post(`/tiktok/creative/portfolio/create`, req)
}

/**
 * 透传接口 - [获取视频信息](https://business-api.tiktok.com/portal/docs?id=1740050161973250)
 * @param params
 */
export function tiktokVideoAdInfo(
  params: components.TikTokVideoAdInfoRequestParams
): Response<components.TikTokVideoAdInfoResponse> {
  return webapi.get(`/tiktok/video/ad/info`, { params })
}

/**
 * 透传接口 - [上传视频](https://business-api.tiktok.com/portal/docs?id=1737587322856449)
 * @param req
 */
export function tiktokVideoAdUpload(
  req: components.TikTokFileAddRequest
): Response<components.TikTokVideoAdUploadResponse> {
  return webapi.post(`/tiktok/video/ad/upload`, req, {
    headers: { 'content-type': 'multipart/form-data' }
  })
}

/**
 * 透传接口 - [获取图片信息](https://business-api.tiktok.com/portal/docs?id=1740051721711618)
 * @param params
 */
export function tiktokImageAdInfo(
  params: components.TikTokImageAdInfoRequestParams
): Response<components.TikTokImageAdInfoResponse> {
  return webapi.get(`/tiktok/file/image/ad/info`, { params })
}

/**
 * 透传接口 - [上传图片](https://business-api.tiktok.com/portal/docs?id=1739067433456642)
 * @param req
 */
export function tiktokImageAdUpload(
  req: components.TikTokFileAddRequest
): Response<components.TikTokImageAdUploadResponse> {
  return webapi.post(`/tiktok/file/image/ad/upload`, req, {
    headers: { 'content-type': 'multipart/form-data' }
  })
}
