import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "30.001",
  height: "14"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#a5a4bf",
      d: "M16.071 8h-2.142A7 7 0 1 1 7 0h16a7 7 0 1 1-6.929 8M18 7a5 5 0 1 0 5-5 5.005 5.005 0 0 0-5 5M2 7a5 5 0 1 0 5-5 5.005 5.005 0 0 0-5 5m14.071-1a7 7 0 0 1 2.035-4h-6.212a7 7 0 0 1 2.035 4Z",
      "data-name": "联合 1"
    }, null, -1)
  ])))
}
export default { render: render }