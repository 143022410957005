import { reactive } from 'vue'
import * as Sentry from '@sentry/vue'

const auth = reactive({
  id: 0,
  hasLogin: false,
  name: '',
  business_roles: [],
  server_role: {},
  name_en: ''
})

function restoreAuth(): boolean {
  const storageAuth = JSON.parse(localStorage.getItem('auth') as string)
  if (!storageAuth) return false

  auth.hasLogin = storageAuth.hasLogin
  auth.name = storageAuth.name

  return auth.hasLogin
}

function setAuth(accountData) {
  const { id, name, business_roles, server_role, name_en } = accountData
  auth.hasLogin = true
  auth.id = id
  auth.name = name
  auth.name_en = name_en
  auth.business_roles = business_roles
  auth.server_role = server_role

  localStorage.setItem('auth', JSON.stringify(auth))
  // 绑定Sentry用户上下文
  import.meta.env.PROD && Sentry.setUser({ id: String(id), username: name })
}

function clearAuth() {
  auth.hasLogin = false
  auth.name = ''

  localStorage.setItem('auth', JSON.stringify(auth))
  // 清除当前设置的用户
  import.meta.env.PROD && Sentry.setUser(null)
}

restoreAuth()

export { auth, setAuth, clearAuth, restoreAuth }
