import type { RouteRecordRaw } from 'vue-router'
import { Platform } from '@/components/configs/default'

const batchCreate: RouteRecordRaw[] = [
  {
    path: '/batch-create/tads',
    alias: '/batch-create/tads-v3',
    name: 'batch-create-tads',
    component: () => import('@/views/ad-manage/batch-create/index.vue'),
    meta: {
      permission: 'batch_create:tads',
      platformId: Platform.TadsV3,
      title: '腾讯广告'
    }
  },
  {
    path: '/batch-create/ocean',
    name: 'batch-create-ocean',
    component: () => import('@/views/ad-manage/batch-create/index.vue'),
    meta: {
      permission: 'batch_create:ocean',
      platformId: Platform.OceanEngine,
      title: '巨量引擎'
    }
  },
  {
    path: '/batch-create/meta',
    name: 'batch-create-meta',
    component: () => import('@/views/ad-manage/batch-create/index.vue'),
    meta: {
      permission: 'batch_create:facebook',
      platformId: Platform.Facebook,
      title: 'Facebook'
    }
  },
  {
    path: '/batch-create/tiktok',
    name: 'batch-create-tiktok',
    component: () => import('@/views/ad-manage/batch-create/index.vue'),
    meta: {
      permission: 'batch_create:tiktok',
      platformId: Platform.TikTok,
      title: 'TikTok'
    }
  }
]
export default batchCreate
