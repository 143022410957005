import type { PlatformCurrencyInputConfig } from '../index'
export const currencyInputConfig: PlatformCurrencyInputConfig = {
  adgroup: {
    bid_amount: {
      CNY: { precision: 2, min: void 0, suffix: '元' },
      USD: { precision: 2, min: void 0, suffix: '美元' },
      EUR: { precision: 2, min: void 0, suffix: '欧元' },
      GBP: { precision: 2, min: void 0, suffix: '英镑' },
      AUD: { precision: 2, min: void 0, suffix: '澳元' },
      CAD: { precision: 2, min: void 0, suffix: '加元' },
      NZD: { precision: 2, min: void 0, suffix: '新西兰元' }
    },
    daily_budget: {
      CNY: { precision: 2, min: 0, suffix: '元' },
      USD: { precision: 2, min: 0, suffix: '美元' },
      EUR: { precision: 2, min: 0, suffix: '欧元' },
      GBP: { precision: 2, min: 0, suffix: '英镑' },
      AUD: { precision: 2, min: 0, suffix: '澳元' },
      CAD: { precision: 2, min: 0, suffix: '加元' },
      NZD: { precision: 2, min: 0, suffix: '新西兰元' }
    },
    lifetime_budget: {
      CNY: { precision: 2, min: 0, suffix: '元' },
      USD: { precision: 2, min: 0, suffix: '美元' },
      EUR: { precision: 2, min: 0, suffix: '欧元' },
      GBP: { precision: 2, min: 0, suffix: '英镑' },
      AUD: { precision: 2, min: 0, suffix: '澳元' },
      CAD: { precision: 2, min: 0, suffix: '加元' },
      NZD: { precision: 2, min: 0, suffix: '新西兰元' }
    }
  },
  campaign: {
    daily_budget: {
      CNY: { precision: 2, min: 0, suffix: '元' },
      USD: { precision: 2, min: 0, suffix: '美元' },
      EUR: { precision: 2, min: 0, suffix: '欧元' },
      GBP: { precision: 2, min: 0, suffix: '英镑' },
      AUD: { precision: 2, min: 0, suffix: '澳元' },
      CAD: { precision: 2, min: 0, suffix: '加元' },
      NZD: { precision: 2, min: 0, suffix: '新西兰元' }
    },
    lifetime_budget: {
      CNY: { precision: 2, min: 0, suffix: '元' },
      USD: { precision: 2, min: 0, suffix: '美元' },
      EUR: { precision: 2, min: 0, suffix: '英镑' },
      GBP: { precision: 2, min: 0, suffix: '美元' },
      AUD: { precision: 2, min: 0, suffix: '澳元' },
      CAD: { precision: 2, min: 0, suffix: '加元' },
      NZD: { precision: 2, min: 0, suffix: '新西兰元' }
    }
  },
  adcreative: {}
}
