import type { FormItemConfigMapType } from '../../default'
import { configs as campaign } from './campaign'
import { configs as adgroup } from './adgroup'
import { configs as adcreative } from './adcreative'

export const tikTokConfigs: FormItemConfigMapType = {
  campaign,
  adgroup,
  adcreative
}
