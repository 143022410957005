import webapi, { type Response } from '../service'
import type * as components from './type'

/**
 * 登录
 * @param req
 */
export function login(req: components.LoginRequest): Response<components.LoginResponse> {
  return webapi.post(`/login`, req)
}

/**
 * 登出
 */
export function logout(): Response<components.LogoutResponse> {
  return webapi.post(`/logout`)
}

/**
 * 获取字典
 * @param params
 */
export function selectOptions(
  params: components.SelectOptionsRequestParams,
  entity: string
): Response<components.SelectOptionsResponse> {
  return webapi.get(`/select-options/${entity}`, { params: { size: 50, ...params } })
}
/**
 * 获取平台账户信息
 */
export function accountSelectOptions(
  params: components.AccountSelectOptionsRequestParams
): Response<components.AccountSelectOptionsResponse> {
  return webapi.get(`/asset/account/select/list`, { params })
}

/**
 * 添加关键字 判断是否超过搜索条数限制
 */
export function selectOptionsOverLimit(
  params: components.SelectOptionsOverLimitRequestParams,
  entity: string
): Response<components.SelectOptionsOverLimitResponse> {
  return webapi.get(`/select-options/${entity}/over-limit`, { params: { size: 1, ...params } })
}
