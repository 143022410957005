// 用于复制绑定元素的内容的 Vue 自定义指令
// 使用方式：v-copy="text"
// text: 要复制的内容，未指定时则复制绑定元素的文本内容（Inner Text）

import { ElMessage } from 'element-plus'
import type { DirectiveBinding } from 'vue'
import useClipboard from 'vue-clipboard3'

interface CopyableHTMLElement extends HTMLElement {
  $copyValue: string
  $copyHandler: () => void
}

const recursionGetRootInnerText = (el: CopyableHTMLElement): string => {
  if (el.nodeType === 3) {
    return el.nodeValue || ''
  }
  let result = ''
  for (const child of el.childNodes) {
    result += recursionGetRootInnerText(child as CopyableHTMLElement)
  }
  return result
}

export const copy = {
  mounted(el: CopyableHTMLElement, binding?: DirectiveBinding<string | number>) {
    Reflect.set(el.style, 'cursor', 'pointer')
    el.classList.add('copyable-item')

    let text = binding?.value
    // 如果未指定binding，则复制根节点的文本内容
    if (text === void 0) {
      text = recursionGetRootInnerText(el)
    }

    el.$copyValue = String(text)
    el.$copyHandler = async () => {
      if (!el.$copyValue) {
        return ElMessage.warning('复制内容不能为空')
      }
      try {
        const { toClipboard } = useClipboard()
        await toClipboard(el.$copyValue)
        ElMessage.success('复制成功')
      } catch (e) {
        ElMessage.error('复制失败')
        console.error(e)
      }
    }
    el.addEventListener('click', el.$copyHandler)
  },
  updated(el: CopyableHTMLElement, binding?: DirectiveBinding<string | number>) {
    let text = binding?.value
    if (text === void 0) {
      text = recursionGetRootInnerText(el)
    }
    el.$copyValue = String(text)
  },
  beforeUnmount(el: CopyableHTMLElement) {
    el.removeEventListener('click', el.$copyHandler)
  }
}
