import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "28",
  height: "24.267"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#a5a4bf",
      d: "M5 8.736h18a.971.971 0 1 1 0 1.941H5a.971.971 0 1 1 0-1.941M2 1.941v20.384h24V4.638H12.075l-1.333-2.7Zm11.333.755H27a.98.98 0 0 1 1 .969V23.3a.98.98 0 0 1-1 .969H1a1 1 0 0 1-.705-.288A.95.95 0 0 1 0 23.3V.966A.99.99 0 0 1 1 0h10a1.74 1.74 0 0 1 1.436.878l.9 1.818Z"
    }, null, -1)
  ])))
}
export default { render: render }