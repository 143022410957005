import { ElMessage } from 'element-plus'
import type { FormItemConfig } from '@/components/common/form-renderer/form-components'
import { ComponentType } from '@/components/common/form-renderer/form-components/type'
import { eventAssetsGet } from '@/api/ocean-engine'

export const configs: FormItemConfig[] = [
  {
    name: 'asset_type',
    label: '投放内容',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        {
          value: 'ORANGE',
          label: '橙子落地页'
        },
        {
          value: 'THIRDPARTY',
          label: '自研落地页',
          disabled: true
        },
        {
          value: 'AWEME_HOME_PAGE',
          label: '抖音页',
          disabled: true
        }
      ],
      default: 'ORANGE'
    },
    rules: [{ required: true, message: '请选择投放内容', trigger: 'change' }],
    condition: {
      'campaign.landing_type': 'LINK'
    }
  },
  {
    name: 'micro_promotion_type',
    label: '投放内容',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        {
          value: 'WECHAT_APP',
          label: '微信小程序'
        },
        {
          value: 'BYTE_APP',
          label: '字节小程序'
        }
      ],
      default: 'WECHAT_APP'
    },
    rules: [{ required: true, message: '请选择投放内容', trigger: 'change' }],
    condition: {
      'campaign.landing_type': 'MICRO_GAME'
    }
  },
  {
    name: 'optimize_goal',
    label: '',
    config: {
      type: ComponentType.COMMON_STRUCT,
      elements: [
        {
          // 由于优化目标依赖鱼mini_program_id，创建依赖asset_id，因此需要先获取这两个字段，这里先把它们拼接在一起，在用到的地方进行拆分处理
          name: 'asset_id_mini_program_id',
          label: '事件资产',
          config: {
            type: ComponentType.COMMON_SELECT,
            options: async (keyword, params) => {
              try {
                const [hasError, data] = await eventAssetsGet({
                  account_id: params.accountId.value,
                  asset_type: 'MINI_PROGRAME'
                })
                if (hasError) {
                  data?.message && ElMessage.error(data.message)
                  return []
                }
                const list = data.data.mini_program || []
                return list.map((el) => {
                  return {
                    label: el.asset_name,
                    value: `${el.asset_id}-${el.mini_program_id}`
                  }
                })
              } catch {
                return []
              }
            }
          },
          rules: [{ required: true, message: '请选择事件资产', trigger: 'change' }],
          condition: {
            'adgroup.micro_promotion_type': 'BYTE_APP'
          }
        },
        {
          name: 'asset_ids',
          label: '',
          config: {
            type: ComponentType.COMMON_HIDDEN,
            dataSelector: (data) => {
              if (!data.adgroup.optimize_goal || !data.adgroup.optimize_goal.asset_id_mini_program_id) {
                return []
              }
              if (data.adgroup.optimize_goal.asset_id_mini_program_id) {
                const assetIdStr = data.adgroup.optimize_goal.asset_id_mini_program_id.slice(
                  0,
                  data.adgroup.optimize_goal.asset_id_mini_program_id.indexOf('-')
                )
                return [Number(assetIdStr)]
              }
            }
          },
          condition: {
            'adgroup.micro_promotion_type': 'BYTE_APP'
          }
        },
        {
          name: 'external_action',
          label: '优化目标',
          config: {
            type: ComponentType.OCEAN_OPTIMIZE_GOAL
          },
          rules: [{ required: true, message: '请选择优化目标', trigger: 'change' }]
        }
      ]
    }
  },
  {
    name: 'delivery_range',
    label: '',
    config: {
      type: ComponentType.COMMON_STRUCT,
      elements: [
        {
          name: 'inventory_catalog',
          label: '广告位置',
          config: {
            type: ComponentType.COMMON_RADIO_GROUP,
            options: [
              { value: 'UNIVERSAL_SMART', label: '通投智选' },
              { value: 'MANUAL', label: '首选媒体' }
            ],
            default: 'UNIVERSAL_SMART'
          }
        },
        {
          name: 'inventory_type',
          label: '媒体选择',
          config: {
            type: ComponentType.OCEAN_INVENTORY_TYPE
          },
          rules: [{ required: true, message: '请选择媒体选择', trigger: 'change' }],
          condition: {
            'adgroup.delivery_range.inventory_catalog': 'MANUAL'
          }
        }
      ]
    }
  },
  {
    name: 'delivery_setting',
    label: '',
    config: {
      type: ComponentType.OCEAN_BUDGET_SCHEDULE
    }
  },
  {
    name: 'search_bid_ratio',
    label: '出价系数',
    tip: '设置出价系数后在搜索场景下会以目标转化出价*系数参与竞价，可以提高广告竞争胜出概获取更多转化。出价系数设置范围1.00-2.00',
    config: {
      type: ComponentType.COMMON_INPUT_NUMBER,
      unit: 1,
      min: 1,
      max: 2,
      precision: 2
    },
    condition: {
      'adgroup.delivery_setting.bid_type': {
        $ne: 'NO_BID'
      },
      'adgroup.delivery_setting.pricing': 'PRICING_OCPM'
    },
    rules: [{ required: true, message: '请输入出价系数', trigger: ['blur', 'change'] }]
  },

  {
    name: 'audience',
    label: '',
    config: {
      type: ComponentType.OCEAN_AUDIENCE_TARGET
    }
  },
  {
    name: 'audience_extend',
    label: '定向拓展',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        { value: 'ON', label: '开启' },
        { value: 'OFF', label: '关闭' }
      ],
      default: 'ON'
    }
  }
]
