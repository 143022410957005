import type { AssetSellStrategy } from '@/api/asset/type'

/** 短剧售卖方式枚举 */
export enum ShortPlayPayTypeEnum {
  FREE = 'SHORT_PLAY_PAY_TYPE_FREE_PLAY',
  CHARGE = 'SHORT_PLAY_PAY_TYPE_CHARGE_PLAY'
}

export const ShortPlayPayTypeLabel = {
  [ShortPlayPayTypeEnum.FREE]: '免费短剧',
  [ShortPlayPayTypeEnum.CHARGE]: '付费短剧'
}

export type SellStrategyDataType = AssetSellStrategy[] | Record<number, AssetSellStrategy[]>

/** tads-v3-sell-strategy-with-create-button 组件的数据值 */
export type TadsV3SellStrategyWithCreateButtonDataType = {
  /** 短剧售卖方式 */
  short_play_pay_type: ShortPlayPayTypeEnum
  /** 售卖策略 */
  sell_strategy_data?: SellStrategyDataType
}

/** 批量创建时的特殊结构 */
export type BatchCreateSubmitSellStrategyData<T> = { meta_data: { sell_strategy: T } }

/** 新建方式枚举 */
export enum CreatingTypeEnum {
  MANUAL = 'manual',
  BATCH_IMPORT = 'batch_import'
}
