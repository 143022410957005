import type { App } from 'vue'
import { permission } from '@/directives/permission'
import { NAME, accountPermissionConfig } from '@/directives/ac-permission'
import { copy } from '@/directives/copy'

/**
 * 注册全局自定义指令
 * @param app
 */
export function setupDirectives(app: App) {
  // 权限控制指令
  app.directive('permission', permission)
  // 账户协作
  app.directive(NAME, accountPermissionConfig)
  // 复制
  app.directive('copy', copy)
}
