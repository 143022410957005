import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "28",
  height: "24.267",
  viewBox: "0 0 30 29.999"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#928df4",
      d: "m29.71 6.741-15.2-6.7a.48.48 0 0 0-.39 0L.283 6.373A.49.49 0 0 0 0 6.816v15.737a.49.49 0 0 0 .275.443L14.62 29.95a.48.48 0 0 0 .422 0l14.687-7.272a.49.49 0 0 0 .271-.442V7.196a.49.49 0 0 0-.29-.45Zm-15.9 20.62L1.67 21.496V9.59l12.14 5.233V27.36Zm1.172-14.587L2.468 7.541a.4.4 0 0 0-.117-.033l12-5.275 12.841 5.443-12.21 5.1Zm13.351 8.422-12.153 6V14.82l12.153-5.24zM3.695 15.181a.96.96 0 0 1-.113-1.342.93.93 0 0 1 1.318-.114l6.262 2.947a.96.96 0 0 1 .113 1.342.93.93 0 0 1-1.322.117l-6.262-2.95Z"
    }, null, -1)
  ])))
}
export default { render: render }