export type TACPermission = 0b000001 | 0b000010 | 0b000011 | 0b00101 | 0b000110 | 0b000111

export const NAME = 'acPermission'
export const accountPermissionConfig = {
  mounted(el, binding) {
    //用户的权限
    const userPermission = binding.value
    //需要拥有的权限
    const validatePermission = binding.arg
    //判断是禁用还是销毁
    const isDisable = binding.modifiers.disable
    //是否有当前权限
    const result = hasAccountPermission(userPermission, validatePermission)

    if (!result) {
      if (isDisable) {
        el.style.background = '#dddddd'
        el.style.pointerEvents = 'none'
        el.style.cursor = 'not-allowed'
        el.style.border = 'none'
        el.disabled = true
        el.style['disabled'] = 'disabled'
        return
      } //变成禁用样式
      el.remove() // 删除当前节点
    }
  }
}
export const BASE: TACPermission = 1
export const READ = (BASE << 0) as TACPermission
export const EDIT = (BASE << 1) as TACPermission
export const ADD = (BASE << 2) as TACPermission

export const removePermission = (auth: TACPermission, permission: TACPermission) => {
  // 参数边界值处理
  if (auth === (BASE | READ)) {
    return BASE
  }
  return (auth & ~permission) as TACPermission
}
export const addPermission = (auth: TACPermission, permission: TACPermission) => {
  return (auth | permission) as TACPermission
}
export const hasAccountPermission = (auth: TACPermission, permission: TACPermission) => {
  return (auth & permission) === permission
}
