import type { FormItemConfig } from '@/components/common/form-renderer/form-components'
import { ComponentType } from '@/components/common/form-renderer/form-components/type'

export const configs: FormItemConfig[] = [
  {
    name: 'objective',
    label: '广告目标',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        {
          label: '销量',
          value: 'OUTCOME_SALES'
        },
        {
          label: '互动',
          value: 'OUTCOME_ENGAGEMENT',
          disabled: true
        }
      ],
      default: 'OUTCOME_SALES'
    },
    rules: [
      {
        validator: (rule, value, callback) => {
          if (value !== 'OUTCOME_SALES') {
            callback(new Error('提示：暂不支持非销量选项'))
          }
          callback()
        },
        trigger: 'change'
      }
    ]
  },
  {
    name: 'special_ad_categories',
    label: '特殊广告类别',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: ['NONE']
    }
  },
  {
    name: 'buying_type',
    label: '购买类型',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        {
          label: '竞拍',
          value: 'AUCTION'
        }
      ],
      default: 'AUCTION'
    }
  },
  {
    name: 'smart_promotion_type',
    label: '广告系列设置',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        {
          label: '手动创建销量广告系列',
          value: 'GUIDED_CREATION'
        },
        {
          label: '进阶赋能型智能购物广告',
          value: 'AUTOMATED_SHOPPING_ADS'
        }
      ],
      default: 'GUIDED_CREATION'
    }
  },
  {
    name: 'is_budget_schedule_enabled',
    label: '广告预算优化',
    config: {
      type: ComponentType.COMMON_SWITCH,
      default: false
    },
    condition: {
      'campaign.smart_promotion_type': 'GUIDED_CREATION'
    }
  },
  {
    name: 'bid_strategy',
    label: '竞价策略',
    config: {
      type: ComponentType.COMMON_SELECT,
      options: [
        {
          label: '单次成效费用目标',
          value: 'COST_CAP'
        },
        {
          label: '最高数量',
          value: 'LOWEST_COST_WITHOUT_CAP'
        },
        {
          label: '竞价上限',
          value: 'LOWEST_COST_WITH_BID_CAP'
        }
      ],
      default: 'LOWEST_COST_WITHOUT_CAP'
    },
    condition: {
      'campaign.is_budget_schedule_enabled': true,
      'campaign.smart_promotion_type': 'GUIDED_CREATION'
    }
  },
  {
    name: 'budget_type',
    label: '预算类型',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        {
          label: '日预算',
          value: 'daily_budget'
        },
        {
          label: '总预算',
          value: 'lifetime_budget'
        }
      ],
      default: 'daily_budget'
    },
    condition: {
      'campaign.is_budget_schedule_enabled': true,
      'campaign.smart_promotion_type': 'GUIDED_CREATION'
    }
  },
  {
    name: 'daily_budget',
    label: '日预算',
    config: {
      type: ComponentType.COMMON_INPUT_NUMBER,
      unit: 100,
      min: 0,
      precision: 2,
      suffix: '元',
      string: true
    },
    rules: [{ required: true, message: '请输入日预算', trigger: 'blur' }],
    condition: {
      'campaign.is_budget_schedule_enabled': true,
      'campaign.budget_type': 'daily_budget',
      'campaign.smart_promotion_type': 'GUIDED_CREATION'
    }
  },
  {
    name: 'lifetime_budget',
    label: '总预算',
    config: {
      type: ComponentType.COMMON_INPUT_NUMBER,
      unit: 100,
      min: 0,
      precision: 2,
      suffix: '元',
      string: true
    },
    rules: [{ required: true, message: '请输入总预算', trigger: 'blur' }],
    condition: {
      'campaign.is_budget_schedule_enabled': true,
      'campaign.budget_type': 'lifetime_budget',
      'campaign.smart_promotion_type': 'GUIDED_CREATION'
    }
  },
  {
    name: 'name',
    label: '计划名称',
    config: {
      type: ComponentType.COMMON_INPUT_TITLE,
      attr: {
        maxlength: 50,
        showWordLimit: true,
        style: {
          width: '450px'
        },
        clearable: true
      },
      default: '计划名称_{{日期}}_{{时间}}_{{标识}}',
      wildcards: ['日期', '时间', '标识', '素材名']
    },
    rules: [{ required: true, message: '请输入计划名称', trigger: 'blur' }]
  },
  {
    name: 'status',
    label: '广告状态',
    config: {
      type: ComponentType.COMMON_SWITCH,
      attr: {
        activeValue: 'ACTIVE',
        inactiveValue: 'PAUSED'
      },
      default: 'ACTIVE'
    }
  }
]
