export const changeTheme = () => {
  const node = document.documentElement

  const mixWhite = '#ffffff'
  const mixBlack = '#000000'

  const colorList = [
    { pre: '--el-color-primary', color: '#3B86FF' },
    { pre: '--el-color-success', color: '#6EB243' },
    { pre: '--el-color-warning', color: '#f2AA3C' },
    { pre: '--el-color-danger', color: '#EB6868' },
    { pre: '--el-color-info', color: '#8989A5' },
    { pre: '--el-color-purple', color: '#6C7CE2' },
    { pre: '--el-color-blue', color: '#3B86FF' },
    { pre: '--el-color-theme', color: '#43425D' },
    { pre: '--el-color-theme-button', color: '#525179' }
  ]

  // 颜色混合函数
  const mix = (color1: string, color2: string, weight: number) => {
    weight = Math.max(Math.min(Number(weight), 1), 0)
    const r1 = parseInt(color1.substring(1, 3), 16)
    const g1 = parseInt(color1.substring(3, 5), 16)
    const b1 = parseInt(color1.substring(5, 7), 16)
    const r2 = parseInt(color2.substring(1, 3), 16)
    const g2 = parseInt(color2.substring(3, 5), 16)
    const b2 = parseInt(color2.substring(5, 7), 16)
    const r = Math.round(r1 * (1 - weight) + r2 * weight)
    const g = Math.round(g1 * (1 - weight) + g2 * weight)
    const b = Math.round(b1 * (1 - weight) + b2 * weight)
    const _r = ('0' + (r || 0).toString(16)).slice(-2)
    const _g = ('0' + (g || 0).toString(16)).slice(-2)
    const _b = ('0' + (b || 0).toString(16)).slice(-2)
    return '#' + _r + _g + _b
  }

  for (let item = 0; item < colorList.length; item++) {
    node.style.setProperty(colorList[item].pre, colorList[item].color)
    node.style.setProperty(`${colorList[item].pre}-dark-2`, mix(colorList[item].color, mixBlack, 2 * 0.1))
    // 设置light-i色号
    for (let i = 1; i < 10; i++) {
      node.style.setProperty(`${colorList[item].pre}-light-${i}`, mix(colorList[item].color, mixWhite, i * 0.1))
    }
  }
}
