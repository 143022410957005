import type { FormItemConfig } from '@/components/common/form-renderer/form-components'
import { ComponentType } from '@/components/common/form-renderer/form-components/type'
export const configs: FormItemConfig[] = [
  {
    name: ['delivery_mode', 'dynamic_creative_type'],
    label: '投放模式',
    tip: '组件化创意下，通过上传丰富优质的图片、视频、文案、跳转链路、营销组件等创意组件，系统将自动根据每次广告请求匹配最有可能转化的组件组合为媒体用户展示，提高投放效率与效果',
    config: {
      type: ComponentType.TADS_V3_CREATIVE_MODE
    }
  },
  {
    name: 'creative_template_id',
    label: '创意形式',
    tip: '当投放模式为组件化创意时，系统将自动匹配创意形式，此时选中的创意形式默认为第一项，无需更改',
    config: {
      type: ComponentType.TADS_V3_CREATIVE_TEMPLATE
    }
  },
  {
    name: 'creative_components',
    label: '',
    sectionLabel: '营销组件',
    config: {
      type: ComponentType.TADS_V3_CREATIVE_COMPONENTS
    }
  },
  {
    name: 'dynamic_creative_name',
    label: '创意名称',
    sectionLabel: '创意设置',
    tip: '创意名称仅用于管理创意，不会对外展示',
    config: {
      type: ComponentType.COMMON_INPUT_TITLE,
      default: '创意_{{日期}}_{{时间}}_{{标识}}',
      wildcards: ['日期', '时间', '标识', '素材名', '定向模版'],
      attr: {
        placeholder: '请输入创意名称',
        maxlength: 60,
        clearable: true,
        showWordLimit: true,
        style: {
          width: '450px'
        }
      }
    },
    rules: [
      { required: true, message: '请输入创意名称', trigger: ['blur', 'change'] },
      { max: 60, message: '长度超过60个字，请重新调整', trigger: ['blur', 'change'] }
    ]
  }
]
