<template>
  <div class="nav-bar">
    <div class="nav-bar-space">
      <component :is="defineAsyncComponent(route.meta.navBarComponent)" v-if="route.meta.navBarComponent" />
    </div>
    <el-space class="sys-message" :size="20">
      <in-dropdown
        dropdown-type="text"
        :model-value="timeZoneStore.timezone"
        :options="timeZoneStore.timezoneList.filter((item) => item.status === 1)"
        @change="timeZoneStore.setTimezone"
        class="dropdown-box"
        :showDesc="true"
      />
      <in-dropdown
        dropdown-type="text"
        :model-value="currencyStore.currency"
        :options="currencyStore.currencyList"
        @change="currencyStore.setCurrency"
      />
      <el-dropdown trigger="click" class="pointer" @command="handleCommand">
        <el-space class="user-message">
          <el-divider class="vertical-divider" direction="vertical" />
          <el-icon><Avatar /></el-icon>
          <span>
            <span>{{ username }}</span>
            <el-icon class="el-icon--right"><arrow-down /></el-icon>
          </span>
        </el-space>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </el-space>
  </div>
</template>
<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { clearAuth } from '@/stores/auth/index'
import { logout } from '@/api/page-api'
import { computed, defineAsyncComponent } from 'vue'
import { useTimezoneStore } from '@/stores/user-config/timezone'
import { useCurrencyStore } from '@/stores/user-config/currency'
const timeZoneStore = useTimezoneStore()
const currencyStore = useCurrencyStore()
const router = useRouter()
const route = useRoute()
const handleCommand = (command: string) => {
  if (command === 'logout') {
    onLogout()
  }
}
const onLogout = async () => {
  const [hasError, data] = await logout()
  if (hasError) return data?.message && ElMessage.error(data.message)
  ElMessage.success('退出成功')
  clearAuth()
  router.replace('/login')
}
const username = computed(() => {
  const auth = JSON.parse(localStorage.getItem('auth') as string)
  return `${auth?.name_en}（${auth?.name}）`
})
</script>
<style scoped>
:root {
  --nav-bar-height: 70px;
  --nav-bar-button: #605e87;
}
.nav-bar {
  width: 100%;
  height: 60px;
  top: 0;
  right: 0;
  z-index: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  padding: 0 40px;
  background-color: #fff;
  box-shadow: var(--panel-box-shadow);
  user-select: none;
}
.nav-bar-space {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}
.sys-message {
  display: flex;
  align-items: center;
}
:focus-visible {
  outline: none;
}
.user-message {
  display: flex;
  align-items: center;
  color: var(--el-color-primary);
}
.user-message .el-icon {
  vertical-align: bottom;
}
.vertical-divider {
  border-width: 2px;
  height: 1.6em;
}
</style>
