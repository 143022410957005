import webapi, { type Response } from '../service'
import type * as components from './type'

/**
 * 角色列表
 * @param params
 */
export function roleList(params: components.RoleListRequest): Response<components.RoleListResponse> {
  return webapi.get(`/auth/role/list`, { params })
}

/**
 * 新增/修改角色
 * @param req
 */
export function roleSave(req: components.RoleSaveRequest): Response<components.RoleSaveResponse> {
  return webapi.post(`/auth/role/save`, req)
}

/**
 * 角色详情
 * @param params
 */
export function roleDetail(params: components.RoleDetailRequest): Response<components.RoleDetailResponse> {
  return webapi.get(`/auth/role/detail`, { params })
}

/**
 * 删除角色
 * @param req
 */
export function roleDelete(req: components.RoleDeleteRequest): Response<components.RoleDeleteResponse> {
  return webapi.post(`/auth/role/delete`, req)
}

/**
 * 菜单列表
 * @param params
 */
export function authMenu(params?: components.AuthMenuRequest): Response<components.AuthMenuResponse> {
  return webapi.get(`/auth/menu`, { params })
}

/**
 * 用户列表
 * @param req
 */
export function userList(req: components.UserListRequest): Response<components.UserListResponse> {
  return webapi.post(`/auth/user/list`, req)
}

/**
 * 部门列表
 * @param params
 */
export function deptList(): Response<components.DeptListResponse> {
  return webapi.get(`/auth/dept/list`)
}

/**
 * 修改用户状态
 * @param req
 */
export function userUpdateStatus(
  req: components.UserUpdateStatusRequest
): Response<components.UserUpdateStatusResponse> {
  return webapi.post(`/auth/user/update/status`, req)
}

/**
 * 修改用户
 * @param req
 */
export function userSave(req: components.UserSaveRequest): Response<components.UserSaveResponse> {
  return webapi.post(`/auth/user/save`, req)
}

/**
 * 用户详情
 * @param params
 */
export function userDetail(params: components.UserDetailRequest): Response<components.UserDetailResponse> {
  return webapi.get(`/auth/user/detail`, { params })
}

/**
 * 当前用户的权限菜单点
 * @param params
 */
export function userMenu(): Response<components.UserMenuResponse> {
  return webapi.get(`/auth/usermenu`)
}

/**
 * 当前用户的数据权限范围
 * @param params
 */
export function userDataScope(): Response<components.UserDataScopeResponse> {
  return webapi.get(`/auth/user/data_scope`)
}

/**
 * 当前用户配置（时区、币种）
 * @param params
 */
export function userConfig(): Response<components.UserConfig> {
  return webapi.get(`/user_config/detail`)
}

/**
 * 更新用户配置（时区、币种）
 * @param req
 */
export function userSaveConfig(req: components.userSaveConfigRequest): Response<components.UserConfig> {
  return webapi.post(`/user_config/save`, req)
}
