import type { RouteRecordRaw } from 'vue-router'

const report: RouteRecordRaw[] = [
  {
    path: '/',
    name: '/',
    redirect: '/report'
  },
  {
    path: '/report/:id?',
    name: 'report-detail',
    component: () => import('@/views/report/index.vue'),
    meta: {
      permission: 'report:list',
      title: '动态报表',
      navBarComponent: void 0
    },
    children: [
      {
        path: 'report-edit/:dimension/:operateId/:accountId/:platformId',
        name: 'ad-manage-edit',
        component: () => import('@/views/ad-manage/edit/index.vue'),
        meta: {
          permission: 'report:ad:edit',
          title: '修改内容',
          navBarComponent: void 0
        }
      },
      {
        path: 'report-copy',
        name: 'ad-manage-copy',
        component: () => import('@/views/ad-manage/copy/index.vue'),
        meta: {
          permission: 'report:ad:copy',
          title: '复制内容',
          navBarComponent: void 0
        }
      },
      {
        path: 'report-batch-copy',
        name: 'ad-manage-batch-copy',
        component: () => import('@/views/ad-manage/copy/index.vue'),
        meta: {
          permission: 'report:ad:copy',
          title: '批量复制内容',
          navBarComponent: void 0
        }
      }
    ]
  }
]
export default report
