import type { PlatformCurrencyInputConfig } from '../index'
export const currencyInputConfig: PlatformCurrencyInputConfig = {
  adgroup: {
    bid_amount: {
      CNY: { precision: 2, min: 0.01, max: 5000, suffix: '元/千次曝光' },
      USD: { precision: 2, min: 0.01, max: 5000, suffix: '美元/千次曝光' },
      EUR: { precision: 2, min: 0.01, max: 5000, suffix: '欧元/千次曝光' },
      GBP: { precision: 2, min: 0.01, max: 5000, suffix: '英镑/千次曝光' },
      AUD: { precision: 2, min: 0.01, max: 5000, suffix: '澳元/千次曝光' },
      CAD: { precision: 2, min: 0.01, max: 5000, suffix: '加元/千次曝光' },
      NZD: { precision: 2, min: 0.01, max: 5000, suffix: '新西兰元/千次曝光' }
    },
    auto_acquisition_budget: {
      CNY: { min: 200, max: 100000, precision: 2, suffix: '元' },
      USD: { min: 200, max: 100000, precision: 2, suffix: '美元' },
      EUR: { min: 200, max: 100000, precision: 2, suffix: '欧元' },
      GBP: { min: 200, max: 100000, precision: 2, suffix: '英镑' },
      AUD: { min: 200, max: 100000, precision: 2, suffix: '澳元' },
      CAD: { min: 200, max: 100000, precision: 2, suffix: '加元' },
      NZD: { min: 200, max: 100000, precision: 2, suffix: '新西兰元' }
    },
    daily_budget: {
      CNY: { min: 50, max: 40000000, suffix: '元/日', precision: 2 },
      USD: { min: 50, max: 40000000, suffix: '美元/日', precision: 2 },
      EUR: { min: 50, max: 40000000, suffix: '欧元/日', precision: 2 },
      GBP: { min: 50, max: 40000000, suffix: '英镑/日', precision: 2 },
      AUD: { min: 50, max: 40000000, suffix: '澳元/日', precision: 2 },
      CAD: { min: 50, max: 40000000, suffix: '加元/日', precision: 2 },
      NZD: { min: 50, max: 40000000, suffix: '新西兰元/日', precision: 2 }
    }
  },
  campaign: {
    // 计划日预算
    daily_budget: {
      CNY: { min: 50, max: 40000000, suffix: '元/日', precision: 2 },
      USD: { min: 50, max: 40000000, suffix: '美元/日', precision: 2 },
      EUR: { min: 50, max: 40000000, suffix: '欧元/日', precision: 2 },
      GBP: { min: 50, max: 40000000, suffix: '英镑/日', precision: 2 },
      AUD: { min: 50, max: 40000000, suffix: '澳元/日', precision: 2 },
      CAD: { min: 50, max: 40000000, suffix: '加元/日', precision: 2 },
      NZD: { min: 50, max: 40000000, suffix: '新西兰元/日', precision: 2 }
    },
    // 计划总预算
    total_budget: {
      CNY: { min: 50, max: 40000000, suffix: '元', precision: 2 },
      USD: { min: 50, max: 40000000, suffix: '美元', precision: 2 },
      EUR: { min: 50, max: 40000000, suffix: '欧元', precision: 2 },
      GBP: { min: 50, max: 40000000, suffix: '英镑', precision: 2 },
      AUD: { min: 50, max: 40000000, suffix: '澳元', precision: 2 },
      CAD: { min: 50, max: 40000000, suffix: '加元', precision: 2 },
      NZD: { min: 50, max: 40000000, suffix: '新西兰元', precision: 2 }
    }
  },
  adcreative: {}
}
