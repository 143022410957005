import type { RouteRecordRaw } from 'vue-router'

/** 账户管家 */
const accountManager: RouteRecordRaw[] = [
  {
    path: '/asset/account/:accountId?/:platformId?/:platformAccountId?/:strategyId?',
    name: 'asset-manage-account',
    component: () => import('@/views/asset-manage/account/ad-account/index.vue'),
    meta: {
      permission: 'account:list',
      title: '广告账户'
    }
  },
  {
    path: '/asset/media-authorization',
    name: 'media-authorization',
    component: () => import('@/views/asset-manage/account/media-authorization/index.vue'),
    meta: {
      permission: 'media_account:list',
      title: '媒体授权'
    }
  }
]

/** 资产管理 */
const assetManager: RouteRecordRaw[] = [
  {
    path: '/asset/product',
    name: 'product-manage',
    component: () => import('@/views/asset-manage/product/product-list/index.vue'),
    meta: {
      permission: 'product:list',
      title: '商品管理'
    }
  },
  {
    path: '/asset/material',
    name: 'material-manage',
    component: () => import('@/views/asset-manage/material/index.vue'),
    meta: {
      permission: 'material:list',
      title: '素材管理'
    }
  },
  {
    path: '/asset/landing-page',
    name: 'asset-landing-page',
    component: () => import('@/views/asset-manage/landing-page/index.vue'),
    meta: {
      permission: 'account:task_list',
      title: '落地页'
    }
  },
  {
    path: '/asset/brand',
    name: 'asset-brand',
    component: () => import('@/views/asset-manage/brand/index.vue'),
    meta: {
      permission: 'material:list',
      title: '品牌形象'
    }
  }
]
export default [...accountManager, ...assetManager]
