import { userMenu } from '@/api/auth'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usePermissionStore = defineStore('permissionStore', () => {
  const permissionList = ref<string[]>([])

  const setPermissionList = (list: string[]) => {
    permissionList.value = list
  }

  const getPermissionList = () => {
    return permissionList.value
  }

  const getPermissionListByApi = async () => {
    try {
      const [hasError, result] = await userMenu()
      if (hasError) {
        setPermissionList([])
        return
      }
      setPermissionList(result.data.permission_list)
      return result.data.permission_list
    } catch (err) {
      setPermissionList([])
    }
  }

  return { setPermissionList, getPermissionList, getPermissionListByApi }
})
