import { defineStore } from 'pinia'
import { reactive } from 'vue'
interface ControlBar {
  operationDialog: boolean
  productAddDialogStatus: boolean
}
export const useDialogStatusControlStore = defineStore('dialogStatusControlStore', {
  state: () => {
    return reactive<ControlBar>({
      operationDialog: false,
      productAddDialogStatus: false
    })
  },
  actions: {
    showOperationDialog(): void {
      this.operationDialog = true
    },
    showProductAddDialog(): void {
      this.productAddDialogStatus = true
    },
    closeDialog(): void {
      this.operationDialog = false
      this.productAddDialogStatus = false
    }
  }
})
