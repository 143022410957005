import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "260",
  height: "260",
  version: "1.0",
  viewBox: "0 0 195 195"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M30.8 8.4c-9.8 3.5-15.7 8.7-20.6 18l-2.7 5.1v131l2.8 5.6c3 6.3 9.2 12.5 15.9 16.1l4.3 2.3h133l5-2.7c6-3.2 12.4-9.7 15.6-15.8l2.4-4.5.3-64.6c.3-63.4.2-64.8-1.8-70.2-2.8-7.4-9.6-14.9-16.8-18.4l-5.7-2.8-64-.2c-51-.2-64.7 0-67.7 1.1M120 30.7c0 1 1 4.7 2.2 8.2 3.6 10.3 14.7 20 22.8 20.1 5 .1 5.5 1.1 5.5 12.2v10.2l-5-.3c-5.7-.3-15.5-3.3-20.1-6.2-1.7-1-3.4-1.9-3.7-1.9s-.7 11.4-.9 25.2c-.4 23.3-.6 25.7-2.7 31.1-6.2 15.9-20.5 25.8-37.2 25.9-9.5.1-16.1-2-23.8-7.7-6.8-5-11.5-11.3-14.3-19.3-3-8.4-2.3-21.7 1.6-29.5C50.8 85.6 63.6 77 78.2 76.2l7.8-.5V98h-5.1c-10.3 0-17 6-17.7 15.9-1.2 17.1 17.9 26.2 29.9 14.2 2-2 4.2-5.4 4.8-7.6.7-2.6 1.1-18.9 1.1-47.8V29h10.5c9.3 0 10.5.2 10.5 1.7" }, null, -1)
  ])))
}
export default { render: render }