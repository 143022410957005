import webapi, { type Response } from '../service'
import type * as components from './type'

/**
 * 单账户批量创建广告
 * @param req
 */
export function batchCreate(req: components.BatchCreateRequest): Response<components.BatchCreateResponse> {
  return webapi.post(`/batch/create`, req)
}

/**
 * 操作中心重试任务
 * @param req
 */
export function batchRetry(req: components.BatchRetryRequest): Response<components.BatchRetryResponse> {
  return webapi.post(`/batch/retry`, req)
}
