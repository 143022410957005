import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "26",
  height: "26",
  class: "icon",
  viewBox: "0 0 1024 1024"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#928DF4",
      d: "M536.448 736v-46.72c21.696-6.784 38.976-19.84 51.968-39.232C601.472 630.528 608 607.936 608 582.4c0-25.984-7.232-48.192-21.76-66.56q-7.68-10.176-22.848-21.12c-15.168-10.944-22.976-15.04-38.528-23.296-17.92-9.472-29.888-17.728-35.776-24.64-6.016-7.04-9.024-15.936-9.024-26.624 0-11.712 3.712-21.504 11.136-29.312s16.832-11.712 28.096-11.712c18.24 0 32.96 12.032 44.224 36.096l37.504-24.576c-8.704-18.368-17.92-31.936-27.52-40.832s-22.016-14.72-37.12-17.6V288h-33.6v44.224c-10.432 3.136-19.712 7.488-27.904 13.056s-15.104 12.16-20.672 19.712c-5.568 7.616-9.856 16.128-12.8 25.408-2.944 9.344-4.416 19.392-4.416 30.208 0 22.848 6.208 42.496 18.688 58.88 7.808 11.008 24.192 22.976 49.024 36.096 12.032 6.464 21.952 12.416 29.696 17.92s13.632 10.432 17.6 14.784c7.808 9.024 11.712 20.992 11.712 35.84 0 16.704-4.672 30.336-14.016 40.832-9.472 10.688-21.312 16-35.392 16-14.528 0-26.112-4.672-34.816-14.016-8.32-9.792-15.36-26.112-21.184-48.96L416 588.48c6.272 32.32 16 56.256 29.248 71.744 13.184 15.488 32.384 25.664 57.536 30.464V736z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#928DF4",
      d: "M1024 512C1024 229.248 794.752 0 512 0S0 229.248 0 512s229.248 512 512 512 512-229.248 512-512m-960 0C64 264.576 264.576 64 512 64s448 200.576 448 448-200.576 448-448 448S64 759.424 64 512"
    }, null, -1)
  ])))
}
export default { render: render }