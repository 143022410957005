import { selectOptions } from '@/api/page-api/index'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { SelectOption } from '@/api/page-api/type'
import { setUserConfig } from '.'
import _ from 'lodash'

export interface TimezoneType extends SelectOption {
  desc: string
  status: number
}
// 默认值
export const timezoneDefaultValue = 'CST'
export const useTimezoneStore = defineStore('timezoneStore', () => {
  const timezoneList = ref<TimezoneType[]>([])
  const timezone = ref<string>(timezoneDefaultValue)
  const setTimezoneList = (list: TimezoneType[]) => {
    timezoneList.value = list
  }

  const getTimezoneList = async () => {
    try {
      const [hasError, data] = await selectOptions({}, 'timezone')
      if (hasError) {
        setTimezoneList([])
        return
      }
      // 处理数据
      const _data: TimezoneType[] = []
      for (const item of data.data.options) {
        _data.push({
          desc: item.label,
          label: item.value,
          value: item.value.split('(')[0],
          status: item.status || 0
        })
      }
      setTimezoneList(_data)
    } catch (err) {
      setTimezoneList([])
    }
  }

  const setTimezoneValue = (value: string) => {
    timezone.value = value
  }

  const setTimezone = async (value: string) => {
    timezone.value = value
    await setUserConfig({ timezone: value })
  }
  // 获取ms偏移量
  const getUtcOffset = (utc?: string) => {
    const utcValue = utc ? utc : _.find(timezoneList.value, (item) => item.value === timezone.value)?.desc
    if (utcValue && utcValue !== 'UTC+08:00') {
      const h = parseInt(utcValue.slice(4, 6)) || 0
      const m = parseInt(utcValue.slice(7, 9)) || 0
      const operator = utcValue.slice(3, 4) || ''
      return Number(operator + ((h + 8) * 60 + m) * 60 * 1000)
    }
    return 0
  }

  return { timezone, timezoneList, getTimezoneList, setTimezone, setTimezoneValue, getUtcOffset }
})
