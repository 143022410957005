<template>
  <div class="height-100">
    <el-container v-loading="!doneFetchConfig" class="main-container" v-if="showNavBar">
      <el-aside :width="sideBarWidth + 'px'" class="aside">
        <SideBar v-model:sideBarWidth="sideBarWidth" />
      </el-aside>
      <el-container class="main-context">
        <el-header>
          <NavBar />
        </el-header>
        <div class="main" v-if="doneFetchConfig">
          <router-view />
        </div>
      </el-container>
    </el-container>
    <div id="main" v-else>
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import NavBar from './components/nav-bar/index.vue'
import SideBar from './components/side/side-bar.vue'
import { useTimezoneStore } from '@/stores/user-config/timezone'
import { useCurrencyStore } from '@/stores/user-config/currency'
import { getUserConfig } from '@/stores/user-config'

const timeZoneStore = useTimezoneStore()
const currencyStore = useCurrencyStore()

const router = useRouter()
const sideBarWidth = ref<number>(200)
const showNavBar = computed(() => {
  let routeName = ref<string>(router.currentRoute.value.name as string)
  if (routeName.value === void 0) {
    return false
  }
  return ['', 'login', 'login-facebook'].indexOf(routeName.value) === -1
})

const doneFetchConfig = ref(false)
const handleGetUserConfig = async () => {
  await timeZoneStore.getTimezoneList()
  await currencyStore.getCurrencyList()
  await getUserConfig()
  doneFetchConfig.value = true
}
const init = () => {
  if (['batch-create'].indexOf(String(router.currentRoute.value.name)) !== -1) {
    sideBarWidth.value = 70
  }
}
init()
watch(showNavBar, () => {
  if (showNavBar.value && !doneFetchConfig.value) {
    handleGetUserConfig()
  }
})
</script>

<style scoped>
:global(:root) {
  --page-padding: 10px;
  --panel-box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  --panel-background-color: #fff;
}
:global(#app:has(.login-panel)) {
  min-width: min-content !important;
  min-height: min-content !important;
}
#main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100%;
  padding: var(--page-padding);
  background-color: #f0f0f7;
}
#main .el-main {
  padding: 0;
}
.el-aside {
  overflow: visible;
}
.aside {
  height: 100%;
  transition: all 0.3s;
}
.el-header {
  padding: 0 !important;
  /* margin-bottom: 20px; */
  position: sticky;
  top: 0;
  z-index: 100;
}
.el-container {
  height: 100%;
  overflow-y: auto;
}
.main {
  margin: 10px;
}
</style>
