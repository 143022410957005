import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "25",
  height: "25",
  class: "icon",
  viewBox: "0 0 1024 1024"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#928DF4",
      d: "M512 550.09a238.1 238.1 0 0 1-168.36-406.45 238.1 238.1 0 0 1 336.72 336.72A236.53 236.53 0 0 1 512 550.09m0-428.18c-104.82 0-190.09 85.27-190.09 190.09S407.18 502.09 512 502.09 702.09 416.82 702.09 312 616.82 121.91 512 121.91"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#928DF4",
      d: "M112 950.09H64a447.66 447.66 0 0 1 448-448v48c-220.56 0-400 179.44-400 400m516.89-353.04H960v48H628.89zm0 152.52H960v48H628.89zm0 152.52H960v48H628.89z"
    }, null, -1)
  ])))
}
export default { render: render }