import type { FormItemConfig } from '@/components/common/form-renderer/form-components'
import { ComponentType } from '@/components/common/form-renderer/form-components/type'

export const callToActionElement = {
  name: 'type',
  label: '行动号召',
  tip: '在广告中展示按钮或链接，吸引受众采取预期操作。',
  config: {
    type: ComponentType.COMMON_SELECT,
    options: [
      { label: '无按钮', value: 'NO_BUTTON' },
      { label: '详细了解', value: 'LEARN_MORE' },
      { label: '立即订购', value: 'ORDER_NOW' },
      { label: '去逛逛', value: 'SHOP_NOW' },
      { label: '立即收听', value: 'LISTEN_NOW', disabled: true },
      { label: '玩游戏', value: 'PLAY_GAME', disabled: true },
      { label: '预约时间', value: 'REQUEST_TIME', disabled: true },
      { label: '查看菜单', value: 'SEE_MENU', disabled: true },
      { label: '立即注册', value: 'SIGN_UP', disabled: true },
      { label: '订阅', value: 'SUBSCRIBE', disabled: true },
      { label: '更多视频', value: 'WATCH_MORE', disabled: true },
      { label: '立即申请', value: 'APPLY_NOW', disabled: true },
      { label: '立即预订', value: 'BOOK_TRAVEL', disabled: true },
      { label: '购票', value: 'BUY_TICKETS', disabled: true },
      { label: '联系我们', value: 'CONTACT_US', disabled: true },
      { label: '下载', value: 'DOWNLOAD', disabled: true },
      { label: '立即询价', value: 'GET_QUOTE', disabled: true },
      { label: '查看场次', value: 'GET_SHOWTIMES', disabled: true },
      { label: '领取优惠', value: 'GET_OFFER_VIEW', disabled: true }
    ]
  },
  rules: [
    { required: true, message: '请选择行动号召', trigger: 'change' },
    {
      validator: (rule, value, callback) => {
        if (!['NO_BUTTON', 'LEARN_MORE', 'ORDER_NOW', 'SHOP_NOW'].includes(value)) {
          callback(new Error('提示：该选项暂不受支持'))
        }
        callback()
      },
      trigger: 'change'
    }
  ]
}

export const configs: FormItemConfig[] = [
  {
    name: 'name',
    label: '广告名称',
    config: {
      type: ComponentType.COMMON_INPUT_TITLE,
      default: '广告名称_{{日期}}_{{时间}}_{{标识}}',
      wildcards: ['日期', '时间', '标识', '素材名']
    },
    rules: [{ required: true, message: '请输入广告名称', trigger: 'blur' }]
  },

  {
    name: 'ads_set',
    label: '广告设置',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        { label: '创建广告', value: 'create' },
        { label: '使用现有帖子', value: 'post' }
      ],
      default: 'create'
    },
    rules: [{ required: true, message: '请选择广告设置', trigger: 'blur' }]
  },
  {
    name: 'ad_format',
    label: '格式',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        { label: '单图片或视频', value: 'SINGLE_IMAGE_OR_VIDEO' },
        { label: '轮播', value: 'CAROUSEL' }
      ],
      default: 'SINGLE_IMAGE_OR_VIDEO'
    },
    condition: {
      'adcreative.ads_set': 'create'
    }
  },
  {
    name: ['object_story_id', 'object_type', 'body'],
    label: '帖子',
    sectionLabel: '创意',
    config: {
      type: ComponentType.FACEBOOK_OBJECT_STORY
    },
    condition: {
      'adcreative.ads_set': 'post'
    }
  },
  {
    name: 'contextual_multi_ads',
    label: '',
    config: {
      type: ComponentType.COMMON_STRUCT,
      elements: [
        {
          name: 'enroll_status',
          label: '多广告主广告',
          config: {
            type: ComponentType.COMMON_SWITCH,
            attr: {
              activeValue: 'OPT_IN',
              inactiveValue: 'OPT_OUT'
            },
            default: 'OPT_IN'
          }
        },
        {
          name: 'eligibility',
          label: '',
          config: {
            type: ComponentType.COMMON_HIDDEN,
            default: ['POST_AD_ENGAGEMENT_FEED', 'POST_AD_ENGAGEMENT_SEED_AD', 'STANDALONE_FEED']
          }
        }
      ]
    }
  },
  {
    name: 'degrees_of_freedom_spec',
    label: '',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: {
        ad_handle_type: 'DYNAMIC',
        creative_features_spec: {
          standard_enhancements: { enroll_status: 'OPT_IN' }
        }
      }
    },
    condition: {
      'adcreative.ad_format': 'SINGLE_IMAGE_OR_VIDEO'
    }
  },
  {
    name: 'degrees_of_freedom_spec',
    label: '',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: {
        ad_handle_type: 'DYNAMIC',
        creative_features_spec: {
          standard_enhancements: { enroll_status: 'OPT_OUT' }
        }
      }
    },
    condition: {
      'adcreative.ad_format': 'CAROUSEL'
    }
  },
  {
    name: ['object_story_spec', 'asset_feed_spec'],
    sectionLabel: '创意',
    label: '',
    config: {
      type: ComponentType.FACEBOOK_STORY_ASSET_SPEC
    },
    condition: {
      'adcreative.ads_set': 'create',
      'adcreative.ad_format': 'SINGLE_IMAGE_OR_VIDEO'
    }
  },
  {
    name: ['object_story_spec', 'asset_feed_spec'],
    sectionLabel: '创意',
    label: '',
    config: {
      type: ComponentType.FACEBOOK_CAROUSEL
    },
    condition: {
      'adcreative.ads_set': 'create',
      'adcreative.ad_format': 'CAROUSEL'
    }
  },
  {
    name: 'portrait_customizations',
    label: '',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: {
        carousel_delivery_mode: 'optimal_num_cards'
      }
    },
    condition: {
      'adcreative.ad_format': 'CAROUSEL'
    }
  },
  {
    name: 'meta_data',
    label: '',
    config: {
      type: ComponentType.COMMON_STRUCT,
      elements: [
        {
          name: 'call_to_action',
          label: '',
          config: {
            type: ComponentType.COMMON_STRUCT,
            elements: [callToActionElement]
          }
        },
        {
          name: 'ad_format',
          label: '',
          config: {
            type: ComponentType.COMMON_HIDDEN,
            dataSelector: (data) => {
              return data.adcreative.ad_format
            }
          }
        }
      ]
    },
    condition: {
      'adcreative.ads_set': 'create'
    }
  },
  {
    name: 'url_tags',
    label: '网址参数',
    sectionLabel: '创意',
    config: {
      type: ComponentType.COMMON_INPUT,
      attr: {
        placeholder: '请输入网址参数'
      }
    }
  }
]

for (const item of configs) {
  Reflect.set(item, 'condition', {
    ...Reflect.get(item, 'condition'),
    'campaign.objective': 'OUTCOME_SALES'
  })
}
