import type { SiteSetType } from './type'

// TikTok placement列表
export const PLACEMENTS: string[] = ['PLACEMENT_TIKTOK', 'PLACEMENT_PANGLE', 'PLACEMENT_GLOBAL_APP_BUNDLE']

export const LANGUAGE_LIST = [
  { value: 'ar', label: '阿拉伯语' },
  { value: 'as', label: '阿萨姆语' },
  { value: 'bgc', label: '哈里亚纳语' },
  { value: 'bh', label: '比哈尔语' },
  { value: 'bn', label: '孟加拉语' },
  { value: 'cs', label: '捷克语' },
  { value: 'de', label: '德语' },
  { value: 'el', label: '希腊语' },
  { value: 'en', label: '英语' },
  { value: 'es', label: '西班牙语' },
  { value: 'fi', label: '芬兰语' },
  { value: 'fr', label: '法语' },
  { value: 'gu', label: '古吉拉特语' },
  { value: 'he', label: '希伯来语' },
  { value: 'hi', label: '印地语' },
  { value: 'hu', label: '匈牙利语' },
  { value: 'id', label: '印尼语' },
  { value: 'it', label: '意大利语' },
  { value: 'ja', label: '日语' },
  { value: 'km', label: '高棉语' },
  { value: 'kn', label: '卡纳达语' },
  { value: 'ko', label: '韩语' },
  { value: 'ml', label: '马拉雅拉姆语' },
  { value: 'mr', label: '马拉地语' },
  { value: 'ms', label: '马来语' },
  { value: 'nl', label: '荷兰语' },
  { value: 'or', label: '奥里亚语' },
  { value: 'pa', label: '旁遮普语' },
  { value: 'pl', label: '波兰语' },
  { value: 'pt', label: '葡萄牙语' },
  { value: 'raj', label: '拉贾斯坦语' },
  { value: 'ro', label: '罗马尼亚语' },
  { value: 'ru', label: '俄语' },
  { value: 'sv', label: '瑞典语' },
  { value: 'ta', label: '泰米尔语' },
  { value: 'te', label: '泰卢固语' },
  { value: 'th', label: '泰语' },
  { value: 'tr', label: '土耳其语' },
  { value: 'uk', label: '乌克兰语' },
  { value: 'vi', label: '越南语' },
  { value: 'zh', label: '简体中文' },
  { value: 'zh-Hant', label: '繁体中文' }
]

/** 世界国家列表，数据源来自ISO 3166-1 */
export const COUNTRY_LIST = [
  { value: 'AF', label: '阿富汗' },
  { value: 'AX', label: '奥兰' },
  { value: 'AL', label: '阿尔巴尼亚' },
  { value: 'DZ', label: '阿尔及利亚' },
  { value: 'AS', label: '美属萨摩亚' },
  { value: 'AD', label: '安道尔' },
  { value: 'AO', label: '安哥拉' },
  { value: 'AI', label: '安圭拉' },
  { value: 'AQ', label: '南极洲' },
  { value: 'AG', label: '安提瓜和巴布达' },
  { value: 'AR', label: '阿根廷' },
  { value: 'AM', label: '亚美尼亚' },
  { value: 'AW', label: '阿鲁巴' },
  { value: 'AU', label: '澳大利亚' },
  { value: 'AT', label: '奥地利' },
  { value: 'AZ', label: '阿塞拜疆' },
  { value: 'BS', label: '巴哈马' },
  { value: 'BH', label: '巴林' },
  { value: 'BD', label: '孟加拉国' },
  { value: 'BB', label: '巴巴多斯' },
  { value: 'BY', label: '白俄罗斯' },
  { value: 'BE', label: '比利时' },
  { value: 'BZ', label: '伯利兹' },
  { value: 'BJ', label: '贝宁' },
  { value: 'BM', label: '百慕大' },
  { value: 'BT', label: '不丹' },
  { value: 'BO', label: '玻利维亚' },
  { value: 'BQ', label: '荷兰加勒比区' },
  { value: 'BA', label: '波黑' },
  { value: 'BW', label: '博茨瓦纳' },
  { value: 'BV', label: '布韦岛' },
  { value: 'BR', label: '巴西' },
  { value: 'IO', label: '英属印度洋领地' },
  { value: 'BN', label: '文莱' },
  { value: 'BG', label: '保加利亚' },
  { value: 'BF', label: '布基纳法索' },
  { value: 'BI', label: '布隆迪' },
  { value: 'CV', label: '佛得角' },
  { value: 'KH', label: '柬埔寨' },
  { value: 'CM', label: '喀麦隆' },
  { value: 'CA', label: '加拿大' },
  { value: 'KY', label: '开曼群岛' },
  { value: 'CF', label: '中非' },
  { value: 'TD', label: '乍得' },
  { value: 'CL', label: '智利' },
  { value: 'CN', label: '中国大陆' },
  { value: 'CX', label: '圣诞岛' },
  { value: 'CC', label: '科科斯（基林）群岛' },
  { value: 'CO', label: '哥伦比亚' },
  { value: 'KM', label: '科摩罗' },
  { value: 'CG', label: '刚果共和国' },
  { value: 'CD', label: '刚果民主共和国' },
  { value: 'CK', label: '库克群岛' },
  { value: 'CR', label: '哥斯达黎加' },
  { value: 'CI', label: '科特迪瓦' },
  { value: 'HR', label: '克罗地亚' },
  { value: 'CU', label: '古巴' },
  { value: 'CW', label: '库拉索' },
  { value: 'CY', label: '塞浦路斯' },
  { value: 'CZ', label: '捷克' },
  { value: 'DK', label: '丹麦' },
  { value: 'DJ', label: '吉布提' },
  { value: 'DM', label: '多米尼克' },
  { value: 'DO', label: '多米尼加' },
  { value: 'EC', label: '厄瓜多尔' },
  { value: 'EG', label: '埃及' },
  { value: 'SV', label: '萨尔瓦多' },
  { value: 'GQ', label: '赤道几内亚' },
  { value: 'ER', label: '厄立特里亚' },
  { value: 'EE', label: '爱沙尼亚' },
  { value: 'SZ', label: '斯威士兰' },
  { value: 'ET', label: '埃塞俄比亚' },
  { value: 'FK', label: '福克兰群岛' },
  { value: 'FO', label: '法罗群岛' },
  { value: 'FJ', label: '斐济' },
  { value: 'FI', label: '芬兰' },
  { value: 'FR', label: '法国' },
  { value: 'GF', label: '法属圭亚那' },
  { value: 'PF', label: '法属波利尼西亚' },
  { value: 'TF', label: '法属南部和南极领地' },
  { value: 'GA', label: '加蓬' },
  { value: 'GM', label: '冈比亚' },
  { value: 'GE', label: '格鲁吉亚' },
  { value: 'DE', label: '德国' },
  { value: 'GH', label: '加纳' },
  { value: 'GI', label: '直布罗陀' },
  { value: 'GR', label: '希腊' },
  { value: 'GL', label: '格陵兰' },
  { value: 'GD', label: '格林纳达' },
  { value: 'GP', label: '瓜德罗普' },
  { value: 'GU', label: '关岛（美国）' },
  { value: 'GT', label: '危地马拉' },
  { value: 'GG', label: '根西' },
  { value: 'GN', label: '几内亚' },
  { value: 'GW', label: '几内亚比绍' },
  { value: 'GY', label: '圭亚那' },
  { value: 'HT', label: '海地' },
  { value: 'HM', label: '赫德岛和麦克唐纳群岛' },
  { value: 'VA', label: '梵蒂冈' },
  { value: 'HN', label: '洪都拉斯' },
  { value: 'HK', label: '香港特别行政区（中国）' },
  { value: 'HU', label: '匈牙利' },
  { value: 'IS', label: '冰岛' },
  { value: 'IN', label: '印度' },
  { value: 'ID', label: '印度尼西亚' },
  { value: 'IR', label: '伊朗' },
  { value: 'IQ', label: '伊拉克' },
  { value: 'IE', label: '爱尔兰' },
  { value: 'IM', label: '马恩岛' },
  { value: 'IL', label: '以色列' },
  { value: 'IT', label: '意大利' },
  { value: 'JM', label: '牙买加' },
  { value: 'JP', label: '日本' },
  { value: 'JE', label: '泽西' },
  { value: 'JO', label: '约旦' },
  { value: 'KZ', label: '哈萨克斯坦' },
  { value: 'KE', label: '肯尼亚' },
  { value: 'KI', label: '基里巴斯' },
  { value: 'KP', label: '朝鲜' },
  { value: 'KR', label: '韩国' },
  { value: 'KW', label: '科威特' },
  { value: 'KG', label: '吉尔吉斯斯坦' },
  { value: 'LA', label: '老挝' },
  { value: 'LV', label: '拉脱维亚' },
  { value: 'LB', label: '黎巴嫩' },
  { value: 'LS', label: '莱索托' },
  { value: 'LR', label: '利比里亚' },
  { value: 'LY', label: '利比亚' },
  { value: 'LI', label: '列支敦士登' },
  { value: 'LT', label: '立陶宛' },
  { value: 'LU', label: '卢森堡' },
  { value: 'MO', label: '澳门特别行政区（中国）' },
  { value: 'MG', label: '马达加斯加' },
  { value: 'MW', label: '马拉维' },
  { value: 'MY', label: '马来西亚' },
  { value: 'MV', label: '马尔代夫' },
  { value: 'ML', label: '马里' },
  { value: 'MT', label: '马耳他' },
  { value: 'MH', label: '马绍尔群岛' },
  { value: 'MQ', label: '马提尼克' },
  { value: 'MR', label: '毛里塔尼亚' },
  { value: 'MU', label: '毛里求斯' },
  { value: 'YT', label: '马约特' },
  { value: 'MX', label: '墨西哥' },
  { value: 'FM', label: '密克罗尼西亚联邦' },
  { value: 'MD', label: '摩尔多瓦' },
  { value: 'MC', label: '摩纳哥' },
  { value: 'MN', label: '蒙古' },
  { value: 'ME', label: '黑山' },
  { value: 'MS', label: '蒙特塞拉特' },
  { value: 'MA', label: '摩洛哥' },
  { value: 'MZ', label: '莫桑比克' },
  { value: 'MM', label: '缅甸' },
  { value: 'NA', label: '纳米比亚' },
  { value: 'NR', label: '瑙鲁' },
  { value: 'NP', label: '尼泊尔' },
  { value: 'NL', label: '荷兰' },
  { value: 'NC', label: '新喀里多尼亚' },
  { value: 'NZ', label: '新西兰' },
  { value: 'NI', label: '尼加拉瓜' },
  { value: 'NE', label: '尼日尔' },
  { value: 'NG', label: '尼日利亚' },
  { value: 'NU', label: '纽埃' },
  { value: 'NF', label: '诺福克岛' },
  { value: 'MK', label: '北马其顿' },
  { value: 'MP', label: '北马里亚纳群岛' },
  { value: 'NO', label: '挪威' },
  { value: 'OM', label: '阿曼' },
  { value: 'PK', label: '巴基斯坦' },
  { value: 'PW', label: '帕劳' },
  { value: 'PS', label: '巴勒斯坦' },
  { value: 'PA', label: '巴拿马' },
  { value: 'PG', label: '巴布亚新几内亚' },
  { value: 'PY', label: '巴拉圭' },
  { value: 'PE', label: '秘鲁' },
  { value: 'PH', label: '菲律宾' },
  { value: 'PN', label: '皮特凯恩群岛' },
  { value: 'PL', label: '波兰' },
  { value: 'PT', label: '葡萄牙' },
  { value: 'PR', label: '波多黎各' },
  { value: 'QA', label: '卡塔尔' },
  { value: 'RE', label: '留尼汪' },
  { value: 'RO', label: '罗马尼亚' },
  { value: 'RU', label: '俄罗斯' },
  { value: 'RW', label: '卢旺达' },
  { value: 'BL', label: '圣巴泰勒米' },
  { value: 'SH', label: '圣赫勒拿、阿森松和特里斯坦-达库尼亚' },
  { value: 'KN', label: '圣基茨和尼维斯' },
  { value: 'LC', label: '圣卢西亚' },
  { value: 'MF', label: '法属圣马丁' },
  { value: 'PM', label: '圣皮埃尔和密克隆' },
  { value: 'VC', label: '圣文森特和格林纳丁斯' },
  { value: 'WS', label: '萨摩亚' },
  { value: 'SM', label: '圣马力诺' },
  { value: 'ST', label: '圣多美和普林西比' },
  { value: 'SA', label: '沙特阿拉伯' },
  { value: 'SN', label: '塞内加尔' },
  { value: 'RS', label: '塞尔维亚' },
  { value: 'SC', label: '塞舌尔' },
  { value: 'SL', label: '塞拉利昂' },
  { value: 'SG', label: '新加坡' },
  { value: 'SX', label: '荷属圣马丁' },
  { value: 'SK', label: '斯洛伐克' },
  { value: 'SI', label: '斯洛文尼亚' },
  { value: 'SB', label: '所罗门群岛' },
  { value: 'SO', label: '索马里' },
  { value: 'ZA', label: '南非' },
  { value: 'GS', label: '南乔治亚和南桑威奇群岛' },
  { value: 'SS', label: '南苏丹' },
  { value: 'ES', label: '西班牙' },
  { value: 'LK', label: '斯里兰卡' },
  { value: 'SD', label: '苏丹' },
  { value: 'SR', label: '苏里南' },
  { value: 'SJ', label: '斯瓦尔巴和扬马延' },
  { value: 'SE', label: '瑞典' },
  { value: 'CH', label: '瑞士' },
  { value: 'SY', label: '叙利亚' },
  { value: 'TW', label: '台湾省（中国）' },
  { value: 'TJ', label: '塔吉克斯坦' },
  { value: 'TZ', label: '坦桑尼亚' },
  { value: 'TH', label: '泰国' },
  { value: 'TL', label: '东帝汶' },
  { value: 'TG', label: '多哥' },
  { value: 'TK', label: '托克劳' },
  { value: 'TO', label: '汤加' },
  { value: 'TT', label: '特立尼达和多巴哥' },
  { value: 'TN', label: '突尼斯' },
  { value: 'TR', label: '土耳其' },
  { value: 'TM', label: '土库曼斯坦' },
  { value: 'TC', label: '特克斯和凯科斯群岛' },
  { value: 'TV', label: '图瓦卢' },
  { value: 'UG', label: '乌干达' },
  { value: 'UA', label: '乌克兰' },
  { value: 'AE', label: '阿联酋' },
  { value: 'GB', label: '英国' },
  { value: 'US', label: '美国' },
  { value: 'UM', label: '美国本土外小岛屿' },
  { value: 'UY', label: '乌拉圭' },
  { value: 'UZ', label: '乌兹别克斯坦' },
  { value: 'VU', label: '瓦努阿图' },
  { value: 'VE', label: '委内瑞拉' },
  { value: 'VN', label: '越南' },
  { value: 'VG', label: '英属维尔京群岛' },
  { value: 'VI', label: '美属维尔京群岛' },
  { value: 'WF', label: '瓦利斯和富图纳' },
  { value: 'EH', label: '西撒哈拉' },
  { value: 'YE', label: '也门' },
  { value: 'ZM', label: '赞比亚' },
  { value: 'ZW', label: '津巴布韦' }
]

// 优化目标类型列表
/** 地区类型 Map */
export enum LocationTypesMap {
  RECENTLY_IN = 'RECENTLY_IN',
  LIVE_AND_RECENTLY = 'LIVE_AND_RECENTLY',
  LIVE_IN = 'LIVE_IN',
  TRAVEL_IN = 'TRAVEL_IN',
  VISITED_IN = 'VISITED_IN'
}

/** 地区类型 Label Map */
export const LocationTypesLabelMap = {
  [LocationTypesMap.RECENTLY_IN]: '近期到访',
  [LocationTypesMap.LIVE_AND_RECENTLY]: '常住且近期',
  [LocationTypesMap.LIVE_IN]: '常住地',
  [LocationTypesMap.TRAVEL_IN]: '旅行到访',
  [LocationTypesMap.VISITED_IN]: '曾经到访'
}

/** 性别 Map */
export enum GenderMap {
  'FEMALE' = '女',
  'MALE' = '男'
}

/** 排除已转化用户 Map */
export enum ExcludeDimensionTypeMap {
  EXCLUDED_DIMENSION_CAMPAIGN = 'EXCLUDED_DIMENSION_CAMPAIGN',
  EXCLUDED_DIMENSION_UID = 'EXCLUDED_DIMENSION_UID',
  EXCLUDED_DIMENSION_BUSINESS_MANAGER = 'EXCLUDED_DIMENSION_BUSINESS_MANAGER',
  EXCLUDED_DIMENSION_COMPANY_ACCOUNT = 'EXCLUDED_DIMENSION_COMPANY_ACCOUNT',
  EXCLUDED_DIMENSION_APP = 'EXCLUDED_DIMENSION_APP',
  EXCLUDED_DIMENSION_PRODUCT = 'EXCLUDED_DIMENSION_PRODUCT'
}

/** 排除已转化用户 Label Map */
export const ExcludedDimensionLabelMap = {
  [ExcludeDimensionTypeMap.EXCLUDED_DIMENSION_CAMPAIGN]: '同计划广告',
  [ExcludeDimensionTypeMap.EXCLUDED_DIMENSION_UID]: '同账户广告',
  [ExcludeDimensionTypeMap.EXCLUDED_DIMENSION_BUSINESS_MANAGER]: '同商务管家广告',
  [ExcludeDimensionTypeMap.EXCLUDED_DIMENSION_COMPANY_ACCOUNT]: '同主体广告',
  [ExcludeDimensionTypeMap.EXCLUDED_DIMENSION_APP]: '同应用广告',
  [ExcludeDimensionTypeMap.EXCLUDED_DIMENSION_PRODUCT]: '同商品广告'
}

/** 优化目标类型列表 */
export const OPTIMIZATIONGOAL_LIST = [
  { label: '未知', value: 'OPTIMIZATIONGOAL_NONE' },
  { label: '品牌转化', value: 'OPTIMIZATIONGOAL_BRAND_CONVERSION' },
  { label: '关注', value: 'OPTIMIZATIONGOAL_FOLLOW' },
  { label: '点击', value: 'OPTIMIZATIONGOAL_CLICK' },
  { label: '曝光', value: 'OPTIMIZATIONGOAL_IMPRESSION' },
  { label: '下载', value: 'OPTIMIZATIONGOAL_APP_DOWNLOAD' },
  { label: '激活', value: 'OPTIMIZATIONGOAL_APP_ACTIVATE' },
  { label: '注册', value: 'OPTIMIZATIONGOAL_APP_REGISTER' },
  { label: '次日留存', value: 'OPTIMIZATIONGOAL_ONE_DAY_RETENTION' },
  { label: '付费次数', value: 'OPTIMIZATIONGOAL_APP_PURCHASE' },
  { label: '下单', value: 'OPTIMIZATIONGOAL_ECOMMERCE_ORDER' },
  { label: 'H5 购买', value: 'OPTIMIZATIONGOAL_ECOMMERCE_CHECKOUT' },
  { label: '销售线索', value: 'OPTIMIZATIONGOAL_LEADS' },
  { label: '加入购物车', value: 'OPTIMIZATIONGOAL_ECOMMERCE_CART' },
  { label: '公众号内注册', value: 'OPTIMIZATIONGOAL_PROMOTION_CLICK_KEY_PAGE' },
  { label: '商品详情页浏览', value: 'OPTIMIZATIONGOAL_VIEW_COMMODITY_PAGE' },
  { label: '在线咨询', value: 'OPTIMIZATIONGOAL_ONLINE_CONSULTATION' },
  { label: '电话拨打', value: 'OPTIMIZATIONGOAL_TELEPHONE_CONSULTATION' },
  { label: '表单预约', value: 'OPTIMIZATIONGOAL_PAGE_RESERVATION' },
  { label: '发货', value: 'OPTIMIZATIONGOAL_DELIVERY' },
  { label: '公众号内发消息', value: 'OPTIMIZATIONGOAL_MESSAGE_AFTER_FOLLOW' },
  { label: '公众号内点击菜单栏', value: 'OPTIMIZATIONGOAL_CLICK_MENU_AFTER_FOLLOW' },
  { label: '有效在线咨询 (待废弃)', value: 'OPTIMIZATIONGOAL_PAGE_EFFECTIVE_ONLINE_CONSULT' },
  { label: '完件', value: 'OPTIMIZATIONGOAL_APPLY' },
  { label: '有效在线咨询', value: 'OPTIMIZATIONGOAL_CONFIRM_EFFECTIVE_LEADS_CONSULT' },
  { label: '有效电话拨打', value: 'OPTIMIZATIONGOAL_CONFIRM_EFFECTIVE_LEADS_PHONE' },
  { label: '综合线索收集', value: 'OPTIMIZATIONGOAL_LEADS_COLLECT' },
  { label: '首次付费', value: 'OPTIMIZATIONGOAL_FIRST_PURCHASE' },
  { label: '预授信', value: 'OPTIMIZATIONGOAL_PRE_CREDIT' },
  { label: '授信', value: 'OPTIMIZATIONGOAL_CREDIT' },
  { label: '提现', value: 'OPTIMIZATIONGOAL_WITHDRAW_DEPOSITS' },
  { label: '关键页面访问次数', value: 'OPTIMIZATIONGOAL_PROMOTION_VIEW_KEY_PAGE' },
  { label: '小游戏创角', value: 'OPTIMIZATIONGOAL_MOBILE_APP_CREATE_ROLE' },
  { label: '跳转按钮点击', value: 'OPTIMIZATIONGOAL_CANVAS_CLICK' },
  { label: '领券', value: 'OPTIMIZATIONGOAL_PROMOTION_CLAIM_OFFER' },
  { label: '商品收藏', value: 'OPTIMIZATIONGOAL_ECOMMERCE_ADD_TO_WISHLIST' },
  { label: '有效表单提交', value: 'OPTIMIZATIONGOAL_CONFIRM_EFFECTIVE_LEADS_RESERVATION' },
  { label: '签收', value: 'OPTIMIZATIONGOAL_PAGE_RECEIPT' },
  { label: '加企业微信客服', value: 'OPTIMIZATIONGOAL_PAGE_SCAN_CODE' },
  { label: '选课', value: 'OPTIMIZATIONGOAL_SELECT_COURSE' },
  { label: '电话潜在客户', value: 'OPTIMIZATIONGOAL_CONFIRM_POTENTIAL_CUSTOMER_PHONE' },
  { label: '广告变现', value: 'OPTIMIZATIONGOAL_MOBILE_APP_AD_INCOME' },
  { label: '小游戏授权', value: 'OPTIMIZATIONGOAL_MOBILE_APP_ACCREDIT' },
  { label: '首次会员购买', value: 'OPTIMIZATIONGOAL_PURCHASE_MEMBER_CARD' },
  { label: '有效综合线索', value: 'OPTIMIZATIONGOAL_PAGE_CONFIRM_EFFECTIVE_LEADS' },
  { label: '微信流量预约行为', value: 'OPTIMIZATIONGOAL_RESERVATION' },
  { label: '首次下单', value: 'OPTIMIZATIONGOAL_FIRST_ECOMMERCE_ORDER' },
  { label: '点赞', value: 'OPTIMIZATIONGOAL_LIKE' },
  { label: '快应用加桌面', value: 'OPTIMIZATIONGOAL_ADD_DESKTOP' },
  { label: '外链点击', value: 'OPTIMIZATIONGOAL_EXTERNAL_LINK_CLICK' },
  { label: '购券', value: 'OPTIMIZATIONGOAL_BUY_COUPONS' },
  { label: '咨询留资', value: 'OPTIMIZATIONGOAL_LEAVE_INFORMATION' },
  { label: '关键行为', value: 'OPTIMIZATIONGOAL_CORE_ACTION' },
  { label: '次留率', value: 'OPTIMIZATIONGOAL_ONE_DAY_RETENTION_RATIO' },
  { label: '阅读文章', value: 'OPTIMIZATIONGOAL_PROMOTION_READ_ARTICLE' },
  { label: '24 小时下单', value: 'OPTIMIZATIONGOAL_FIRST_TWENTY_FOUR_HOUR_ECOMMERCE_ORDER' },
  { label: '扫码加粉', value: 'OPTIMIZATIONGOAL_ECOMMERCE_SCANCODE_WX' },
  { label: '7 日留存', value: 'OPTIMIZATIONGOAL_MOBILE_APP_SEVEN_DAYS_RETENTION' },
  { label: '首次到课', value: 'OPTIMIZATIONGOAL_CLASS_PARTICIPATED' },
  { label: '保险支付', value: 'OPTIMIZATIONGOAL_INSURANCE_PURCHASE' },
  { label: '意向表单', value: 'OPTIMIZATIONGOAL_RESERVATION_CHECK' },
  { label: '券商开户', value: 'OPTIMIZATIONGOAL_OPEN_ACCOUNT' },
  { label: '7 日下单', value: 'OPTIMIZATIONGOAL_SEVEN_DAY_ECOMMERCE_ORDER' },
  { label: '添加个人微信客服', value: 'OPTIMIZATIONGOAL_ADD_WECHAT' },
  { label: '加企微后咨询', value: 'OPTIMIZATIONGOAL_WECOM_CONSULT' },
  { label: '加群', value: 'OPTIMIZATIONGOAL_ADD_GROUP' },
  { label: '快速下单', value: 'OPTIMIZATIONGOAL_QUICK_ORDER' },
  { label: '预付定金', value: 'OPTIMIZATIONGOAL_PRE_PAY' },
  { label: '主动一句话咨询', value: 'OPTIMIZATIONGOAL_PAGE_ONLINE_CONSULT_ACTIVE_ONE_MSG' },
  { label: '通话 30S', value: 'OPTIMIZATIONGOAL_CALL_DURATION_THIRTY_SECONDS' },
  { label: '领课', value: 'OPTIMIZATIONGOAL_CLAIM_COURSE' },
  { label: '退群', value: 'OPTIMIZATIONGOAL_QUIT_GROUP' },
  { label: '拉新访问关键页面', value: 'OPTIMIZATIONGOAL_VIEW_ACQUISITION_CONTENT' },
  { label: '沉默唤起', value: 'OPTIMIZATIONGOAL_BACK_FLOW' },
  { label: '三句话咨询', value: 'OPTIMIZATIONGOAL_PAGE_ONLINE_CONSULT_THREE_MSG' },
  { label: '保险续费', value: 'OPTIMIZATIONGOAL_RENEWAL' },
  { label: '低价课转化', value: 'OPTIMIZATIONGOAL_LOW_PRICE_COURSE' },
  { label: '开口后下单意向', value: 'OPTIMIZATIONGOAL_CONSULT_INTENTION' },
  { label: '七日内每次留存成本', value: 'OPTIMIZATIONGOAL_EVERY_DAY_RETENTION' },
  { label: '关键页面访问人数', value: 'OPTIMIZATIONGOAL_PROMOTION_VIEW_KEY_PAGE_UV' },
  { label: '直播观看一分钟', value: 'OPTIMIZATIONGOAL_LIVE_STREAM_DURATION_1MIN' },
  { label: '直播互动', value: 'OPTIMIZATIONGOAL_LIVE_STREAM_INTERACTION' },
  { label: '取消订单率', value: 'OPTIMIZATIONGOAL_ECOMMERCE_CANCEL_ORDER' },
  { label: '点击留资组件', value: 'OPTIMIZATIONGOAL_CLICK_LEADS_COMPONENT' },
  { label: '正价课', value: 'OPTIMIZATIONGOAL_REGULAR_PRICE_COURSE' },
  { label: '到店', value: 'OPTIMIZATIONGOAL_VISIT_STROE' },
  { label: '每日首次启动', value: 'OPTIMIZATIONGOAL_EFFECTIVE_ENTRY' },
  { label: '完件授信率', value: 'OPTIMIZATIONGOAL_CREDIT_RATIO' },
  { label: '全域通直播间成交', value: 'OPTIMIZATIONGOAL_QYT_LIVE_STREAM_DEAL' },
  { label: '全域通直播间商品点击', value: 'OPTIMIZATIONGOAL_QYT_LIVE_STREAM_PRODUCT_CLICK' },
  { label: '全域通直播间观众', value: 'OPTIMIZATIONGOAL_QYT_LIVE_STREAM_AUDIENCE' },
  { label: '全域通直播评论次数', value: 'OPTIMIZATIONGOAL_QYT_LIVE_STREAM_COMMENT' },
  { label: '全域通直播间涨粉', value: 'OPTIMIZATIONGOAL_QYT_LIVE_STREAM_FANS' },
  { label: '首日首次付费', value: 'OPTIMIZATIONGOAL_24H_FIRSTPAY' },
  { label: '店铺停留', value: 'OPTIMIZATIONGOAL_STORE_STAY' },
  { label: '低价险支付', value: 'OPTIMIZATIONGOAL_LOW_PRICE_INSURANCE_PAYMENT' },
  { label: '贷款额度开通', value: 'OPTIMIZATIONGOAL_UNDERWRITING' },
  { label: '借款', value: 'OPTIMIZATIONGOAL_FIRST_WITHDRAW' },
  { label: '先导课完课', value: 'OPTIMIZATIONGOAL_BRIDGING_COURSE_COMPLETED' }
]
// 广告深度优化转化目标类型列表
export const GOAL_LIST = [
  { label: '未知', value: 'GOAL_NONE' },
  { label: '7 天付费 ROI', value: 'GOAL_7DAY_PURCHASE_ROAS' },
  { label: '15 天付费 ROI', value: 'GOAL_15DAY_PURCHASE_ROAS' },
  { label: '30 天付费 ROI', value: 'GOAL_30DAY_PURCHASE_ROAS' },
  { label: '60 天付费 ROI', value: 'GOAL_60DAY_PURCHASE_ROAS' },
  { label: '30 天变现 ROI', value: 'GOAL_30DAY_MONETIZATION_ROAS' },
  { label: '下单 ROI', value: 'GOAL_30DAY_ORDER_ROAS' },
  { label: '首日付费 ROI', value: 'GOAL_1DAY_PURCHASE_ROAS' },
  { label: '首日变现 ROI', value: 'GOAL_1DAY_MONETIZATION_ROAS' },
  { label: '3 日付费 ROI', value: 'GOAL_3DAY_PURCHASE_ROAS' },
  { label: '3 日变现 ROI', value: 'GOAL_3DAY_MONETIZATION_ROAS' },
  { label: '7 日变现 ROI', value: 'GOAL_7DAY_MONETIZATION_ROAS' },
  { label: '15 日变现 ROI', value: 'GOAL_15DAY_MONETIZATION_ROAS' },
  { label: '七日内留存天数', value: 'GOAL_7DAY_RETENTION_TIMES' },
  { label: '七日长效付费', value: 'GOAL_7DAY_LONGTERM_PURCHASE_ROAS' },
  { label: '十四日长效付费', value: 'GOAL_14DAY_LONGTERM_PURCHASE_ROAS' },
  { label: '三十日长效付费', value: 'GOAL_30DAY_LONGTERM_PURCHASE_ROAS' },
  { label: '全域通七日直播间成交', value: 'GOAL_QYT_7DAY_LIVE_STREAM_DEAL_ROAS' },
  { label: '七日提现 ROI', value: 'GOAL_WITHDRAW_DEPOSITS_ROAS' }
]

export const SITE_SET: SiteSetType[] = [
  'SITE_SET_MOBILE_UNION',
  'SITE_SET_WECHAT',
  'SITE_SET_TENCENT_NEWS',
  'SITE_SET_TENCENT_VIDEO',
  'SITE_SET_KANDIAN',
  'SITE_SET_QQ_MUSIC_GAME',
  'SITE_SET_MOMENTS',
  'SITE_SET_CHANNELS',
  'SITE_SET_WECHAT_PLUGIN'
]

export const supportedCreateTemplateIds = [
  721, // 竖版视频9:16
  720, // 横版视频16:9
  712, // 竖版大图9:16
  711, // 横版大图16:9
  618, // 常规视频4:3
  311, // 常规大图
  2106 // 常规图片
]
export const supportedVideoCreateTemplateIds = [
  721, // 竖版视频9:16
  720, // 横版视频16:9
  618 // 常规视频4:3
]
export const supportedImageCreateTemplateIds = [
  712, // 竖版大图9:16
  711, // 横版大图16:9
  311, // 常规大图
  2106 // 常规图片
]
