import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "25",
  height: "25",
  class: "icon",
  viewBox: "0 0 1024 1024"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#928DF4",
      d: "M861.021 884.736a69.725 69.725 0 0 1-69.841-69.47 69.725 69.725 0 0 1 69.841-69.445 69.725 69.725 0 0 1 69.842 69.446 69.725 69.725 0 0 1-69.842 69.469m-23.273-234.403a23.25 23.25 0 0 1 23.273-23.156c12.847 0 23.273 10.38 23.273 23.156v28.114a139 139 0 0 0-23.273-2.095c-7.936 0-15.686.815-23.273 2.095zm93.115 44.754v-44.754a69.655 69.655 0 0 0-69.842-69.469 69.68 69.68 0 0 0-69.841 69.47v44.753c-41.705 24.04-69.842 68.794-69.842 120.18 0 76.73 62.534 138.915 139.683 138.915s139.706-62.185 139.706-138.938c0-51.363-28.136-96.117-69.864-120.157M265.658 347.625c0-114.874 94.022-208.338 209.548-208.338 115.549 0 209.547 93.464 209.547 208.361s-93.998 208.384-209.547 208.384c-115.526 0-209.548-93.487-209.548-208.384zm445.79 277.527a460.6 460.6 0 0 0-90.113-40.983c82.316-50.409 136.518-141.778 133.12-245.714-4.7-143.662-123.88-262.935-268.288-268.427-159.302-6.051-290.35 120.552-290.35 277.62 0 100.12 53.41 187.578 133.283 236.497C179.41 633.46 63.465 756.108 24.39 909.498c-4.678 18.362 5.702 38.517 24.087 43.45 19.223 5.12 38.423-6.19 42.962-24.343C134.26 757.318 289.909 629.97 475.206 629.97c73.425 0 142.033 20.178 200.937 55.04 16.896 10.008 38.679 4.283 48.244-12.8a34.91 34.91 0 0 0-12.94-47.08z"
    }, null, -1)
  ])))
}
export default { render: render }