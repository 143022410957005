import webapi, { type Response } from '../service'
import type * as components from './type'

/**
 * 获取广告像素列表
 * @param params
 */
export function facebookPixelList(
  params: components.FacebookPixelListRequest
): Response<components.FacebookPixelListResponse> {
  return webapi.get(`/facebook/pixel/list`, { params })
}

/**
 * 图片上传
 * @param req
 */
export function facebookImagesAdd(
  req: components.FacebookFilesAddRequest
): Response<components.FacebookImagesAddResponse> {
  return webapi.post(`/facebook/images/add`, req, {
    headers: { 'content-type': 'multipart/form-data' }
  })
}

/**
 * 视频上传
 * @param req
 */
export function facebookVideosAdd(
  req: components.FacebookFilesAddRequest
): Response<components.FacebookVideosAddResponse> {
  return webapi.post(`/facebook/videos/add`, req, {
    headers: { 'content-type': 'multipart/form-data' }
  })
}

/**
 * 视频封面获取
 * @param params
 */
export function facebookVideosThumbnails(
  params: components.FacebookVideosThumbnailsRequest
): Response<components.FacebookVideosThumbnailsResponse> {
  return webapi.get(`/facebook/videos/thumbnails/list`, { params })
}

/**
 * 获取公共主页列表
 * @param params
 */
export function facebookPagesList(
  params: components.FacebookPagesListRequest
): Response<components.FacebookPagesListResponse> {
  return webapi.get(`/facebook/pages/list`, { params })
}

/**
 * 获取图片详情
 * @param params
 */
export function facebookImages(params: components.FacebookImagesRequest): Response<components.FacebookImagesResponse> {
  return webapi.get(`/facebook/images/get`, { params })
}

/**
 * 获取视频详情
 * @param params
 */
export function facebookVideos(params: components.FacebookVideosRequest): Response<components.FacebookVideosResponse> {
  return webapi.get(`/facebook/videos/get`, { params })
}

/**
 * 获取帖子附件
 * @param params
 */
export function facebookPostAttachments(
  params: components.FacebookPostAttachmentsRequest
): Response<components.FacebookPostAttachmentsResponse> {
  return webapi.get(`/facebook/post/attachments`, { params })
}

/**
 * 获取公共主页的帖子列表
 * @param params
 */
export function facebookPageAdsPosts(
  params: components.FacebookPageAdsPostsRequest
): Response<components.FacebookPageAdsPostsResponse> {
  return webapi.get(`/facebook/page/ads_posts`, { params })
}

/**
 * 获取公共主页的PBIA
 * @param params
 */
export function facebookPageBackedInstagramAccounts(
  params: components.FacebookPageBackedInstagramAccountsRequestParams
): Response<components.FacebookPageBackedInstagramAccountsResponse> {
  return webapi.get(`/facebook/page/page_backed_instagram_accounts`, { params })
}

/**
 * 获取广告账户关联的BM列表
 * @param params
 */
export function facebookBusinessList(
  params: components.FacebookBusinessListRequestParams
): Response<components.FacebookBusinessListResponse> {
  return webapi.get(`/facebook/business/list`, { params })
}

/**
 * 获取BM下的公共主页列表
 * @param params
 */
export function facebookBusinessPagesList(
  params: components.FacebookBusinessPagesListRequestParams
): Response<components.FacebookBusinessPagesListResponse> {
  return webapi.get(`/facebook/business/pages/list`, { params })
}
