import webapi, { type Response } from '../service'
import type * as components from './type'

/**
 * 腾讯广告3.0[接口清单](https://developers.e.qq.com/v3.0/docs/apilist)
 */

/**
 * 透传接口 - [获取商品库](https://developers.e.qq.com/v3.0/docs/api/product_catalogs/get)
 * @param params
 */
export function productCatalogsGet(
  params: components.ProductCatalogsGetRequestParams
): Response<components.ProductCatalogsGetResponse> {
  return webapi.get(`/tadsv3/product_catalogs/get`, { params })
}

/**
 * 透传接口 - [获取商品](https://developers.e.qq.com/v3.0/docs/api/product_items/get)
 * @param params
 */
export function productItemsGet(
  params: components.ProductItemsGetRequestParams
): Response<components.ProductItemsGetResponse> {
  return webapi.get(`/tadsv3/product_items/get`, { params })
}

/**
 * 透传接口 - [获取可投放推广内容资产列表](https://developers.e.qq.com/v3.0/docs/api/marketing_target_assets/get)
 * @param params
 */
export function marketingTargetAssetsGet(
  params: components.MarketingTargetAssetsGetRequestParams
): Response<components.MarketingTargetAssetsGetResponse> {
  return webapi.get(`/tadsv3/marketing_target_assets/get`, { params })
}

/**
 * 透传接口 - [短剧售卖策略-获取售卖策略列表](https://developers.e.qq.com/v3.0/docs/api/landing_page_sell_strategy/get)
 * @param params
 */
export function landingPageSellStrategyGet(
  params: components.LandingPageSellStrategyGetRequestParams
): Response<components.LandingPageSellStrategyGetResponse> {
  return webapi.get(`/tadsv3/landing_page_sell_strategy/get`, { params })
}

/**
 * 透传接口 - [查询优化目标权限](https://developers.e.qq.com/v3.0/docs/api/optimization_goal_permissions/get)
 * @param params
 */
export function optimizationGoalPermissionsGet(
  params: components.OptimizationGoalPermissionsGetRequestParams
): Response<components.OptimizationGoalPermissionsGetResponse> {
  return webapi.get(`/tadsv3/optimization_goal_permissions/get`, { params })
}

/**
 * 透传接口 - [获取转化归因](https://developers.e.qq.com/v3.0/docs/api/conversions/get)
 * @param params
 */
export function conversionsGet(
  params: components.ConversionsGetRequestParams
): Response<components.ConversionsGetResponse> {
  return webapi.get(`/tadsv3/conversions/get`, { params })
}

/**
 * 透传接口 - [获取用户行为数据源](https://developers.e.qq.com/v3.0/docs/api/user_action_sets/get)
 * @param params
 */
export function userActionSetsGet(
  params: components.UserActionSetsGetRequestParams
): Response<components.UserActionSetsGetResponse> {
  return webapi.get(`/tadsv3/user_action_sets/get`, { params })
}

/**
 * 透传接口 - [获取定向模版](https://developers.e.qq.com/v3.0/docs/api/targetings/get)
 * @param params
 */
export function targetingsGet(
  params: components.TargetingsGetRequestParams
): Response<components.TargetingsGetResponse> {
  return webapi.get(`/tadsv3/targetings/get`, { params })
}

/**
 * 透传接口 - [创建定向](https://developers.e.qq.com/v3.0/docs/api/targetings/add)
 * @param req
 */
export function targetingsAdd(req: components.TargetingsAddRequest): Response<components.TargetingsAddResponse> {
  return webapi.post(`/tadsv3/targetings/add`, req)
}

/**
 * 透传接口 - [获取定向标签](https://developers.e.qq.com/v3.0/docs/api/targeting_tags/get)
 * @param params
 */
export function targetingTagsGet(
  params: components.TargetingTagsGetRequestParams
): Response<components.TargetingTagsGetResponse> {
  return webapi.get(`/tadsv3/targeting_tags/get`, { params })
}

/**
 * 透传接口 - [获取客户人群](https://developers.e.qq.com/v3.0/docs/api/custom_audiences/get)
 * @param params
 */
export function customAudiencesGet(
  params: components.CustomAudiencesGetRequestParams
): Response<components.CustomAudiencesGetResponse> {
  return webapi.get(`/tadsv3/custom_audiences/get`, { params })
}

/**
 * 透传接口 - [获取创意规格列表](https://developers.e.qq.com/v3.0/docs/api/creative_template_list/get)
 * @param params
 */
export function creativeTemplateListGet(
  params: components.CreativeTemplateListGetRequestParams
): Response<components.CreativeTemplateListGetResponse> {
  return webapi.get(`/tadsv3/creative_template_list/get`, { params })
}
/**
 * 透传接口 - [获取创意规格详情](https://developers.e.qq.com/v3.0/docs/api/creative_template/get)
 * @param req
 */
export function creativeTemplateGet(
  req: components.CreativeTemplateGetRequest
): Response<components.CreativeTemplateGetResponse> {
  return webapi.post(`/tadsv3/creative_template/get`, req)
}

/**
 * 透传接口 - [获取视频文件](https://developers.e.qq.com/v3.0/docs/api/videos/get)
 * @param params
 */
export function videosGet(params: components.VideosGetRequestParams): Response<components.VideosGetResponse> {
  return webapi.get(`/tadsv3/videos/get`, { params })
}
/**
 * 透传接口 - [添加视频文件](https://developers.e.qq.com/v3.0/docs/api/videos/add)
 * @param req
 */
export function videosAdd(req: components.VideosAddRequest): Response<components.VideosAddResponse> {
  return webapi.post(`/tadsv3/videos/add`, req, { headers: { 'content-type': 'multipart/form-data' } })
}
/**
 * 透传接口 - [获取图片信息](https://developers.e.qq.com/v3.0/docs/api/images/get)
 * @param params
 */
export function imagesGet(params: components.ImagesGetRequestParams): Response<components.ImagesGetResponse> {
  return webapi.get(`/tadsv3/images/get`, { params })
}
/**
 * 透传接口 - [添加图片文件](https://developers.e.qq.com/v3.0/docs/api/images/add)
 * @param req
 */
export function imagesAdd(req: components.ImagesAddRequest): Response<components.ImagesAddResponse> {
  return webapi.post(`/tadsv3/images/add`, req, { headers: { 'content-type': 'multipart/form-data' } })
}

/**
 * 透传接口 - [获取视频号授权记录列表](https://developers.e.qq.com/v3.0/docs/api/wechat_channels_authorization/get)
 * @param params
 */
export function wechatChannelsAuthorizationGet(
  params: components.WechatChannelsAuthorizationGetRequestParams
): Response<components.WechatChannelsAuthorizationGetResponse> {
  return webapi.get(`/tadsv3/wechat_channels_authorization/get`, { params })
}

/**
 * 透传接口 - [获取可投视频号链接](https://developers.e.qq.com/v3.0/docs/api/wechat_channels_accounts/get)
 */
export function wechatChannelsAccountsGet(
  params: components.WechatChannelsAccountsGetRequestParams
): Response<components.WechatChannelsAccountsGetResponse> {
  return webapi.get(`/tadsv3/wechat_channels_accounts/get`, { params })
}

/**
 * 透传接口 - [创建品牌形象](https://developers.e.qq.com/v3.0/docs/api/brand/add)
 * @param req
 */
export function brandAdd(req: components.BrandAddRequest): Response<components.BrandAddResponse> {
  return webapi.post(`/tadsv3/brand/add`, req, { headers: { 'content-type': 'multipart/form-data' } })
}

/**
 * 透传接口 - [获取品牌形象列表](https://developers.e.qq.com/v3.0/docs/api/brand/get)
 * @param params
 */
export function brandGet(params: components.BrandGetRequestParams): Response<components.BrandGetResponse> {
  return webapi.get(`/tadsv3/brand/get`, { params })
}

/**
 * 透传接口 - [创建朋友圈头像昵称跳转页](https://developers.e.qq.com/v3.0/docs/api/profiles/add)
 * @param req
 */
export function profilesAdd(req: components.ProfilesAddRequest): Response<components.ProfilesAddResponse> {
  return webapi.post(`/tadsv3/profiles/add`, req)
}

/**
 * 透传接口 - [获取朋友圈头像昵称跳转页](https://developers.e.qq.com/v3.0/docs/api/profiles/get)
 * @param params
 */
export function profilesGet(params: components.ProfilesGetRequestParams): Response<components.ProfilesGetResponse> {
  return webapi.get(`/tadsv3/profiles/get`, { params })
}

/**
 * 透传接口 - [获取落地页列表](https://developers.e.qq.com/v3.0/docs/api/pages/get)
 * @param params
 */
export function pagesGetOptions(params: components.PagesGetRequestParams): Response<components.PagesGetResponse> {
  return webapi.get(`/tadsv3/pages/get`, { params })
}

/**
 * 透传接口 - [更新模版预览接口](https://developers.e.qq.com/v3.0/docs/api/programmed/update)
 * @param req
 */
export function programmedUpdate(
  req: components.ProgrammedUpdateRequest
): Response<components.ProgrammedUpdateResponse> {
  return webapi.post(`/tadsv3/programmed/update`, req)
}
