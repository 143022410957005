import type { RouteRecordRaw } from 'vue-router'

const auth: RouteRecordRaw[] = [
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/auth/403.vue')
  }
]
export default auth
