import { ElMessage } from 'element-plus'
import type { FormItemConfig } from '@/components/common/form-renderer/form-components'
import { ComponentType } from '@/components/common/form-renderer/form-components/type'
import { facebookPixelList } from '@/api/facebook'

export const configs: FormItemConfig[] = [
  {
    name: 'name',
    label: '广告组名称',
    config: {
      type: ComponentType.COMMON_INPUT_TITLE,
      attr: {
        maxlength: 50,
        showWordLimit: true,
        style: {
          width: '450px'
        },
        clearable: true
      },
      default: '广告组名称_{{日期}}_{{时间}}_{{标识}}',
      wildcards: ['日期', '时间', '标识', '素材名']
    },
    rules: [{ required: true, message: '请输入广告组名称', trigger: 'blur' }]
  },
  {
    name: 'destination_type',
    label: '转化发生位置',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        {
          value: 'WEBSITE',
          label: '网站'
        },
        {
          value: 'APP',
          label: '应用',
          disabled: true
        },
        {
          value: 'UNDEFINED',
          label: '未定义',
          disabled: true
        }
      ],
      default: 'WEBSITE'
    },
    rules: [
      {
        validator: (rule, value, callback) => {
          if (value !== 'WEBSITE') {
            callback(new Error('提示：暂不支持非网站'))
          }
          callback()
        },
        trigger: 'change'
      }
    ]
  },
  {
    name: 'optimization_goal',
    label: '转化目标',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_SELECT,
      options: [{ label: '最大化转化次数', value: 'OFFSITE_CONVERSIONS' }],
      default: 'OFFSITE_CONVERSIONS'
    },
    rules: [
      {
        validator: (rule, value, callback) => {
          if (value !== 'OFFSITE_CONVERSIONS') {
            callback(new Error('提示：暂不支持非最大化转化次数'))
          }
          callback()
        },
        trigger: 'change'
      }
    ]
  },
  {
    name: 'promoted_object',
    label: '',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_STRUCT,
      elements: [
        {
          name: 'pixel_id',
          label: 'Pixel像素代码',
          config: {
            type: ComponentType.COMMON_SELECT,
            options: async (keyword, param) => {
              try {
                if (!param.accountId.value) return []
                const [hasError, data] = await facebookPixelList({
                  account_id: param.accountId.value
                })
                if (hasError) {
                  data?.message && ElMessage.error(data.message)
                  return []
                }
                return (data.data.list || []).map((el) => {
                  return { value: el.id, label: el.name }
                })
              } catch {
                return []
              }
            }
          },
          rules: [{ required: true, message: '请选择Pixel像素代码', trigger: 'change' }]
        },
        {
          name: 'custom_event_type',
          label: '转化事件',
          config: {
            type: ComponentType.COMMON_SELECT,
            options: [
              { label: '加入心愿单', value: 'ADD_TO_WISHLIST' },
              { label: '加入购物车', value: 'ADD_TO_CART' },
              { label: '发起结帐', value: 'INITIATE_CHECKOUT' },
              { label: '完成注册', value: 'COMPLETE_REGISTRATION' },
              { label: '开始试用', value: 'START_TRIAL' },
              { label: '捐款', value: 'DONATE' },
              { label: '搜索', value: 'SEARCH' },
              { label: '查看内容', value: 'VIEW_CONTENT' },
              { label: '添加支付信息', value: 'ADD_PAYMENT_INFO' },
              { label: '订阅', value: 'SUBSCRIBE' },
              { label: '购物', value: 'PURCHASE' }
            ]
          },
          rules: [{ required: true, message: '请选择转化事件', trigger: 'change' }]
        }
      ]
    }
  },
  {
    name: 'attribution_spec',
    label: '',
    sectionLabel: '转化',
    config: {
      type: ComponentType.FACEBOOK_ATTRIBUTION_SPEC
    },
    rules: [{ required: true, message: '请选择归因设置', trigger: 'change' }]
  },
  {
    name: 'bid_amount',
    label: '出价（单次成效费用目标）',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_INPUT_NUMBER,
      unit: 100,
      precision: 2,
      min: 0,
      suffix: '元'
    },
    rules: [{ required: true, message: '请输入出价（单次成效费用目标）', trigger: 'change' }],
    condition: {
      $or: [
        {
          'campaign.bid_strategy': 'LOWEST_COST_WITH_BID_CAP'
        },
        { 'campaign.bid_strategy': 'COST_CAP' }
      ]
    }
  },
  {
    name: 'bid_amount',
    label: '出价（单次成效费用目标）',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_INPUT_NUMBER,
      unit: 100,
      precision: 2,
      min: void 0,
      suffix: '元',
      default: void 0
    },
    condition: {
      $or: [
        {
          'campaign.is_budget_schedule_enabled': false
        },
        { 'campaign.smart_promotion_type': 'AUTOMATED_SHOPPING_ADS' }
      ]
    }
  },
  {
    name: 'bid_strategy',
    label: '',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      dataSelector: (data) => {
        if (data.adgroup?.bid_amount || data.adgroup?.bid_amount === 0) {
          return 'COST_CAP'
        }
        return 'LOWEST_COST_WITHOUT_CAP'
      }
    },
    condition: {
      $or: [
        {
          'campaign.is_budget_schedule_enabled': false
        },
        { 'campaign.smart_promotion_type': 'AUTOMATED_SHOPPING_ADS' }
      ]
    }
  },
  // 计费方式
  {
    name: 'billing_event',
    label: '',
    sectionLabel: '转化',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: 'IMPRESSIONS'
    }
  },
  {
    name: 'budget_type',
    label: '预算类型',
    sectionLabel: '预算和排期',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        {
          label: '单日预算',
          value: 'daily_budget'
        },
        { label: '总预算', value: 'lifetime_budget' }
      ],
      default: 'daily_budget'
    },
    condition: {
      $or: [
        {
          'campaign.is_budget_schedule_enabled': false
        },
        { 'campaign.smart_promotion_type': 'AUTOMATED_SHOPPING_ADS' }
      ]
    }
  },
  {
    name: 'daily_budget',
    label: '单日预算',
    sectionLabel: '预算和排期',
    config: {
      type: ComponentType.COMMON_INPUT_NUMBER,
      unit: 100,
      min: 0,
      precision: 2,
      suffix: '元',
      string: true
    },
    rules: [
      { required: true, message: '请输入单日预算', trigger: 'blur' },
      {
        validator: (rule, value, callback) => {
          if (Number(value) > 0) {
            callback()
          } else {
            callback(new Error('单日预算必须大于0'))
          }
        },
        trigger: 'blur'
      }
    ],
    condition: {
      $and: [
        {
          $or: [
            {
              'campaign.is_budget_schedule_enabled': false
            },
            { 'campaign.smart_promotion_type': 'AUTOMATED_SHOPPING_ADS' }
          ]
        },
        { 'adgroup.budget_type': 'daily_budget' }
      ]
    }
  },
  {
    name: 'lifetime_budget',
    label: '总预算',
    sectionLabel: '预算和排期',
    config: {
      type: ComponentType.COMMON_INPUT_NUMBER,
      unit: 100,
      min: 0,
      precision: 2,
      suffix: '元',
      string: true
    },
    rules: [
      { required: true, message: '请输入总预算', trigger: 'blur' },
      {
        validator: (rule, value, callback) => {
          if (Number(value) > 0) {
            callback()
          } else {
            callback(new Error('总预算必须大于0'))
          }
        },
        trigger: 'blur'
      }
    ],
    condition: {
      $and: [
        {
          $or: [
            {
              'campaign.is_budget_schedule_enabled': false
            },
            { 'campaign.smart_promotion_type': 'AUTOMATED_SHOPPING_ADS' }
          ]
        },
        { 'adgroup.budget_type': 'lifetime_budget' }
      ]
    }
  },
  {
    name: ['start_time', 'end_time'],
    label: '投放时间',
    sectionLabel: '预算和排期',
    config: {
      type: ComponentType.FACEBOOK_PROMOTE_DATE,
      elements: {
        type: ComponentType.COMMON_PROMOTE_DATE,
        longTermType: 'datetime',
        specificType: 'datetimerange',
        longTermFormat: 'YYYY-MM-DD HH:mm:ss',
        specificFormat: 'YYYY-MM-DD HH:mm:ss',
        dateType: 'SPECIFIC'
      }
    },
    condition: {
      $or: [
        {
          'adgroup.budget_type': 'lifetime_budget'
        },
        {
          'campaign.budget_type': 'lifetime_budget'
        }
      ]
    }
  },
  {
    name: ['start_time', 'end_time'],
    label: '投放时间',
    sectionLabel: '预算和排期',
    config: {
      type: ComponentType.FACEBOOK_PROMOTE_DATE
    },
    condition: {
      $or: [
        {
          'adgroup.budget_type': 'daily_budget'
        },
        {
          'campaign.budget_type': 'daily_budget'
        }
      ]
    }
  },
  {
    name: 'adset_schedule_disabled',
    label: '分时段投放',
    sectionLabel: '预算和排期',
    config: { type: ComponentType.COMMON_SWITCH, default: false },
    condition: {
      $or: [
        {
          'adgroup.budget_type': 'lifetime_budget',
          'adgroup.end_time': { $ne: '' }
        },
        { 'campaign.budget_type': 'lifetime_budget', 'adgroup.end_time': { $ne: '' } }
      ]
    }
  },
  {
    name: 'adset_schedule',
    label: '投放时段',
    sectionLabel: '预算和排期',
    config: {
      type: ComponentType.FACEBOOK_PROMOTE_TIME
    },
    condition: {
      'adgroup.adset_schedule_disabled': true
    }
  },
  /** 分时段投放时，投放策略一定要用day_parting（也称为广告调度） */
  {
    name: 'pacing_type',
    label: '',
    sectionLabel: '预算和排期',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: ['day_parting']
    },
    condition: {
      'adgroup.adset_schedule_disabled': true
    }
  },
  /** 定向 */
  {
    name: 'targeting',
    label: '',
    sectionLabel: '定向',
    config: {
      type: ComponentType.FACEBOOK_TARGETING
    }
  }
]

for (const item of configs) {
  Reflect.set(item, 'condition', {
    ...Reflect.get(item, 'condition'),
    'campaign.objective': 'OUTCOME_SALES'
  })
}
