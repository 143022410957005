import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

// Lodash 工具库
import _ from 'lodash'

// Sentry 监控平台
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'

// Element Plus
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ElementPlusElTableInfiniteScroll from 'el-table-infinite-scroll'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'

// 组件库
import InfluxUI from '@adsmocha/influx-ui'
import '@adsmocha/influx-ui/dist/style.css'

// 自定义指令
import { setupDirectives } from '@/plugins'

// 自定义样式
import { changeTheme } from './assets/css/computedColor'
import './assets/main.css'

declare global {
  interface Window {
    MUI: any
  }
}

changeTheme()

const app = createApp(App)
const pinia = createPinia()

const MUI = window.MUI

pinia.use(({ store }) => {
  const initialState = _.cloneDeep(store.$state)

  store.$reset = () => {
    store.$patch(_.cloneDeep(initialState))
  }
})

app.use(pinia)
app.use(router)
app.use(InfluxUI)
app.use(ElementPlus, {
  locale: zhCn
})
app.use(ElementPlusElTableInfiniteScroll)
app.use(MUI)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

MUI.setConfig({
  appId: import.meta.env.VITE_APPID
})

// 注册全局自定义指令，如：v-permission权限指令
setupDirectives(app)

if (import.meta.env.PROD) {
  Sentry.init({
    enabled: false, // sentry监控平台坏了，暂时关闭
    app,
    release: process.env.SENTRY_RELEASE,
    environment: process.env.NODE_ENV,
    dsn: 'https://ae8387f4127b487081b92873056f7222@production.sentry.influx.io/17',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'Failed to fetch dynamically imported module', // 动态加载文件资源
      'Importing a module script failed',
      'TypeError: window.aiparser is not a function',
      'Non-Error promise rejection captured', // 表单校验失败触发的reject
      // https://github.com/w3c/csswg-drafts/issues/5488#issuecomment-812279513
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'ResizeObserver loop limit exceeded', // 低版本浏览器超出循环限制
      'ResizeObserver loop completed with undelivered notifications.', // Firefox 低版本浏览器超出循环限制
      'Request failed with status code', // Axios接口请求失败
      'Non-Error exception captured',
      'Failed to fetch' // 版本更新资源请求错误
    ],
    initialScope: (scope) => {
      // 初始化之后判断是否有用户信息，若有则绑定Sentry用户上下文
      const storageAuth = JSON.parse(localStorage.getItem('auth') as string)
      if (storageAuth?.name) {
        scope.setUser({ id: String(storageAuth.id), username: storageAuth.name })
      }
      return scope
    }
  })
}

app.mount('#app')
