<template>
  <div class="task-center-container" @click="dialogStatusControlStore.showOperationDialog">
    <div><Save class="task-center-icon"></Save></div>
    <div class="text" v-show="simplify">操作中心</div>
    <OperationDialog />
  </div>
</template>

<script setup lang="ts">
import OperationDialog from '@/components/operation/operation-dialog.vue'
import { useDialogStatusControlStore } from '@/stores/dialog-status-control/index'
import { Save } from '@/assets/svg'
const dialogStatusControlStore = useDialogStatusControlStore()
defineProps<{ simplify?: boolean }>()
</script>

<style scoped>
.task-center-container {
  width: 100%;
  height: 50px;
  background: #2f2e50;
  border-radius: var(--el-border-radius-base);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task-center-icon {
  transform: scale(1.2) translateY(2px);
}

.text {
  font-size: 16px;
  font-weight: bold;
  color: #ababb9;
  margin-left: 10px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}
</style>
