import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "26",
  height: "26",
  class: "icon",
  viewBox: "0 0 1047 1024"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#928DF4",
      d: "M34.396 306.94 484.75 501.617a86.3 86.3 0 0 0 34.392 5.84 84.4 84.4 0 0 0 35.691-7.787l454.246-202.463a57.754 57.754 0 0 0 0-105.775L554.832 7.787a82.41 82.41 0 0 0-68.786 0L31.8 202.464A57.1 57.1 0 0 0 .003 253.08a58.4 58.4 0 0 0 34.393 53.86M512.652 64.892a17.52 17.52 0 0 1 16.872 0L965.6 242.697 528.875 437.374a19.47 19.47 0 0 1-16.872 0L75.278 253.729zm490.586 686.56a30.5 30.5 0 0 0-11.032 0l-463.33 205.709a18.82 18.82 0 0 1-17.522 0L48.024 762.484a42.8 42.8 0 0 0-9.734 0 30.5 30.5 0 0 0-9.734 59.052l459.437 194.677a80.5 80.5 0 0 0 34.393 7.787 75.9 75.9 0 0 0 31.797-7.138l460.086-202.464a31.15 31.15 0 0 0 20.117-28.553 30.5 30.5 0 0 0-31.148-34.393"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#928DF4",
      d: "M1007.78 475.011a29.85 29.85 0 0 0-13.627 0l-469.82 212.847a19.47 19.47 0 0 1-16.872 0L46.726 478.905h-12.33A30.5 30.5 0 0 0 .003 506.16a29.85 29.85 0 0 0 16.223 26.606l465.278 210.25a80.5 80.5 0 0 0 34.393 7.788 72 72 0 0 0 32.446-7.788l471.767-210.25a29.85 29.85 0 0 0 18.17-27.255 31.15 31.15 0 0 0-30.5-30.5"
    }, null, -1)
  ])))
}
export default { render: render }