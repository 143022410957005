<template>
  <div class="side-bar-item-container" :style="{ background: config.active ? '#2F2E50' : '' }">
    <div class="parent-container" @click="selectItem">
      <template v-if="!editing">
        <div class="icon-container" v-if="config.defaultIcon">
          <component
            :is="routePath === config.route || openChildren || config.active ? config.activeIcon : config.defaultIcon"
          />
        </div>
        <div class="item-container" v-show="!simplify">
          <div class="text">{{ config.label }}</div>
        </div>
      </template>
    </div>
    <div v-if="config.active && config.children" class="children-container">
      <Draggable
        v-model="dragItems.children"
        v-bind="DRAGGABLE_CONFIG"
        item-key="route"
        :move="handleMove"
        @end="handleDragEnd"
      >
        <template #item="{ element }">
          <SideBarItem :class="element.fixed ? '' : 'movable'" :config="element" :simplify="simplify" />
        </template>
      </Draggable>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SideBarItemConfig } from '..'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import SideBarItem from '@/components/side/components/side-bar-item.vue'
import { ElMessage } from 'element-plus'
import { reportConfigUpdate } from '@/api/report'
import Draggable from 'vuedraggable'
import { useVModel } from '@vueuse/core'

const route = useRoute()
const router = useRouter()
const editing = ref<boolean>(false)
const props = defineProps<{
  config: SideBarItemConfig
  simplify?: boolean
}>()
const DRAGGABLE_CONFIG = {
  animation: 200,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost',
  sort: props.config.draggable,
  draggable: '.movable',
  delay: 100,
  chosenClass: 'isActive'
}
const emit = defineEmits(['click:itemSelected', 'update:config'])
const dragItems = useVModel(props, 'config', emit, { passive: true })
const routePath = computed(() => {
  return route.path
})
const openChildren = computed(() => {
  const list = route.matched
  return list.some((i) => i.name === props.config.name)
})
const selectItem = async () => {
  await router.replace(props.config.route)
  emit('click:itemSelected', props.config.route)
}

const dragItem = ref<SideBarItemConfig & { id: number }>()
const currentIndex = ref<number>(0)
const handleDragEnd = async () => {
  try {
    if (!dragItem.value) return
    const [hasError, data] = await reportConfigUpdate({
      id: Number(dragItem.value.id),
      sort: currentIndex.value
    })
    if (hasError) {
      data?.message && ElMessage.error(data.message)
      return
    }
  } catch (error) {
    console.error(error)
  }
}
const handleMove = (e) => {
  dragItem.value = e.draggedContext.element
  currentIndex.value = e.draggedContext.futureIndex
}
</script>

<style scoped>
.parent-container {
  display: flex;
  padding: 0 20px;
  align-items: center;
  min-height: 60px;
  overflow-x: hidden;
  position: relative;
  /* cursor: pointer; */
}
.children-container {
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow-y: scroll;
}
.children-container::-webkit-scrollbar {
  display: none;
}
.icon-container {
  width: 40px;
  display: flex;
  transform: scale(1.1);
  align-items: center;
  justify-content: center;
}
.text {
  width: 100px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 5px;
  height: 60px;
  line-height: 60px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.edit-container {
  position: absolute;
  display: flex;
  top: 50%;
  left: 200px;
  transform: translateY(-50%);
  transition: all 0.25s;
  z-index: 10;
}
.parent-container:hover .edit-container {
  left: 80px;
}
.btn {
  margin-right: 5px;
  cursor: pointer;
}
.update-report-name {
  height: 150px;
  overflow: hidden;
  position: relative;
}
.el-input {
  height: 150px;
}
.edit-panel-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
:deep(.el-textarea__inner) {
  height: 150px;
}
.mr-10 {
  margin-right: 10px;
}
.isActive {
  transition: all 0.25s;
  background: #cacaca6f;
}
.disabled {
  /* pointer-events: none; */
  cursor: not-allowed;
}
</style>
