import webapi, { type Response } from '../service'
import type * as components from './type'

/**
 * 获取动态报表详情
 * @param req
 */
export function reportDetail(req: components.ReportDetailRequest): Response<components.ReportDetailResponse> {
  return webapi.post(`/report/detail`, req)
}

/**
 * 获取动态报表汇总数据
 * @param req
 */
export function reportOverview(req: components.ReportOverviewRequest): Response<components.ReportOverviewResponse> {
  return webapi.post(`/report/overview`, req)
}

/**
 * 导出动态报表数据
 * @param req
 */
export function reportExportFile(
  req: components.ReportExportFileRequest
): Response<components.ReportExportFileResponse> {
  return webapi.post(`/report/export/file`, req)
}

/**
 * 获取导出中心列表
 */
export function reportTaskList(): Response<components.ReportTaskListResponse> {
  return webapi.get(`/report/task/list`)
}

/**
 * 保存动态报表模版
 * @param req
 */
export function reportConfigAdd(req: components.ReportConfigAddRequest): Response<components.ReportConfigAddResponse> {
  return webapi.post(`/report/config/add`, req)
}

/**
 * 更新动态报表模版
 * @param req
 */
export function reportConfigUpdate(
  req: components.ReportConfigUpdateRequest
): Response<components.ReportConfigUpdateResponse> {
  return webapi.post(`/report/config/update`, req)
}

/**
 * 获取动态报表模版列表
 * @param params
 */
export function reportConfigList(
  params: components.ReportConfigListRequestParams
): Response<components.ReportConfigListResponse> {
  return webapi.get(`/report/config/list`, { params })
}

/**
 * 获取动态报表模版详情
 * @param params
 */
export function reportConfigDetail(
  params: components.ReportConfigDetailRequestParams
): Response<components.ReportConfigDetailResponse> {
  return webapi.get(`/report/config/detail`, { params })
}

/**
 * 删除动态报表模版
 * @param req
 */
export function reportConfigDelete(
  req: components.ReportConfigDeleteRequest
): Response<components.ReportConfigDeleteResponse> {
  return webapi.post(`/report/config/delete`, req)
}

/**
 * 获取指标配置
 */
export function reportIndicatorFieldOption(): Response<components.ReportIndicatorFieldOptionResponse> {
  return webapi.get(`/report/indicator_field/option/v1`)
}

/**
 * 获取维度配置
 */
export function reportDimensionFieldOption(): Response<components.ReportDimensionFieldOptionResponse> {
  return webapi.get(`/report/dimension_field/option`)
}

/**
 * 获取查找配置
 */
export function reportSearchFieldOption(): Response<components.ReportSearchFieldOptionResponse> {
  return webapi.get(`/report/search_field/option/v1`)
}

/**
 * 获取素材分析详情
 * @param req
 */
export function reportMaterialDetail(
  req: components.ReportMaterialDetailRequest
): Response<components.ReportMaterialDetailResponse> {
  return webapi.post(`/report/material/detail`, req)
}

/**
 * 获取素材分析汇总数据
 * @param req
 */
export function reportMaterialOverview(
  req: components.ReportMaterialOverviewRequest
): Response<components.ReportMaterialOverviewResponse> {
  return webapi.post(`/report/material/overview`, req)
}

/** 获取当前支持的维度列表
 * @param req
 */
export function reportDimensions(req: components.ReportDimensionRequest): Response<components.ReportDimensionResponse> {
  return webapi.post(`/report/dimensions`, req)
}
