import type { FormItemConfig } from '@/components/common/form-renderer/form-components'
import { ComponentType } from '@/components/common/form-renderer/form-components/type'

export const callToActionConfigs: FormItemConfig[] = [
  {
    name: 'call_to_action_id',
    label: '行动引导文案',
    tip: '通过行动引导文案，选择你希望用户在看到你的广告时所采取的操作。它是你广告创意的一部分。如果你的广告位还包含 Pangle，那么系统会在行动引导文案过长时使用默认文案',
    config: {
      type: ComponentType.TIKTOK_CALL_TO_ACTION_ID
    },
    rules: [{ required: true, message: '至少选择 1 个', trigger: ['blur', 'change'] }]
  }
]

export const configs: FormItemConfig[] = [
  {
    name: 'is_aco',
    label: '智能创意广告',
    config: {
      type: ComponentType.COMMON_SWITCH,
      default: true
    },
    condition: {
      'campaign.is_spc': false
    }
  },
  {
    name: 'ad_name',
    label: '广告名称',
    config: {
      type: ComponentType.COMMON_INPUT_TITLE,
      attr: {
        maxlength: 512,
        showWordLimit: true,
        style: {
          width: '450px'
        },
        clearable: true,
        placeholder: '请输入广告名称'
      },
      default: '广告名称_{{日期}}_{{时间}}_{{标识}}',
      wildcards: ['日期', '时间', '标识', '素材名']
    },
    condition: {
      'campaign.is_spc': false
    },
    rules: [
      { required: true, message: '请输入广告名称', trigger: ['blur', 'change'] },
      {
        validator: (rule, value, callback) => {
          // 不能含有 emoji
          if (value.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/)) {
            return callback(new Error('名称不能含有 Emoji'))
          }
          // 名称不能超过512字符，中文或日文每个字占用2个字符，每个英语字符占用1个字符，单独计算
          let length = 0
          for (let i = 0; i < value.length; i++) {
            if (value.charCodeAt(i) > 255) {
              length += 2
            } else {
              length++
            }
          }
          if (length > 512) {
            callback(new Error('名称不能超过512个字符，中文或日文每个字占用2个字符，每个英语字符占用1个字符'))
          } else {
            callback()
          }
        },
        trigger: ['blur', 'change']
      }
    ]
  },
  {
    name: 'ad_format',
    label: '广告版式',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [{ label: '单视频', value: 'SINGLE_VIDEO' }],
      default: 'SINGLE_VIDEO'
    },
    condition: {
      'campaign.is_spc': false,
      'adcreative.is_aco': false
    }
  },
  {
    name: 'ads_set',
    label: '广告发布身份',
    tip: '使用 TikTok 账户下的帖子来投放广告，可以帮助提升账户的知名度和广告的互动率。如果你在 Pangle/全球化应用组合广告位投放 Spark Ads，用户将跳转至目标页面链接，而不是你的 TikTok 账户。',
    config: {
      type: ComponentType.TIKTOK_ADS_SET
    }
  },
  {
    name: 'identity_type',
    label: '认证类型',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      dataSelector: (data) => {
        return data.adcreative?.ads_set ? void 0 : 'CUSTOMIZED_USER'
      }
    }
  },
  {
    name: 'identity_id',
    label: '认证身份',
    config: {
      type: ComponentType.TIKTOK_IDENTITY_ID
    },
    rules: [{ required: true, message: '请选择认证身份', trigger: ['blur', 'change'] }]
  },
  ...callToActionConfigs
]
