import type { FormItemConfig } from '@/components/common/form-renderer/form-components'
import { ComponentType } from '@/components/common/form-renderer/form-components/type'
import { marketingTargetAssetsGet, landingPageSellStrategyGet } from '@/api/tads-v3'
import { ShortPlayPayTypeEnum } from '@/components/common/form-renderer/form-components/tads-v3/sell-strategy/type'
import { isFullWidthCharacterNumber } from '@/utils'

export const configs: FormItemConfig[] = [
  {
    name: 'marketing_goal',
    label: '营销目的',
    sectionLabel: '营销内容',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [{ value: 'MARKETING_GOAL_PRODUCT_SALES', label: '商品销售' }],
      default: 'MARKETING_GOAL_PRODUCT_SALES'
    }
  },
  {
    name: 'marketing_sub_goal',
    label: '二级营销目的',
    sectionLabel: '营销内容',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: 'MARKETING_SUB_GOAL_UNKNOWN'
    }
  },
  {
    name: 'marketing_target_type',
    label: '推广产品',
    sectionLabel: '营销内容',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [{ value: 'MARKETING_TARGET_TYPE_SHORT_DRAMA', label: '短剧' }],
      default: 'MARKETING_TARGET_TYPE_SHORT_DRAMA'
    }
  },
  {
    name: 'marketing_asset_id',
    label: '选择产品',
    sectionLabel: '营销内容',
    config: {
      type: ComponentType.COMMON_SELECT,
      options: async (keyword, params) => {
        try {
          const [hasError, data] = await marketingTargetAssetsGet({
            account_id: params.accountId.value,
            marketing_target_type: 'MARKETING_TARGET_TYPE_SHORT_DRAMA',
            page: 1,
            size: 100
          })
          if (hasError) return []
          return (data.data.list || []).map((el) => ({
            label: el.marketing_asset_name,
            value: el.marketing_asset_id
          }))
        } catch {
          return []
        }
      }
    },
    rules: [{ required: true, message: '请选择推广产品', trigger: 'change' }]
  },
  {
    name: 'short_play_pay_type',
    label: '短剧售卖方式',
    sectionLabel: '营销内容',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        { value: ShortPlayPayTypeEnum.FREE, label: '免费短剧' },
        { value: ShortPlayPayTypeEnum.CHARGE, label: '付费短剧' }
      ],
      default: ShortPlayPayTypeEnum.FREE
    }
  },
  {
    name: 'sell_strategy_id',
    label: '短剧售卖策略',
    sectionLabel: '营销内容',
    config: {
      type: ComponentType.COMMON_SELECT,
      options: async (keyword, params) => {
        try {
          const [hasError, data] = await landingPageSellStrategyGet({
            account_id: params.accountId.value,
            page: 1,
            size: 100
          })
          if (hasError) return []
          return (data.data.list || []).map((el) => ({
            label: el.strategy_name,
            value: el.strategy_id
          }))
        } catch {
          return []
        }
      }
    },
    condition: {
      'adgroup.short_play_pay_type': ShortPlayPayTypeEnum.CHARGE
    },
    rules: [{ required: true, message: '请选择短剧售卖策略', trigger: 'change' }]
  },
  {
    name: 'marketing_carrier_type',
    label: '营销载体类型',
    sectionLabel: '营销内容',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [{ value: 'MARKETING_CARRIER_TYPE_JUMP_PAGE', label: '页面跳转' }],
      default: 'MARKETING_CARRIER_TYPE_JUMP_PAGE'
    }
  },
  {
    name: 'conversion_type',
    label: '转化归因',
    sectionLabel: '营销内容',
    config: {
      type: ComponentType.TADS_V3_CONVERSION_TYPE
    }
  },
  {
    name: 'user_action_sets',
    label: '精准匹配归因',
    sectionLabel: '营销内容',
    config: {
      type: ComponentType.TADS_V3_USER_ACTION_SETS
    },
    condition: {
      'adgroup.conversion_type': 'EXACT'
    }
  },
  {
    name: 'optimization_goal',
    label: '优化目标',
    sectionLabel: '营销内容',
    config: {
      type: ComponentType.TADS_V3_OPTIMIZATION_GOAL
    },
    condition: {
      'adgroup.conversion_type': { $in: ['ALL', 'EXACT'] }
    },
    rules: [{ required: true, message: '请选择优化目标', trigger: 'change' }]
  },
  {
    name: ['conversion_id', 'optimization_goal', 'deep_behavior_optimization_goal', 'deep_worth_optimization_goal'],
    label: '转化',
    sectionLabel: '营销内容',
    tip: '转化中包含你希望高效获取的行为作为优化目标，深度优化目标，以及转化数据上报和归因的方式。我们将按照你设置的优化目标，为你尽可能找到实现转化的用户。你可以在工具箱-转化归因模块进行转化配置。',
    config: {
      type: ComponentType.TADS_V3_CONVERSION
    },
    condition: {
      'adgroup.conversion_type': 'CONVERSION'
    }
  },
  {
    name: 'automatic_site_enabled',
    label: '自动版位',
    sectionLabel: '广告版位',
    tip: '广告在不同的位置可能会有更好的表现，使用自动版位时，系统将根据你的设置，选择表现更好的位置进行投放，并帮你触达尽可能多的目标用户。',
    config: {
      type: ComponentType.COMMON_SWITCH,
      default: false
    }
  },
  {
    name: 'site_set',
    label: '版位选择',
    sectionLabel: '广告版位',
    config: {
      type: ComponentType.TADS_V3_SITE_SET,
      default: []
    },
    condition: {
      'adgroup.automatic_site_enabled': false
    }
  },
  {
    name: 'search_expand_targeting_switch',
    label: '搜索场景扩量-定向拓展',
    sectionLabel: '广告版位',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: 'SEARCH_EXPAND_TARGETING_SWITCH_CLOSE'
    }
  },
  {
    name: 'targeting',
    label: '',
    sectionLabel: '定向',
    config: {
      type: ComponentType.TADS_V3_TARGETING
    }
  },
  {
    name: 'bid_mode',
    label: '计费方式',
    sectionLabel: '出价与预算',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        { value: 'BID_MODE_OCPM', label: 'oCPM' },
        { value: 'BID_MODE_CPM', label: 'CPM', disabled: true }
      ],
      default: 'BID_MODE_OCPM'
    }
  },
  {
    name: 'smart_bid_type',
    label: '出价类型',
    sectionLabel: '出价与预算',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [
        { value: 'SMART_BID_TYPE_CUSTOM', label: '稳定拿量' },
        { value: 'SMART_BID_TYPE_SYSTEMATIC', label: '最大化转量投放' }
      ],
      default: 'SMART_BID_TYPE_CUSTOM'
    }
  },
  {
    name: 'bid_amount',
    label: '出价',
    sectionLabel: '出价与预算',
    config: {
      type: ComponentType.TADS_V3_BID_AMOUNT,
      unit: 100,
      precision: 2,
      min: 0.01,
      max: 5000,
      suffix: '元/千次曝光',
      attr: {
        clearable: true
      }
    },
    condition: {
      'adgroup.bid_mode': 'BID_MODE_OCPM',
      'adgroup.smart_bid_type': 'SMART_BID_TYPE_CUSTOM'
    }
  },
  {
    name: ['deep_conversion_behavior_bid', 'deep_conversion_worth_rate'],
    label: '深度目标出价',
    sectionLabel: '出价与预算',
    config: {
      type: ComponentType.TADS_V3_CONVERSION_BID
    },
    condition: {
      'adgroup.bid_mode': 'BID_MODE_OCPM',
      'adgroup.smart_bid_type': 'SMART_BID_TYPE_CUSTOM',
      $or: [
        { 'adgroup.deep_behavior_optimization_goal': { $exists: true } },
        { 'adgroup.deep_worth_optimization_goal': { $exists: true } }
      ]
    }
  },
  {
    name: ['auto_acquisition_enabled', 'auto_acquisition_budget'],
    label: '一键起量',
    sectionLabel: '出价与预算',
    tip: '为保证系统探索效果，起量预算设置后不支持修改，你可以在该广告的一键起量状态中止或结束时，重新设置起量预算，开始一次新的起量周期。',
    config: {
      type: ComponentType.TADS_V3_AUTO_ACQUISITION
    },
    condition: {
      'adgroup.bid_mode': 'BID_MODE_OCPM',
      'adgroup.smart_bid_type': 'SMART_BID_TYPE_CUSTOM'
    }
  },
  {
    name: 'daily_budget',
    label: '广告日预算',
    sectionLabel: '出价与预算',
    tip: '对于微信朋友圈广告，下调日预算不能低于该推广计划今日已消耗金额的 1.5 倍；对于非微信朋友圈广告，修改后的日预算不能低于该推广计划今日已消耗金额加上 50 元',
    config: {
      type: ComponentType.TADS_V3_DAILY_BUDGET
    }
  },
  {
    name: ['begin_date', 'end_date'],
    label: '投放日期',
    sectionLabel: '广告设置',
    tip: '设置投放日期时，不可选择距离当前日期超过一年的日期',
    config: {
      type: ComponentType.COMMON_PROMOTE_DATE,
      dateType: 'SPECIFIC'
    }
  },
  {
    name: ['time_series', 'first_day_begin_time'],
    label: '投放时间',
    sectionLabel: '广告设置',
    config: {
      type: ComponentType.TADS_V3_PROMOTE_TIME
    }
  },
  {
    name: 'adgroup_name',
    label: '广告名称',
    sectionLabel: '广告设置',
    tip: '广告名称不能超过120个字符（1个英文=1个字符，1个中文=2个字符），且不能含有特殊字符 <>&"\'/\\',
    config: {
      type: ComponentType.COMMON_INPUT_TITLE,
      attr: {
        placeholder: '广告名称仅用于管理创意，不会对外展示',
        style: {
          width: '450px'
        },
        clearable: true
      },
      wildcards: ['日期', '时间', '标识', '素材名', '落地页名', '定向模版'],
      default: '广告_{{日期}}_{{时间}}_{{标识}}'
    },
    rules: [
      { required: true, message: '请输入广告名称', trigger: ['blur', 'change'] },
      {
        validator: (rule, value) => !/[<>&"'/\\]/.test(value),
        message: '广告名称不能含有特殊字符 <>&"\'/\\',
        trigger: ['blur', 'change']
      },
      {
        validator: (rule, value) => isFullWidthCharacterNumber(value) <= 60,
        message: '长度超过60个字，请重新调整',
        trigger: ['blur', 'change']
      }
    ]
  }
]
