<template>
  <div class="side-bar">
    <div class="control-sidebar" @click="changeWidth">
      <el-icon class="arrow" :class="!simplify ? '' : 'rotate'"><ArrowRightBold /></el-icon>
    </div>
    <div class="side-bar-container">
      <div class="side-bar-header" v-show="simplify">
        <span>MOCHA</span>
        <el-tag v-if="mochaTag" size="small" class="margin-left-xs" :type="mochaTag.tagType">
          {{ mochaTag.tagText }}
        </el-tag>
      </div>
      <div class="side-bar-header" v-show="!simplify" style="justify-content: center">M</div>
      <div class="side-bar-content">
        <SideBarItem
          v-for="(item, index) in sideBar"
          :key="index + item.label"
          :config="item"
          :simplify="!simplify"
          class="root-node"
          @click:itemSelected="activeSelectItem"
          @update:config="(newVal) => (item.children = newVal)"
        />
      </div>
      <div class="side-bar-footer">
        <TaskCenter v-permission="'operating_center:list'" :simplify="simplify" />
        <ExportCenter v-permission="'export_center:list'" :simplify="simplify" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ExportCenter from '@/components/side/components/export-center.vue'
import TaskCenter from './components/task-center.vue'
import SideBarItem from './components/side-bar-item.vue'
import { onMounted, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import type { SideBarItemConfig } from '.'
import { useSideBarStore, configs } from '@/stores/side-bar'

const route = useRoute()
const emit = defineEmits(['update:sideBarWidth'])
const versions = {
  dev: { hostname: ['localhost', 'dev.app.adsmocha.com'], tagText: '开发版', tagType: 'warning' },
  privateBeta: { hostname: ['pre.app.adsmocha.com'], tagText: '内测版', tagType: 'primary' },
  production: { hostname: ['app.adsmocha.com'], tagText: '正式版', tagType: 'primary' },
  test: { hostname: ['test.app.adsmocha.com'], tagText: '测试版', tagType: 'warning' }
}
const hostname = window.location.hostname
const sideBar = ref<SideBarItemConfig[]>([])
const props = defineProps<{ sideBarWidth: number }>()
const simplify = computed(() => props.sideBarWidth > 100)

const sideBarStore = useSideBarStore()

const mochaTag = computed(() => {
  const version = Object.values(versions).find((version) => version.hostname.includes(hostname))
  if (version) {
    return {
      tagText: version.tagText,
      tagType: version.tagType as 'warning' | 'primary' | 'success' | 'info' | 'danger'
    }
  } else return void 0
})

const activeSelectItem = (_route: string) => {
  if (_route[_route.length - 1] === '/') {
    _route = _route.slice(0, -1)
  }
  const list = route.matched
  configs.forEach((item) => {
    let openChildren = false
    item.children?.forEach((child) => {
      if (list.some((i) => i.name === child.name)) openChildren = true
    })

    if (item.route === _route || openChildren) {
      item.active = true
      return
    } else if (_route.includes('/report/') && item.route === '/report') {
      item.active = true
    } else {
      if (item.children?.length) {
        if (item.children.find((item) => item.route === _route)) {
          item.active = true
          return
        }
      }
      item.active = false
    }
  })
}

const changeWidth = () => {
  emit('update:sideBarWidth', simplify.value ? 70 : 200)
}

onMounted(async () => {
  sideBar.value = sideBarStore.getSideBar()
  activeSelectItem(route.path)
})
</script>

<style scoped>
.side-bar {
  height: 100%;
  position: relative;
}
.control-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #2f2e50;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  right: -15px;
  top: 20px;
  transition: all 0.3s;
}
.rotate {
  transform: rotateZ(-180deg);
}
.arrow {
  transition: all 0.3s;
}
.side-bar-container {
  background: #43425d;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.side-bar-header {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 26px;
  font-weight: 800;
  min-width: 70px;
  background-color: #2f2e50;
}
.side-bar-content {
  flex-grow: 1;
  overflow: auto;
}
.side-bar-content::-webkit-scrollbar {
  display: none;
}
.side-bar-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
</style>
