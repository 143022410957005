import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "17.333"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "rgba(255,255,255,0.6)",
      d: "M9.524 1.926h9.764a.7.7 0 0 1 .712.692v14.023a.7.7 0 0 1-.712.692H.712a.7.7 0 0 1-.5-.206.68.68 0 0 1-.212-.484V.69A.706.706 0 0 1 .716 0h7.14a1.24 1.24 0 0 1 1.026.627l.643 1.3Z"
    }, null, -1)
  ])))
}
export default { render: render }