import { defineStore } from 'pinia'
import { computed, markRaw, reactive } from 'vue'
import {
  AccountActive,
  Account,
  FileActive,
  File,
  Tool,
  ToolActive,
  CircleActive,
  Circle,
  OrderActive,
  Order,
  Role,
  RoleActive,
  User,
  UserActive,
  BatchCreate,
  BatchCreateActive,
  AssetManage,
  AssetManageActive
} from '@/assets/svg'
import type { SideBarItemConfig } from './type'
import { hasPermission } from '@/directives/permission'
import _ from 'lodash'

const versions = {
  dev: ['localhost', 'dev.app.adsmocha.com'],
  privateBeta: ['pre.app.adsmocha.com'],
  production: ['app.adsmocha.com'],
  test: ['test.app.adsmocha.com']
}
/** 当前环境 */
const env = computed(() => {
  for (const key in versions) {
    if (versions[key].includes(window.location.hostname)) {
      return key
    }
  }
  return ''
})

export const configs = reactive<SideBarItemConfig[]>([
  {
    label: '动态报表',
    defaultIcon: markRaw(File),
    route: '/report',
    name: 'report-detail',
    active: true,
    activeIcon: markRaw(FileActive),
    draggable: true,
    permission: ['report:list']
  },
  {
    label: '账户管家',
    defaultIcon: markRaw(Account),
    route: '/asset/account',
    active: false,
    activeIcon: markRaw(AccountActive),
    permission: ['media_account:list', 'account:list'],
    children: [
      {
        label: '广告账户',
        route: '/asset/account',
        name: 'asset-manage-account',
        defaultIcon: markRaw(Circle),
        activeIcon: markRaw(CircleActive),
        permission: ['account:list']
      },
      {
        label: '媒体授权',
        route: '/asset/media-authorization',
        name: 'media-authorization',
        defaultIcon: markRaw(Circle),
        activeIcon: markRaw(CircleActive),
        permission: ['media_account:list']
      }
    ]
  },
  {
    label: '广告创建',
    defaultIcon: markRaw(BatchCreate),
    route: '/batch-create/tads',
    active: false,
    activeIcon: markRaw(BatchCreateActive),
    permission: ['batch_create:tads', 'batch_create:ocean', 'batch_create:facebook', 'batch_create:tiktok'],
    children: [
      {
        label: '腾讯广告',
        route: '/batch-create/tads',
        name: 'batch-create-tads',
        defaultIcon: markRaw(Circle),
        activeIcon: markRaw(CircleActive),
        permission: ['batch_create:tads']
      },
      {
        label: '巨量引擎',
        route: '/batch-create/ocean',
        name: 'batch-create-ocean',
        defaultIcon: markRaw(Circle),
        activeIcon: markRaw(CircleActive),
        permission: ['batch_create:ocean']
      },
      {
        label: 'Facebook',
        route: '/batch-create/meta',
        name: 'batch-create-meta',
        defaultIcon: markRaw(Circle),
        activeIcon: markRaw(CircleActive),
        permission: ['batch_create:facebook']
      },
      {
        label: 'TikTok',
        route: '/batch-create/tiktok',
        name: 'batch-create-tiktok',
        defaultIcon: markRaw(Circle),
        activeIcon: markRaw(CircleActive),
        permission: ['batch_create:tiktok']
      }
    ]
  },
  {
    label: '资产管理',
    defaultIcon: markRaw(AssetManage),
    route: '/asset/material',
    active: false,
    activeIcon: markRaw(AssetManageActive),
    permission: ['product:list', 'material:list', 'landing_page:wechat_mini_program:list'],
    children: [
      {
        label: '素材管理',
        route: '/asset/material',
        name: 'material-manage',
        defaultIcon: markRaw(Circle),
        activeIcon: markRaw(CircleActive),
        permission: ['material:list']
      },
      {
        label: '商品管理',
        route: '/asset/product',
        name: 'product-manage',
        defaultIcon: markRaw(Circle),
        activeIcon: markRaw(CircleActive),
        permission: ['product:list']
      },
      {
        label: '落地页',
        route: '/asset/landing-page',
        name: 'landing-page',
        defaultIcon: markRaw(Circle),
        activeIcon: markRaw(CircleActive),
        permission: ['landing_page:wechat_mini_program:list']
      },
      {
        label: '品牌形象',
        route: '/asset/brand',
        name: 'brand',
        defaultIcon: markRaw(Circle),
        activeIcon: markRaw(CircleActive),
        permission: ['brand:brand_image:list']
      }
    ]
  },
  {
    label: '工具箱',
    defaultIcon: markRaw(Tool),
    route: '/toolbox/robot',
    active: false,
    activeIcon: markRaw(ToolActive),
    permission: ['material_analysis:list', 'auto_rule:list'],
    children: [
      {
        label: '自动规则',
        route: '/toolbox/robot',
        name: 'robot',
        defaultIcon: markRaw(Circle),
        activeIcon: markRaw(CircleActive),
        permission: ['auto_rule:list']
      },
      {
        label: '素材分析',
        route: '/toolbox/material/analyze',
        name: 'material-analyze',
        defaultIcon: markRaw(Circle),
        activeIcon: markRaw(CircleActive),
        permission: ['material_analysis:list']
      }
    ]
  },
  {
    label: '订单管理',
    defaultIcon: markRaw(Order),
    route: '/order-manage',
    name: 'order-manage',
    active: false,
    activeIcon: markRaw(OrderActive),
    permission: ['order:list']
  },
  {
    label: '角色管理',
    route: '/role-manage',
    name: 'role-manage',
    defaultIcon: markRaw(Role),
    activeIcon: markRaw(RoleActive),
    permission: ['role:list']
  },
  {
    label: '人员管理',
    route: '/user-manage',
    name: 'user-manage',
    defaultIcon: markRaw(User),
    activeIcon: markRaw(UserActive),
    permission: ['user:list']
  }
])
export const useSideBarStore = defineStore('sideBarStore', () => {
  const getSideBar = (): SideBarItemConfig[] => {
    return getSideBarByConfig(configs).filter((el) => !_.isEmpty(el))
  }
  const getFirstSideBarUrl = (): string => {
    return getSideBar()[0]?.route || '/'
  }
  return { getSideBar, getFirstSideBarUrl }
})

// 初始化sideBar，权限过滤
const getSideBarByConfig = (list: SideBarItemConfig[]): SideBarItemConfig[] => {
  return list.map((item) => {
    let currentRouter = {} as SideBarItemConfig
    if (item.permission) {
      for (const el of item.permission) {
        if (hasPermission(el)) {
          currentRouter = item
          item.children && (currentRouter.children = getSideBarByConfig(item.children).filter((el) => !_.isEmpty(el)))
        }
      }
      item.children && item.children.length ? (item.route = item.children[0].route) : null
    }
    /** 内测环境  非管理员 隐藏自动规则入口 */
    const auth = JSON.parse(localStorage.getItem('auth') as string)
    if (item.label === '工具箱' && env.value === 'privateBeta' && item.children && auth.server_role?.id !== 2) {
      currentRouter.children = item.children.filter((el) => el.label !== '自动规则')
      item.route = item.children[0].route
    }

    return currentRouter
  })
}
