import { userConfig, userSaveConfig } from '@/api/auth'
import { useTimezoneStore } from './timezone'
import { useCurrencyStore } from './currency'

export const getUserConfig = async () => {
  try {
    const timezoneStore = useTimezoneStore()
    const currencyStore = useCurrencyStore()
    const [hasError, data] = await userConfig()
    if (hasError) {
      return
    }
    await timezoneStore.setTimezoneValue(data.data.timezone)
    await currencyStore.setCurrencyValue(data.data.currency)
  } catch (error) {
    console.trace(error)
    return
  }
}

export const setUserConfig = async (obj = {}) => {
  try {
    const timezoneStore = useTimezoneStore()
    const currencyStore = useCurrencyStore()
    await userSaveConfig({
      timezone: timezoneStore.timezone,
      currency: currencyStore.currency,
      ...obj
    })
  } catch (error) {
    console.trace(error)
    return
  }
}
