import { h } from 'vue'
import { ElMessageBox } from 'element-plus'
import { createRouter, createWebHistory } from 'vue-router'
import { useLocalStorage } from '@vueuse/core'
import { usePermissionStore } from '@/stores/permission'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import login from './login'
import report from './report'
import batchCreate from './batch-create'
import assetManage from './asset-manage'
import order from './order'
import system from './system-manage'
import auth from './auth'
import toolbox from './toolbox'

NProgress.configure({ showSpinner: false })

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [...login, ...report, ...batchCreate, ...assetManage, ...order, ...system, ...auth, ...toolbox]
})

const getVersion = async () => {
  try {
    const response = await fetch('/version.json')
    if (!response.ok) {
      throw new Error('Network response was not OK')
    }
    return response?.json()
  } catch (error) {
    console.error(error)
    return { version: 0 }
  }
}

const whiteList = ['login', '403', 'ad-manage-create', 'ad-manage-task-detail', 'ad-manage-task-list', 'login-facebook']

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (!whiteList.includes(to.name as string)) {
    const permissionStore = usePermissionStore()
    let permissionList = permissionStore.getPermissionList()

    if (!permissionList.length) {
      const result = await permissionStore.getPermissionListByApi()
      if (!result) {
        // 如果请求报错，则停留在当前路由
        return false
      }
      permissionList = result
    }
    if (!to.meta.permission || !permissionList.includes(to.meta.permission as string)) {
      next('/403')
      return
    }
  }
  if (import.meta.env.PROD) {
    const { version } = await getVersion()
    const versionStorage = useLocalStorage('version', version)
    if (version && versionStorage.value !== version) {
      const versionDate = `${new Date(version)
        .toLocaleString('zh-CN', {
          hour12: false,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        })
        .replace(/\//g, '-')
        .replace(/, /g, ' ')} (UTC+8)`
      ElMessageBox.alert(
        h('p', null, [
          h('strong', null, '检测当前版本有更新，请立即刷新！'),
          h('p', { style: 'margin-top: 10px' }, `更新时间: ${versionDate}`)
        ]),
        '提示',
        {
          showClose: false,
          confirmButtonText: '立即刷新',
          customClass: 'update-version',
          customStyle: {
            position: 'absolute',
            top: '8rem',
            left: 'calc(50% - 200px)'
          }
        }
      ).then(() => {
        versionStorage.value = version
        window.location.reload()
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
