/**
 * 参考 diff 算法，算出数组新增/删除的项
 * @param newArr 新数组
 * @param oldArr 旧数组
 */
export const diffArray = <Item = any>(newArr: Item[], oldArr: Item[]) => {
  const removedValue: Item[] = []
  const map = new Map()
  newArr.forEach((v, i) => map.set(v, i))
  const isNew = new Array(newArr.length).fill(true)
  // push the removed value and mark common value
  oldArr.forEach((v) => {
    const newIndex = map.get(v)
    if (newIndex !== void 0) isNew[newIndex] = false
    else removedValue.push(v)
  })
  return { addedValue: newArr.filter((_, i) => isNew[i]), removedValue }
}

/**
 * 计算字符串长度
 * @param str 字符串
 */
export const isFullWidthCharacterNumber = (str: string): number => {
  let totalCount: number = 0
  for (let i = 0; i < str.length; i++) {
    if (/[^\x00-\xff]/.test(str[i])) {
      totalCount += 1 // 全角字符加1
    } else {
      totalCount += 0.5 // 半角字符加0.5
    }
  }

  return totalCount
}

/**
 * 判断是否为移动端
 * @param costumedEnv 自定义环境特征名
 */
export const isMobile = (costumedEnv?: string): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(costumedEnv || navigator.userAgent)
}

/**
 * 将 PromoteTime 解释为字符串
 * @param arr PromoteTime 数组
 * @param weekdays 星期数组
 */
export const translatePromoteTime = (
  arr: string[],
  weekdays: string[] = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日']
) => {
  const textValue: number[][][] = []
  for (let index = 0; index < weekdays.length; index++) {
    const result: number[][] = []
    let startIndex = 0
    let endIndex = 48
    const timeList = arr.slice(index * 48, (index + 1) * 48)
    while (timeList) {
      startIndex = timeList.indexOf('1')
      if (startIndex === -1) break
      timeList.fill('1', 0, startIndex)
      endIndex = timeList.indexOf('0')
      if (endIndex === -1) endIndex = 48
      timeList.fill('0', 0, endIndex)
      result.push([startIndex * 0.5, endIndex * 0.5])
    }
    textValue.push(result)
  }
  const clockList = textValue.map((textList) => {
    const list = textList.map(([startTime, endTime]) => {
      const startList = String(startTime).split('.')
      const endList = String(endTime).split('.')
      const start =
        startList.length === 1
          ? `${startList[0].length === 1 ? '0' + startList[0] : startList[0]}:00`
          : `${startList[0].length === 1 ? '0' + startList[0] : startList[0]}:30`
      const end =
        endList.length === 1
          ? `${endList[0].length === 1 ? '0' + endList[0] : endList[0]}:00`
          : `${endList[0].length === 1 ? '0' + endList[0] : endList[0]}:30`
      return [start, end].join('~')
    })
    return list.join('、')
  })
  const value = weekdays
    .map((day, index) => {
      if (!clockList[index].length) return ''
      return `${day}: ${clockList[index]}`
    })
    .filter((item) => item.length)
    .join('; ')
  return value
}

/**
 * 获取枚举值
 * @param anEnum 枚举
 * @returns 结果数组
 */
export const getEnumValues = <T>(anEnum: { [x: string]: any }): T[keyof T][] => {
  const keys = Object.keys(anEnum).filter((item) => {
    if (typeof item === 'boolean' || typeof anEnum?.[anEnum?.[item]] === 'boolean') {
      return false
    }
    if (typeof item === 'number' || typeof anEnum?.[anEnum?.[item]] === 'number') {
      return false
    }
    return isNaN(Number(item))
  })
  const valueList: any[] = []
  for (const key of keys) {
    valueList.push(anEnum[key])
  }
  return valueList
}

/**
 * 动态获取静态资源，即自动捕获 & 获取Vite添加文件指纹前 & 后的静态资源（如：assets文件夹）
 * @param dir 静态资源的静态地址
 * @returns 静态资源的动态处理后的地址
 */
export const getAssetDir = (dir: string) => {
  const url = new URL(dir, import.meta.url)
  return url.pathname
}

/**
 * 将对象的属性值为空字符串值的属性过滤掉
 * @param {object} obj 对象
 */
export function deepCloneAndRemoveEmptyStringProperties(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  const clone = Array.isArray(obj) ? [] : {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]

      if (value !== '') {
        clone[key] = deepCloneAndRemoveEmptyStringProperties(value)
      } else if (Array.isArray(obj)) {
        clone[key] = ''
      }
    }
  }

  return clone
}

/**
 * 判断单纯数组1是否是单纯数组2的子集
 * @param {object} arr1 数组1
 * @param {object} arr2 数组2
 * @returns {boolean} 结果
 */
export function arrayContainsArray(arr1: string[], arr2: string[]): boolean {
  return arr1.every((item) => arr2.includes(item))
}

/**
 * 获得一个长度为 2 的 时间戳 数组
 * @param {number} beforeDay 前移天数
 * @param {Date} endDate 终止日期
 * @returns {[number, number]} 起始和结束时间戳
 */
export function getDateTimeRange(beforeDay: number = 1, endDate: Date = new Date()): [number, number] {
  const MILLISECONDS_IN_DAY = 86400000 // 每天的毫秒数

  // 确保 beforeDay 至少为 1
  beforeDay = beforeDay > 0 ? beforeDay : 1

  // 确保 endDate 是有效的 Date 对象
  if (!(endDate instanceof Date)) {
    throw new Error('endDate must be a valid Date object')
  }

  // 计算起始日期
  const startDate = new Date(endDate.getTime() - MILLISECONDS_IN_DAY * (beforeDay - 1))
  startDate.setHours(0, 0, 0, 0) // 设置为当天开始

  // 设置终止日期为当天结束
  endDate.setHours(23, 59, 59, 0)

  return [startDate.getTime(), endDate.getTime()]
}

/**
 * 校验是否为合格的 URL
 * @param {string} urlString url 字符串
 * @returns {boolean}
 */
export const isValidUrl = (urlString: string = ''): boolean => {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}
