import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "26",
  height: "26",
  class: "icon",
  viewBox: "0 0 1024 1024"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#a5a4bf",
      d: "M956.9 192.547H253.812c-35.009 0-64.182 29.174-64.182 64.182v703.089c0 35.008 29.173 64.182 64.182 64.182H956.9c35.009 0 64.183-29.174 64.183-64.182V256.729c0-35.008-29.174-64.182-64.183-64.182m0 764.353H253.812V256.73H956.9zM253.812 767.27H64.182V64.183h703.089v128.365h64.182V64.182C831.453 29.174 802.279 0 767.271 0H64.182C29.174 0 0 29.174 0 64.182v703.089c0 35.008 29.174 64.182 64.182 64.182h189.63zm574.724-192.546H635.989V382.177h-64.183v192.547H379.26v64.182h192.547v192.547h64.183V638.906h192.547z"
    }, null, -1)
  ])))
}
export default { render: render }