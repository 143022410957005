import type { FormItemConfig } from '@/components/common/form-renderer/form-components'
import { ComponentType } from '@/components/common/form-renderer/form-components/type'

export const configs: FormItemConfig[] = [
  {
    name: 'campaign_name',
    label: '计划名称',
    config: {
      type: ComponentType.COMMON_INPUT_TITLE,
      attr: {
        maxlength: 512,
        showWordLimit: true,
        style: {
          width: '450px'
        },
        clearable: true,
        placeholder: '请输入计划名称'
      },
      default: '计划名称_{{日期}}_{{时间}}_{{标识}}',
      wildcards: ['日期', '时间', '标识', '素材名']
    },
    rules: [
      { required: true, message: '请输入计划名称', trigger: ['blur', 'change'] },
      {
        validator: (rule, value, callback) => {
          // 不能含有 emoji
          if (value.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/)) {
            return callback(new Error('名称不能含有 Emoji'))
          }
          // 名称不能超过512字符，中文或日文每个字占用2个字符，每个英语字符占用1个字符，单独计算
          let length = 0
          for (let i = 0; i < value.length; i++) {
            if (value.charCodeAt(i) > 255) {
              length += 2
            } else {
              length++
            }
          }
          if (length > 512) {
            callback(new Error('名称不能超过512个字符，中文或日文每个字占用2个字符，每个英语字符占用1个字符'))
          } else {
            callback()
          }
        },
        trigger: ['blur', 'change']
      }
    ]
  },
  {
    name: 'objective_type',
    label: '推广目标',
    config: {
      type: ComponentType.COMMON_RADIO_GROUP,
      options: [{ label: '网站转化量', value: 'WEB_CONVERSIONS' }],
      default: 'WEB_CONVERSIONS'
    }
  },
  {
    name: 'campaign_type',
    label: '计划类型',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: 'REGULAR_CAMPAIGN'
    }
  },
  {
    name: 'is_spc',
    label: '广告系列设置',
    tip: '借助 Smart+ 推广系列这种全新方式，轻松、快速创建有效的推广系列。',
    config: {
      type: ComponentType.COMMON_SWITCH,
      default: false
    }
  },
  {
    name: 'spc_type',
    label: '网站推广系列类型',
    config: {
      type: ComponentType.COMMON_HIDDEN,
      default: 'WEB_ALL_IN_ONE'
    },
    condition: {
      'campaign.is_spc': true
    }
  },
  {
    name: 'budget_optimize_on',
    label: '推广系列预算优化',
    tip: '系统自动优化预算分配，当前推广系列下的广告组在出价与优化的设置上存在限制',
    config: {
      type: ComponentType.COMMON_SWITCH,
      default: false
    },
    condition: {
      'campaign.is_spc': false
    }
  },
  {
    name: ['budget_mode', 'budget'],
    label: '推广系列预算',
    tip: '一旦日预算或总预算达到上限，该推广系列中的所有广告组将停止投放',
    config: {
      type: ComponentType.TIKTOK_BUDGET
    },
    condition: {
      'campaign.is_spc': false
    }
  }
]
