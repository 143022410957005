import { OceanConfigs as OCEAN_ENGINE_CONFIGS } from './ocean-engine'
import { facebookConfigs as FACEBOOK_CONFIGS } from './facebook'
import { tadsV3Configs as TADS_V3_CONFIGS } from './tads-v3'
import { tikTokConfigs as TIK_TOK_CONFIGS } from './tiktok'

import {
  TadsLogo as TADS_LOGO,
  OceanLogo as OCEAN_ENGINE_LOGO,
  FacebookLogo as FACEBOOK_LOGO,
  TikTokLogo as TIK_TOK_LOGO
} from '@/assets/svg'

import type { FunctionalComponent, SVGAttributes } from 'vue'
import type { FormItemConfig } from '@/components/common/form-renderer/form-components'
import { ComponentType } from '@/components/common/form-renderer/form-components/type'

import { currencyInputConfig as oceanCurrencyInputConfig } from './ocean-engine/currency'
import { currencyInputConfig as facebookCurrencyInputConfig } from './facebook/currency'
import { currencyInputConfig as tadsV3CurrencyInputConfig } from './tads-v3/currency'
import { currencyInputConfig as tikTokCurrencyInputConfig } from './tiktok/currency'

export type Panel = {
  key: DimensionEnum
  title: string
  section: Section[]
}

export type Section = {
  title: string
  form: FormItemConfig[]
}

export type PlatformCurrencyInputConfig = Partial<Record<DimensionEnum, Record<string, CurrencyConfig>>>

export type FormItemConfigMapType = Record<DimensionEnum, FormItemConfig[]>
export type FormItemConfigMapExcludeType<U extends DimensionEnum> = Record<Exclude<DimensionEnum, U>, FormItemConfig[]>

export enum DimensionEnum {
  CAMPAIGN = 'campaign',
  ADGROUP = 'adgroup',
  ADCREATIVE = 'adcreative'
}

export const nullConfig: FormItemConfigMapType = {
  [DimensionEnum.CAMPAIGN]: [],
  [DimensionEnum.ADGROUP]: [],
  [DimensionEnum.ADCREATIVE]: []
}

export const nullCurrencyConfig: PlatformCurrencyInputConfig = {
  [DimensionEnum.CAMPAIGN]: {},
  [DimensionEnum.ADGROUP]: {},
  [DimensionEnum.ADCREATIVE]: {}
}

export const underConstructionConfig: Record<DimensionEnum, FormItemConfig[]> = {
  [DimensionEnum.CAMPAIGN]: [
    {
      name: '',
      label: '功能待开发',
      config: {
        type: ComponentType.COMMON_UNKNOWN
      }
    }
  ],
  [DimensionEnum.ADGROUP]: [
    {
      name: '',
      label: '功能待开发',
      config: {
        type: ComponentType.COMMON_UNKNOWN
      }
    }
  ],
  [DimensionEnum.ADCREATIVE]: [
    {
      name: '',
      label: '功能待开发',
      config: {
        type: ComponentType.COMMON_UNKNOWN
      }
    }
  ]
}

export type AdInfo = Record<DimensionEnum, any>

export enum UnsupportedPlatform {
  Tads = 1
}

export enum Platform {
  OceanEngine = 2,
  Facebook = 3,
  TikTok = 4,
  TadsV3 = 5,
  Baidu = 6
}
export type PlatformInfo = {
  message: string
  icon: FunctionalComponent<SVGAttributes, any> | string
  srcIcon: boolean
}

export type PlatformConfig = {
  info: PlatformInfo
  form: Record<Partial<DimensionEnum>, FormItemConfig[]>
}
export const platformConfig: Record<Platform, PlatformConfig> = {
  [Platform.OceanEngine]: {
    info: {
      message: '巨量广告',
      icon: OCEAN_ENGINE_LOGO,
      srcIcon: false
    },
    form: OCEAN_ENGINE_CONFIGS
  },
  [Platform.Facebook]: {
    info: {
      message: 'Facebook',
      icon: FACEBOOK_LOGO,
      srcIcon: false
    },
    form: FACEBOOK_CONFIGS
  },
  [Platform.TikTok]: {
    info: {
      message: 'TikTok',
      icon: TIK_TOK_LOGO,
      srcIcon: false
    },
    form: TIK_TOK_CONFIGS
  },
  [Platform.TadsV3]: {
    info: {
      message: '腾讯广告',
      icon: TADS_LOGO,
      srcIcon: false
    },
    form: TADS_V3_CONFIGS as Record<Partial<DimensionEnum>, FormItemConfig[]>
  },
  [Platform.Baidu]: {
    info: {
      message: '百度',
      icon: '',
      srcIcon: false
    },
    form: nullConfig
  }
}

export type CurrencyInputConfig = {
  suffix: string
  precision?: number
  min?: number
  max?: number
}

export type CurrencyConfig = {
  CNY: CurrencyInputConfig // 人民币
  USD: CurrencyInputConfig // 美元
  EUR: CurrencyInputConfig // 欧元
  GBP: CurrencyInputConfig // 英镑
  AUD: CurrencyInputConfig // 澳元
  CAD: CurrencyInputConfig // 加元
  NZD: CurrencyInputConfig // 新西兰元
}

export const platformCurrencyInputConfig: Record<Platform, PlatformCurrencyInputConfig> = {
  [Platform.OceanEngine]: oceanCurrencyInputConfig,
  [Platform.Facebook]: facebookCurrencyInputConfig,
  [Platform.TikTok]: tikTokCurrencyInputConfig,
  [Platform.TadsV3]: tadsV3CurrencyInputConfig,
  [Platform.Baidu]: nullCurrencyConfig
}

export const groupConfigsBySection = (configs: FormItemConfig[]) => {
  const result = {}
  if (configs && configs.length) {
    for (const config of configs) {
      const sectionLabel = config.sectionLabel || '基础信息'

      result[sectionLabel] = result[sectionLabel] || []
      result[sectionLabel].push(config)
    }
  }

  return result
}
